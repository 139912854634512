import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { RouterService } from '@shared/services/router.service';
import { WorkspacesService } from '../../services';
import { Observable, tap, map } from 'rxjs';

export abstract class CanActivateViewGuard {
  protected abstract flag: string;
  private routerService: RouterService = inject(RouterService);
  private workspaceService: WorkspacesService = inject(WorkspacesService);

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.workspaceService.isFeatureFlagDisabled$(this.flag).pipe(
      tap((res) => {
        if (res) {
          this.routerService.navigateByUrl('/');
        }
      }),
      map((res) => !res)
    );
  }
}
