import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  effect,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  input,
  Input,
  Output,
  QueryList,
  signal,
  ViewChild,
  ViewChildren,
  WritableSignal,
} from '@angular/core';
import { Commands, Results } from '@local/client-contracts';
import { getAssistantTitle, isDescriptionEditableAssistant, isSlackAssistant, isTeamsAssistant } from '@local/common-web';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ContextMenuService } from '@shared/components';
import { EventsService } from '@shared/services';
import { KeyboardService } from '@shared/services/keyboard.service';
import { getWidthBreakpointScreen, isRightClick, windowSizeObserver } from '@shared/utils';
import { stopEvent } from '@shared/utils/elements-util';
import { Tooltip } from 'primeng/tooltip';
import { AvatarItemModel } from 'src/app/bar/models/avatar-item.model';
import { AvatarListService } from 'src/app/bar/services/avatar-list.service';
import { CommandsService } from 'src/app/bar/services/commands/commands.service';
import { ExperiencesService } from 'src/app/bar/services/experiences.service';
import { HubService } from 'src/app/bar/services/hub.service';
import { AssistantItem } from 'src/app/bar/views/results';
import { AssistantContextMenuHelper } from '../../helpers/assistant-context-menu-helper';
import { toObservable } from '@angular/core/rxjs-interop';
import { pairwise, startWith } from 'rxjs';
import { isEqual } from 'lodash';

@UntilDestroy()
@Component({
  selector: 'assistant-item',
  templateUrl: './assistant-item.component.html',
  styleUrls: ['./assistant-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssistantItemComponent {
  readonly ASSISTANT_ICON_STYLES = { 'font-size': '23px', height: '24px', width: '24px' };
  @Input() selected: boolean;

  //avatar
  avatarsList: WritableSignal<AvatarItemModel[]> = signal([]);

  //context menu
  private assistantContextMenuHelper: AssistantContextMenuHelper;
  private windowSize$ = windowSizeObserver();
  contextMenuActive: boolean;
  @ViewChild('actions', { read: ElementRef }) actionsRef: ElementRef;
  @ViewChild('container', { read: ElementRef }) containerRef: ElementRef;
  @ViewChild('assistantName', { read: ElementRef }) assistantNameRef: ElementRef;
  @ViewChild('subtitle', { read: ElementRef }) assistantSubtitleRef: ElementRef;

  @ViewChildren(Tooltip) tooltipsComps: QueryList<Tooltip>;

  createdBullet: string;
  galleryCreatedBullet: string;
  tooltipModifiedTime: string;
  toggleCopyTooltip: WritableSignal<boolean> = signal(false);
  smallScreen: WritableSignal<boolean> = signal(false);
  assistantItem = input<AssistantItem>();
  titleAssistant = computed(() => {
    if (this.assistantItem()) {
      return getAssistantTitle(this.assistantItem());
    }
  });
  subtitleAssistant = computed(() => {
    if (this.assistantItem()) {
      return this.getAssistantSubtitle();
    }
  });
  avatar = computed<AvatarItemModel>(() => {
    if (this.assistantItem()) {
      return this.assistantService.getLastModified(this.assistantItem());
    }
  });

  private _showResultSections: Results.ShowResultSections = {
    showBullets: true,
    showContextMenu: true,
    disableRightClick: false,
  };

  @Input()
  set showResultSections(value: Results.ShowResultSections) {
    this._showResultSections = value || {
      showBullets: true,
      showContextMenu: true,
      disableRightClick: false,
    };
    this.setShowResultSections(this._showResultSections);
  }

  get showResultSections(): Results.ShowResultSections {
    return this._showResultSections;
  }

  get assistant(): AssistantItem {
    return this.assistantItem();
  }

  get tooltipText() {
    const titleEl: HTMLElement = this.assistantNameRef?.nativeElement;
    if (!titleEl) return;
    const height = titleEl.clientHeight;
    const scrollHeight = titleEl.scrollHeight;
    return height < scrollHeight ? this.titleAssistant : '';
  }

  @HostListener('auxclick', ['$event']) onAuxClick(event: MouseEvent) {
    if (isRightClick(event) && !this.showResultSections.disableRightClick) {
      this.openContextMenu(event);
    }
  }

  @HostListener('click', ['$event']) async onClick(event: MouseEvent) {
    const isClickable = await this.isClickable();
    if (this.selected || isClickable) {
      await this.assistantService.openAssistant(this.assistant.id);
      if (isClickable) {
        event.preventDefault();
      }
    }
  }

  @HostListener('dblclick', ['$event']) async onDblClick(event: MouseEvent) {
    event.preventDefault();
    await this.assistantService.openAssistant(this.assistant.id);
  }

  @HostBinding('class.selected')
  get isSelected() {
    return this.selected;
  }

  @HostBinding('attr.viewMode')
  @Input()
  viewMode: Results.LayoutType = 'list';

  @Output() contextOpen = new EventEmitter();
  @Output() contextClose = new EventEmitter();

  constructor(
    private cdr: ChangeDetectorRef,
    private keyboardService: KeyboardService,
    private eventsService: EventsService,
    private hubService: HubService,
    private assistantService: ExperiencesService,
    private contextMenuService: ContextMenuService,
    private avatarListService: AvatarListService,
    private commandsService: CommandsService
  ) {
    toObservable(this.assistantItem)
      .pipe(untilDestroyed(this), startWith(null), pairwise())
      .subscribe(async ([prevAssistant, newAssistant]) => {
        if (newAssistant?.shareOptions && !isEqual(prevAssistant?.shareOptions, newAssistant.shareOptions)) {
          this.avatarsList.set(
            await this.avatarListService.createAvatarList(
              newAssistant.createdBy,
              newAssistant.shareOptions,
              true,
              this.assistantService.SHARE_OPTIONS_ASSISTANTS
            )
          );
        }
        const phrase = await this.assistantService.getAssistantTimePhrases(this.assistant);
        this.galleryCreatedBullet = `${phrase.preDisplayModifiedTime} ${phrase.displayModifiedTime}`;
        this.createdBullet = `${phrase.preDisplayModifiedTime}by ${phrase.startModifiedPhrase} ${phrase.displayModifiedTime}`;
        this.tooltipModifiedTime = phrase.fullDetails;
      });
  }

  ngAfterViewInit(): void {
    this.initContextMenuHelper();
    this.initContextMenuHelperListeners();
    this.windowSize$.pipe(untilDestroyed(this)).subscribe(() => {
      this.smallScreen.set(['small', 'extra-small'].includes(getWidthBreakpointScreen()));
    });
  }

  private getAssistantSubtitle() {
    if (isSlackAssistant(this.assistant)) {
      if (this.assistantService.slackWorkspaces?.size > 1) {
        return this.assistant.settings.workspaceName;
      } else {
        return '';
      }
    }
    if (isTeamsAssistant(this.assistant)) {
      return this.assistant.settings.teamName;
    }
    return '';
  }

  private async isClickable(): Promise<boolean> {
    const isLauncher = await this.hubService.getIsLauncher();
    return this.hubService.currentLocation === 'home' || isLauncher;
  }

  openAssistant($event) {
    stopEvent($event);
    this.assistantService.openAssistant(this.assistant.id);
  }

  async copyChannelId() {
    await this.commandsService.executeCommand({
      type: 'copy-clipboard',
      value: this.assistant.externalId,
    } as Commands.CopyClipboard);
    this.toggleCopyTooltip.update((value) => !value);
  }
  async openContextMenu(event: MouseEvent) {
    this.contextOpen.emit();
    const ref = await this.assistantContextMenuHelper.open(event, this.assistant);
    this.contextMenuActive = true;
    ref.destroy$.pipe(untilDestroyed(this)).subscribe(() => {
      this.contextMenuActive = false;
      this.contextClose.emit();
      this.cdr.markForCheck();
    });
    this.eventsService.event('assistant.open_menu', {
      label: 'mouse_click',
      location: { title: this.hubService.currentLocation },
    });
    this.cdr.markForCheck();
  }

  protected initContextMenuHelper(): void {
    this.assistantContextMenuHelper = new AssistantContextMenuHelper(
      this.contextMenuService,
      this.keyboardService,
      this.actionsRef,
      this.assistantService,
      this.hubService
    );
  }

  initContextMenuHelperListeners() {
    this.assistantContextMenuHelper.invoke$.pipe(untilDestroyed(this)).subscribe(({ item, trigger }) => {
      this.assistantService.onContextMenuCommand(item, trigger, this.assistant, this.hubService.currentLocation);
    });
  }
  get tooltipTextDescription() {
    const el: HTMLElement = this.assistantSubtitleRef?.nativeElement;
    if (!el) return;
    const width = el.clientWidth;
    const scrollWidth = el.scrollWidth;
    return width < scrollWidth && isDescriptionEditableAssistant(this.assistant) ? this.assistant?.settings?.description : '';
  }
  setShowResultSections(value: Results.ShowResultSections): void {
    console.log('Setting showResultSections:', value);
  }
}
