<div #container class="assistant-item-container">
  <div class="assistant-info" [ngClass]="{ 'info-without-bullets': !showResultSections.showBullets }">
    <ng-container *ngIf="viewMode === 'gallery'">
      <ng-container *ngTemplateOutlet="editTime"></ng-container>
      <ng-container *ngTemplateOutlet="squareButton"></ng-container>
      <div class="title-container">
        <ng-container *ngTemplateOutlet="icon"></ng-container>
        <ng-container *ngTemplateOutlet="title"></ng-container>
      </div>
      <ng-container *ngTemplateOutlet="description"></ng-container>
      <ng-container *ngTemplateOutlet="footer"></ng-container>
    </ng-container>

    <ng-container *ngIf="viewMode === 'list'">
      <ng-container *ngTemplateOutlet="icon"></ng-container>
      <div class="assistant-list-text">
        <ng-container *ngTemplateOutlet="title"></ng-container>
        @if (showResultSections.showBullets) {
          <div class="assistant-bullets-section">
            <ng-container *ngTemplateOutlet="editTime"></ng-container>
            <ng-container *ngIf="assistant.experienceType === 'slack'">
              <div class="divider"></div>
              <ng-container *ngTemplateOutlet="slackChannelId"></ng-container>
              <ng-container *ngIf="assistant?.settings?.isPrivate">
                <div class="divider"></div>
                <ng-container *ngTemplateOutlet="privateSlackChannelList"></ng-container>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="assistant.isShared && avatarsList()?.length > 1">
              <div class="divider"></div>
              <ng-container *ngTemplateOutlet="avatarList"></ng-container>
            </ng-container>
          </div>
        }
      </div>
      @if (showResultSections.showContextMenu) {
        <ng-container *ngTemplateOutlet="squareButton"></ng-container>
      }
    </ng-container>
  </div>
</div>

<ng-template #title>
  <div
    #assistantName
    class="assistant-name"
    data-cy="assistant-name"
    [pTooltip]="tooltipText"
    [style.width]="viewMode === 'gallery' ? '100%' : containerRef?.nativeElement?.clientWidth - 100 + 'px' || '100%'"
    tooltipPosition="top"
    tooltipStyleClass="u-tooltip"
    (click)="openAssistant($event)"
  >
    <div class="title">
      <span [innerHTML]="titleAssistant() | highlight: assistant?.highlights : 'default'"></span>
      <ng-container *ngTemplateOutlet="privateSlackChannelGallery"></ng-container>
    </div>
    <span *ngIf="subtitleAssistant()" class="subtitle">{{ subtitleAssistant() }}</span>
  </div>
</ng-template>

<ng-template #icon>
  <assistant-icon
    [model]="assistant"
    [spriteEmojiSize]="showResultSections.showSingleLineView ? 20 : 24"
    [iconStyles]="showResultSections.showSingleLineView ? { 'font-size': '20px', height: '20px', width: '20px' } : ASSISTANT_ICON_STYLES"
  ></assistant-icon>
</ng-template>
<ng-template #description>
  <span
    #subtitle
    class="assistant-description"
    [innerHTML]="assistant?.settings?.description"
    [pTooltip]="tooltipTextDescription"
    tooltipPosition="bottom"
    tooltipStyleClass="u-tooltip"
  ></span>
</ng-template>

<ng-template #avatarList let-sizeAvatar="sizeAvatar">
  <avatar-list
    *ngIf="avatarsList()?.length > 1"
    [model]="avatarsList()"
    [tooltipHeader]="'Shared with'"
    [spreadAvatarsCount]="3"
    [tooltipLimit]="10"
    [sizeAvatar]="sizeAvatar ?? '20px'"
    [sizeAvatarCount]="sizeAvatar ?? '20px'"
    [fallbackIcon]="{ type: 'font', value: 'icon-shared-link' }"
  ></avatar-list>
</ng-template>

<ng-template #footer>
  <div class="assistant-footer">
    <ng-container *ngIf="assistant.experienceType === 'slack'">
      <ng-container *ngTemplateOutlet="slackChannelId"></ng-container>
    </ng-container>
    <ng-container *ngIf="assistant.isShared && avatarsList()?.length > 1">
      <ng-container *ngTemplateOutlet="avatarList; context: { sizeAvatar: '16px' }"></ng-container>
    </ng-container>
  </div>
</ng-template>

<ng-template #editTime>
  <div class="avatar-edit-time-container">
    <avatar
      [url]="avatar().imgUrl || avatar().name"
      [size]="'16px'"
      [pTooltip]="viewMode === 'gallery' ? createdBullet : null"
      tooltipPosition="top"
      tooltipStyleClass="u-tooltip"
    ></avatar>
    <div
      class="last-edit"
      [pTooltip]="viewMode === 'gallery' ? createdBullet : tooltipModifiedTime"
      tooltipPosition="top"
      tooltipStyleClass="u-tooltip"
    >
      {{ smallScreen() || viewMode === 'gallery' ? galleryCreatedBullet : createdBullet }}
    </div>
  </div>
</ng-template>

<ng-template #slackChannelId>
  <div
    class="slack-channel-id-container"
    [pTooltip]="'Click to copy' | toggleTooltipText: 'Copied!' : 1000 : tooltipsComps?.last : toggleCopyTooltip() | async"
    tooltipPosition="top"
    tooltipStyleClass="u-tooltip"
    (click)="$event.stopPropagation(); copyChannelId()"
  >
    <div>ID: {{ assistant.externalId }}</div>
  </div>
</ng-template>

<ng-template #privateSlackChannelGallery>
  <u-icon
    *ngIf="viewMode === 'gallery' && assistant?.settings?.isPrivate"
    class="private-channel-icon"
    [pTooltip]="'Private channel'"
    tooltipPosition="top"
    tooltipStyleClass="u-tooltip"
    [model]="{ type: 'font-icon', value: 'icon-private-channel' }"
  ></u-icon>
</ng-template>

<ng-template #privateSlackChannelList>
  <div class="slack-channel-container">
    <u-icon class="slack-channel-icon" [model]="{ type: 'font-icon', value: 'icon-private-channel' }"></u-icon>
    <div>Private channel</div>
  </div>
</ng-template>

<ng-template #squareButton>
  <square-button
    [active]="assistantContextMenuHelper?.source === 'actions'"
    class="square-button"
    [ngClass]="{ visible: contextMenuActive }"
    (click)="$event.stopPropagation(); openContextMenu($event)"
    (auxclick)="$event.stopPropagation(); openContextMenu($event)"
    (contextmenu)="$event.stopPropagation(); openContextMenu($event)"
    #actions
    >•••</square-button
  >
</ng-template>
