import { Filters, MemorySearch, Wiki } from '@local/client-contracts';
import { LogService } from '@shared/services';
import { MemorySearchService } from '@shared/services/memory-search.service';
import { Observable, ReplaySubject, Subscription } from 'rxjs';
import { SearchResults, TelemetryTrigger } from 'src/app/bar/views';
import { StatsService } from '../../../stats.service';
import { WikiCardsService } from '../../../wikis/wiki-cards.service';
import { MemorySearchClient } from '../memory-search-client/memory-search-client';
import { SearchRequest } from '../search-request';
import { SearchResponse } from '../search-response';
import { WikiTreeSourceSettings } from './wiki-tree-source-settings';
import { WikisService } from '../../../wikis/wikis.service';
import { WikiFoldersService } from '../../../wikis/wiki-folders.service';
import { Constants } from '@local/common';
import { WorkspacesService } from '../../../workspaces.service';

export class WikiTreeSearchClient extends MemorySearchClient<WikiTreeSourceSettings> {
  private instances: { [sessionName: string]: { sub?: Subscription; refreshRunning?: boolean } } = {};
  protected workspaceDisabledFlags: string[] = [Constants.DISABLED_WIKIS_WORKSPACE_FEATURE_FLAG];

  constructor(
    logService: LogService,
    memorySearchService: MemorySearchService,
    private wikiCardsService: WikiCardsService,
    private statsService: StatsService,
    private wikisService: WikisService,
    private wikiFolders: WikiFoldersService,
    protected workspaceService: WorkspacesService
  ) {
    super(logService, memorySearchService, ['Alphabetical', 'Time'], null, workspaceService);
  }

  nextPage(request: SearchRequest<WikiTreeSourceSettings>, response: SearchResponse, trigger: TelemetryTrigger): Promise<void> {
    return;
  }

  supportsMatch(response: SearchResponse): boolean {
    return false;
  }

  supportsFilters(filters: Filters.Values): boolean {
    return true;
  }

  getInput(request: SearchRequest<WikiTreeSourceSettings>, response: SearchResponse): Observable<MemorySearch.Item[]> {
    const input$ = new ReplaySubject<MemorySearch.Item[]>(1);
    try {
      this.loadWiki(request, response, input$);
    } catch (e) {
      input$.error(e);
    }
    return input$;
  }

  async loadWiki(request: SearchRequest<WikiTreeSourceSettings>, response: SearchResponse, input$: ReplaySubject<MemorySearch.Item[]>) {
    let finished = false;
    const sessionName = request.sessionName;
    this.initSession(request.id, sessionName);

    const onInstanceSub = (finished, res) => {
      if (finished && res) {
        const instance = this.instances[sessionName];
        if (instance && !instance.refreshRunning && !response.cancelled) {
          this.instances[sessionName].refreshRunning = true;
          response.extra = res;
          this.refresh(request, response);
        }
      }
    };

    const updates: Observable<any>[] = [this.statsService.updated$, this.wikiCardsService.updated$, this.wikiFolders.all$];

    for (const update of updates) {
      this.instances[sessionName].sub.add(
        update.subscribe((res) => {
          onInstanceSub(finished, res);
        })
      );
    }

    await this.getWiki(request.sourceSettings, input$, response, request.query);

    finished = true;
    if (this.instances[sessionName]) {
      this.instances[sessionName].refreshRunning = false;
    }
    input$.complete();
  }

  private async getWiki(
    settings: WikiTreeSourceSettings,
    input$: ReplaySubject<MemorySearch.Item[]>,
    response: SearchResponse,
    query?: string
  ) {
    if (response.cancelled) {
      input$.complete();
      return;
    }

    const tree = await this.wikisService.getWikiTree({ wikiId: settings.wikiId, query });

    input$.next([{ data: tree, searchText: '', sortValue: '' }]);
    return tree;
  }

  private initSession(id: number, sessionName: string): void {
    const subscription = this.instances[sessionName]?.sub;
    if (subscription) {
      this.destroy(id, sessionName);
    }
    this.instances[sessionName] = {};
    this.instances[sessionName].sub = new Subscription();
  }

  destroy(id: number, sessionName: string): void {
    this.instances[sessionName]?.sub?.unsubscribe();
    this.instances[sessionName] = null;
  }

  async getOutput(items: MemorySearch.Item[], sourceSettings?: WikiTreeSourceSettings): Promise<SearchResults[]> {
    const tree = items[0]?.data as Wiki.TreeNode;
    if (!tree) {
      return;
    }
    const viewTree = await this.wikisService.getViewWikiTree(tree, sourceSettings.searchView, sourceSettings.stateView);

    return [viewTree] as SearchResults[];
  }
}
