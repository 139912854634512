import { Injectable } from '@angular/core';
import { ActivatedRoute, Data, NavigationBehaviorOptions, NavigationEnd, NavigationExtras, Params, Router, UrlTree } from '@angular/router';
import { observable } from '@local/common';
import { isEmbed } from '@local/common-web';
import { CapitalizePipe } from '@shared/pipes/capitalize.pipe';
import { last } from '@shared/utils';
import { Logger } from '@unleash-tech/js-logger';
import { cloneDeep, isEqual } from 'lodash';
import { BehaviorSubject, Observable, ReplaySubject, firstValueFrom, tap } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith } from 'rxjs/operators';
import * as uuid from 'uuid';
import { LogService } from '.';
import { TitleBarService } from './title-bar.service';
interface CustomNavigation {
  navigate: Router['navigate'];
  navigateByUrl: Router['navigateByUrl'];
}
export type PredicateNavigationType = (url: string) => Promise<boolean>;

@Injectable({ providedIn: 'root' })
export class RouterService implements CustomNavigation {
  private HISTORY_URL_BLACKLIST = ['/new?source'];

  protected logger: Logger;

  private _events = new ReplaySubject<any>();
  private isEmbed = isEmbed();

  private navIndex = 0;
  private isHistoryNavigation: boolean;
  private replaceUrl: boolean;

  private history: string[] = [];
  protected _history$ = new BehaviorSubject<string[]>([]);
  protected _active$ = new BehaviorSubject<string>(null);

  navigated$ = new ReplaySubject<string>(1);
  currentFullUrl: string;
  currentAppModule: string;
  predicateNavigation: { [id: string]: PredicateNavigationType } = {};

  @observable
  get navigation$(): Observable<any> {
    return this._events.asObservable();
  }

  @observable
  get active$(): Observable<string> {
    this.router.config;
    if (this._active$) {
      return this._active$.asObservable().pipe(distinctUntilChanged());
    }
  }

  @observable
  get currentHistory$() {
    return this._history$.pipe(map((h) => h && h[this.navIndex]));
  }

  get activeRoute$() {
    // Resolving the component data might be problematic when it's nested in other outlet
    // this hack finds the right 'route' node
    // https://github.com/angular/angular/issues/11812
    const traceCurrent = (route: ActivatedRoute) => {
      while (route.firstChild) route = route.firstChild;
      return route;
    };
    return this.navigation$.pipe(
      map(() => this.route),
      map((route) => traceCurrent(route)),
      startWith(traceCurrent(this.route)), // Takes care of the initial state (after refresh / first load)
      filter((route) => route.outlet === 'primary')
    );
  }

  @observable
  get queryParams$(): Observable<Params> {
    return this.activeRoute$.pipe(
      map((r) => r.snapshot?.queryParams),
      filter((p) => !!p)
    );
  }

  @observable
  get data$(): Observable<Data> {
    return this.activeRoute$.pipe(
      map((r) => r.snapshot?.data),
      filter((p) => !!p),
      map((v) => cloneDeep(v))
    );
  }

  @observable
  get params$(): Observable<Params> {
    return this.activeRoute$.pipe(
      map((r) => r.snapshot?.params),
      filter((p) => !!p)
    );
  }

  @observable
  get canGoBack$(): Observable<boolean> {
    return this.navigation$.pipe(map(() => this.canGoBack));
  }

  @observable
  get canGoForward$() {
    return this.navigation$.pipe(map(() => this.canGoForward));
  }

  @observable
  get back$(): Observable<string> {
    return this.navigation$.pipe(
      startWith(null),
      map(() => this.history[this.navIndex - 1])
    );
  }

  @observable
  get forward$(): Observable<string> {
    return this.navigation$.pipe(
      startWith(null),
      map(() => this.history[this.navIndex + 1])
    );
  }

  get active() {
    return this._active$.getValue();
  }

  set active(value: string) {
    this._active$.next(value);
  }

  get activeRoute(): ActivatedRoute {
    const traceCurrent = (route: ActivatedRoute) => {
      while (route.firstChild) route = route.firstChild;
      return route;
    };
    return traceCurrent(this.route);
  }

  get params(): Params {
    return this.activeRoute.snapshot?.params ?? {};
  }

  get queryParams() {
    return this.route?.snapshot?.queryParams ?? {};
  }

  get location(): string {
    const data: any = this.activeRoute?.snapshot?.data;
    return data?.id;
  }

  get prefix() {
    return '/';
  }

  get canGoBack() {
    return this.navIndex - 1 >= 0;
  }

  get current() {
    return this.history[this.navIndex];
  }

  get url() {
    return this.router.url;
  }

  get canGoForward() {
    return this.navIndex + 1 < this.history.length;
  }

  get navigationEnd(): Promise<void> {
    return firstValueFrom(
      this.navigation$.pipe(
        filter((v) => {
          return decodeURIComponent(location.pathname + location.search) === decodeURIComponent(v.url);
        }),
        map(() => undefined)
      )
    );
  }

  getCurrentNavigation() {
    return this.router.getCurrentNavigation();
  }

  getAppModule() {
    const urlParts = this.currentFullUrl.split('/');
    return urlParts[0];
  }

  constructor(
    protected router: Router,
    public route: ActivatedRoute,
    private titleBarService: TitleBarService,
    logService: LogService
  ) {
    this.logger = logService.scope('RouterService');

    router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        map((e) => e as NavigationEnd),
        tap((e) => this._events.next(e))
      )
      .subscribe();

    this.navigation$.subscribe((v) => {
      // For use by places where we want to implement 'Back' button
      this.onNavigation(v.url);
      this.currentFullUrl = this.router.url.slice(1);
      this.currentAppModule = this.getAppModule();
    });
    this.initRouteIdResolver();
    this.initTitleLogic();
  }

  async navigateByUrl(
    url: string | UrlTree,
    extras: NavigationBehaviorOptions = {},
    addModulePrefix = true,
    openInNewTab = false
  ): Promise<boolean> {
    if (!(await this.shouldNavigate(url?.toString()))) {
      return;
    }
    if (addModulePrefix && url) {
      if (typeof url === 'string') {
        url = url.startsWith(this.prefix) ? url : this.prefix + url;
      } else {
        url.fragment = this.prefix + url.fragment;
      }
    }

    if (openInNewTab) {
      window.open(url.toString(), '_blank');
      return;
    }

    if (!extras) {
      extras = {};
    }

    this.replaceUrl = extras.replaceUrl;
    this.addEmbedExtras(extras);

    await this.router.navigateByUrl(url, extras);
    this.navigated$.next(typeof url === 'string' ? url : url?.toString());
  }

  async navigate(commands: any[], extras: NavigationExtras = {}, addModulePrefix = true, openInNewTab = false): Promise<boolean> {
    if (!(await this.shouldNavigate(commands.join('/')))) {
      return;
    }

    this.replaceUrl = extras?.replaceUrl;

    this.addEmbedExtras(extras);

    if (openInNewTab) {
      const url = this.router.serializeUrl(this.router.createUrlTree([this.prefix, ...(commands ?? [])], extras));
      window.open(url, '_blank');
      return;
    }

    if (addModulePrefix) {
      return this.router.navigate([this.prefix, ...(commands ?? [])], extras);
    }
    return this.router.navigate([...(commands ?? [])], extras);
  }

  private addEmbedExtras(extras?: NavigationExtras) {
    if (!this.isEmbed) {
      return;
    }
    extras.replaceUrl = this.replaceUrl = true;
  }

  go(delta?: number) {
    delta = this.history.length - 1 + delta;
    if ((!delta && delta !== 0) || delta >= this.history.length) {
      return;
    }
    return this.history[delta];
  }

  /** Resolves the activated route id using 3 ways. Order is as priority
   * 1. route.resolver.id() => this is called on NavigationEnd so if id resolver has been defined the id property will be in the route data
   * 2. route.data.id
   * 3. The route path (without the module prefix)
   */
  initRouteIdResolver() {
    this.activeRoute$.subscribe((route) => {
      if (!route) {
        this.active = null;
        return;
      }

      const { id: dataId } = <any>route?.snapshot?.data ?? {};
      if (dataId) {
        this.active = dataId;
        return;
      }

      this.active = route?.snapshot?.url.map((s) => s.path).join('/');
    });
  }

  /** The Titlebar service in injected in the app bootstrap.
   * This is a general implmentaion for the title. Each route can define on his data,
   * or using 'resolver' for the field 'title' in order to determine it's own title.
   */
  private initTitleLogic() {
    this.data$.subscribe((d: any) => {
      let title: string;

      const { id, title: dTitle, node, avoidTitleTransform } = d ?? {};

      if (id) {
        title = id;
      }

      title = this.active;

      if (dTitle) {
        title = dTitle;
      }

      if (node?.title) {
        title = node?.title;
      }

      if (this.queryParams['purl']) {
        return;
      }

      title = avoidTitleTransform ? title : new CapitalizePipe().transform(last(title.split('/')), true);
      this.titleBarService.active = title;
    });
  }

  async back(replaceUrl = false) {
    const url: string = window.location.href;
    if (url.includes('/new?source')) {
      const appId: string = url.split('/')[5];
      const urlPart = appId ? appId + '?source=connect&published=true' : '';
      return this.navigateByUrl(`admin/sources/${urlPart}`);
    }
    this.isHistoryNavigation = true;
    this.navIndex--;
    const previous: string = this.history[this.navIndex];

    return this.navigateByUrl(previous, { replaceUrl });
  }

  async forward(): Promise<boolean> {
    this.isHistoryNavigation = true;
    this.navIndex++;
    const next: string = this.history[this.navIndex];

    return this.navigateByUrl(next);
  }

  getPreviousHistory() {
    return this.history[this.navIndex - 1];
  }

  addQueryParam(params: Params, replaceUrl = false, openInNewTab = false) {
    const next = { ...this.queryParams, ...params };
    if (!this.activeRoute || isEqual(next, this.queryParams)) {
      return;
    }
    return this.navigate(
      [],
      { relativeTo: this.activeRoute, queryParams: next, queryParamsHandling: 'merge', replaceUrl },
      false,
      openInNewTab
    );
  }

  removeQueryParam(keys: string[] | string, replaceUrl = false) {
    if (typeof keys === 'string') {
      keys = [keys];
    }
    const prev = JSON.stringify(this.queryParams);

    const next = { ...this.queryParams };

    for (const key of keys) {
      if (!next[key]) continue;
      delete next[key];
    }

    if (prev === JSON.stringify(next)) {
      return;
    }

    if (this.navIndex < this.history.length - 1) {
      this.isHistoryNavigation = true;
      this.navIndex--;
    }

    return this.navigate([], { relativeTo: this.activeRoute, queryParams: next, replaceUrl }, false);
  }

  replaceQueryParam(params: Params, replaceUrl = false) {
    const key = Object.keys(params)[0];
    this.removeQueryParam(key, replaceUrl);
    this.addQueryParam({ [key]: params[key] }, replaceUrl);
  }

  private onNavigation(url: string) {
    if (this.isHistoryNavigation || this.HISTORY_URL_BLACKLIST.some((u) => url.includes(u))) {
      if (this.replaceUrl) {
        this.history.splice(this.navIndex, 1);
      }
      this.isHistoryNavigation = this.replaceUrl = false;
      return;
    }
    const lengthHistory = this.history.length - 1;
    if (this.navIndex !== lengthHistory) {
      const tempHistory = this.history.slice(this.navIndex, lengthHistory);
      this.history.push(...tempHistory.reverse());
    }
    if (this.replaceUrl) {
      this.history[lengthHistory] = url;
    } else {
      this.history.push(url);
    }
    this._history$.next(this.history);
    this.navIndex = this.history.length - 1;
    this.isHistoryNavigation = this.replaceUrl = false;
  }

  private async shouldNavigate(url: string): Promise<boolean> {
    if (!this.predicateNavigation) return;

    for (const predicate of Object.values(this.predicateNavigation)) {
      if (!(await predicate(url))) {
        return false;
      }
    }
    return true;
  }

  addPredicateNavigation(predicate: PredicateNavigationType): string {
    const id: string = uuid.v4();
    this.predicateNavigation[id] = predicate;
    return id;
  }

  removePredicateNavigation(id: string) {
    delete this.predicateNavigation[id];
  }
}
