import { resultHeaderCountKey } from '@shared/utils/header-builder.util';
import {
  AnswersSourceSettings,
  AssistantsSourceSettings,
  CollectionsSourceSettings,
  GoToSourceSettings,
  LocalActionSourceSettings,
  SourceSettings,
} from 'src/app/bar/services/search/client';
import { WikiCollectionItemSourceSettings } from 'src/app/bar/services/search/client/wiki-collection-items';
export class SearchPopupSettings {
  private static readonly gotoDefault: GoToSourceSettings = {
    id: 'gotoDefault',
    type: 'goto',
    allowedGotoItems: ['goto', 'search', 'wikis'],
    header: {
      title: 'Go to',
      clickable: false,
    },
  };

  private static readonly goto: GoToSourceSettings = {
    id: 'goto',
    type: 'goto',
    allowedGotoItems: ['help', 'goto', 'search', 'wikis', 'settings'],
    showHeaderButton: true,
    header: {
      title: 'Go to',
      titleEnd: resultHeaderCountKey,
      clickable: true,
    },
  };
  private static readonly chat: GoToSourceSettings = {
    id: 'chat',
    type: 'goto',
    allowedGotoItems: ['wikis', 'assistants', 'search', 'settings'],
    showHeaderButton: true,
    header: {
      title: 'Chat',
      titleEnd: resultHeaderCountKey,
      clickable: true,
    },
  };

  private static readonly collections: CollectionsSourceSettings = {
    id: 'collections',
    type: 'collection',
    showHeaderButton: true,
    header: {
      title: 'Collections',
      titleEnd: resultHeaderCountKey,
      clickable: true,
    },
    filters: {
      preFilters: {
        'collection-type': ['Static', 'Live'],
      },
    },
    showGroupHeader: true,
  };

  private static readonly wikis: CollectionsSourceSettings = {
    id: 'wikis',
    type: 'collection',
    showGroupHeader: true,
    header: {
      title: 'Wikis',
      titleEnd: resultHeaderCountKey,
      clickable: true,
    },
    filters: {
      preFilters: {
        'collection-type': ['Wiki'],
      },
    },
  };
  private static readonly cards: WikiCollectionItemSourceSettings = {
    type: 'wiki-collection-items',
    withIcon: true,
    showHeaderButton: true,
    cache: 'only',
    header: {
      title: 'Cards',
      titleEnd: resultHeaderCountKey,
      clickable: true,
    },
    searchView: true,
  };

  private static readonly localActions: LocalActionSourceSettings = {
    type: 'local-action',
    header: {
      title: 'Create New',
      titleEnd: resultHeaderCountKey,
      clickable: true,
    },
    withGoLinksAction: true,
    filters: { excludeFilters: ['SlackMessage'] },
  };

  private static readonly answers: AnswersSourceSettings = {
    type: 'answers',
    minWords: 3,
    maxCount: 1,
    onlyClassifyAi: true,
    redirectToChat: true,
    ignoreFilters: true,
    shouldClassifyAi: true,
  };

  static readonly assistants: AssistantsSourceSettings = {
    type: 'assistants',
    header: {
      title: 'Assistants',
      titleEnd: resultHeaderCountKey,
    },
    noFooter: true,
  };
  static settings: { default: SourceSettings[]; search: SourceSettings[]; [view: string]: SourceSettings[] } = {
    default: [SearchPopupSettings.gotoDefault],
    search: [
      SearchPopupSettings.answers,
      SearchPopupSettings.goto,
      SearchPopupSettings.localActions,
      SearchPopupSettings.collections,
      SearchPopupSettings.wikis,
      SearchPopupSettings.cards,
      SearchPopupSettings.chat,
      SearchPopupSettings.assistants,
    ],
  };
}
