import { Injectable } from '@angular/core';
import { Assistants, Chats, Experiences, Filters, Search, SessionInfo } from '@local/client-contracts';
import {
  getAssistantDescription,
  getAssistantTitle,
  getGeneralAssistantIcon,
  isEmbed,
  isExtension,
  isNativeWindow,
} from '@local/common-web';
import { EmbedOptions, EmbedService } from '@shared/embed.service';
import { AppService } from '@shared/services/app.service';
import { SessionService } from '@shared/services/session.service';
import { upperFirst } from 'lodash';
import { firstValueFrom, ReplaySubject } from 'rxjs';
import { AnswerNoResults, AnswerSearchItem, StaticSearchItem } from '../views';
import { StaticSearchItemType } from '../views/results/components/static-search-item/static-search-item.model';
import { AnswersSourceSettings, SearchRequest } from './search/client';

@Injectable({
  providedIn: 'root',
})
export class AnswerSearchService {
  private isNative = isNativeWindow();
  private isEmbed = isEmbed();
  private isLauncher: boolean;
  private isExtension = isExtension();
  private embedOption: EmbedOptions;
  resourcePopupOpen$: ReplaySubject<{ index: number; id?: string }> = new ReplaySubject(1);
  constructor(
    private sessionService: SessionService,
    private embedService: EmbedService,
    private appService: AppService
  ) {
    this.embedService?.options$?.subscribe((op) => (this.embedOption = op));
    this.appService.windowStyle$.subscribe((b) => (this.isLauncher = b != 'standard'));
  }

  async buildExternalRequest(
    request: SearchRequest<AnswersSourceSettings>,
    query: string
  ): Promise<Assistants.ExternalAnswersSearchRequest> {
    const req = await this.buildBaseRequest(request, query);

    return {
      ...req,
      knowledgeType: Experiences.KnowledgeType.External,
      blobIds: request.sourceSettings.blobIds,
      action: request.sourceSettings.action,
    };
  }

  private async buildBaseRequest(
    request: SearchRequest<AnswersSourceSettings>,
    query: string
  ): Promise<Assistants.BaseAnswersSearchRequest> {
    const currentSession = await firstValueFrom(this.sessionService.current$);

    return {
      query,
      context: this.buildQuestionContext(currentSession, request.sourceSettings.source),
      sessionId: request.sessionId,
      experienceId: request.sourceSettings.assistantId,
      allowAllQuestionQueries: request.sourceSettings.allowAllQuestionQueries,
      chat: request.sourceSettings.chat,
      answerCacheTime: request.sourceSettings?.answerCacheTime,
    };
  }

  buildQuestionContext(currentSession: SessionInfo, location?: Assistants.AnswerLocationType): Assistants.QuestionContext {
    return {
      source: this.isNative ? 'Desktop' : 'Web',
      platform: this.getPlatformType(),
      location: this.getLocationType(location),
      simulation: currentSession.provider === 'unleash' ? 'true' : 'false',
    };
  }

  async buildInternalRequest(
    request: SearchRequest<AnswersSourceSettings>,
    query: string,
    mergeFilters: Filters.Values
  ): Promise<Assistants.InternalAnswersSearchRequest> {
    const baseRequest = await this.buildBaseRequest(request, query);

    return {
      ...baseRequest,
      filters: mergeFilters,
      answersResultsTypes: ['KnowledgeBase', 'Cards', 'ResourceLookup', 'Conversations'] as Assistants.AnswersResultType[],
      knowledgeType: Experiences.KnowledgeType.Internal,
      onlyClassifyAi: request.sourceSettings.onlyClassifyAi,
      shouldClassifyAi: request.sourceSettings.shouldClassifyAi,
    };
  }

  private getLocationType(pageSource?: Assistants.AnswerLocationType): Assistants.AnswerLocationType {
    if (pageSource) {
      return pageSource;
    }
    if (this.isEmbed) {
      if (this.embedOption?.slug) {
        return upperFirst(this.embedOption.slug.split(':')?.[0] || '') as Assistants.AnswerLocationType;
      }
      return 'Other';
    }
    return 'Search';
  }

  private getPlatformType() {
    if (this.isExtension) {
      return 'Extension';
    }
    if (this.isEmbed) {
      return 'Embed';
    }
    if (this.isLauncher) {
      return 'Launcher';
    }
    return 'App';
  }

  insertReferencesIntoText(
    federatedAnswer: Assistants.FederatedAnswer,
    resources: Search.ResultResourceItem[] | Chats.MessageResource[]
  ): string {
    const text = federatedAnswer?.answer;
    if (!text) {
      return;
    }

    const references = federatedAnswer?.references;
    if (!references?.length || (!federatedAnswer.resourceIds?.length && !resources?.length)) {
      return text;
    }

    references.sort((a, b) => b.position - a.position);

    let updatedText = text;
    references.forEach((reference) => {
      const position = reference.position;
      const referencesIds = [...reference.referenceIds].reverse();

      referencesIds.map((referenceId) => {
        let index;
        if (resources?.length) {
          index = resources.findIndex((resource) => resource.id === referenceId || resource.resourceId === referenceId);
        } else {
          index = federatedAnswer.resourceIds.indexOf(referenceId);
        }
        if (index < 0) {
          return;
        }
        updatedText = `${updatedText.slice(0, position)} %%${index + 1}%%${updatedText.slice(position)}`;
      });
    });

    return updatedText;
  }

  buildFollowUpItem(res: Assistants.AnswersSearchResponse): StaticSearchItem {
    return {
      type: 'static-search-item',
      icon: { type: 'font-icon', value: 'icon-bubble-dots' },
      title: AnswerNoResults.includes(res.status) ? 'Go to chat' : 'Ask a Follow-up',
      invokeType: 'follow-up-chat',
    };
  }

  buildAssistantAnswerStaticSearchItem(
    assistant: Experiences.ExperienceItem,
    invokeType: StaticSearchItemType = 'open-chat'
  ): StaticSearchItem {
    const searchAnswerItem: StaticSearchItem = {
      type: 'static-search-item',
      icon: getGeneralAssistantIcon(assistant),
      title: `Ask ${getAssistantTitle(assistant)}`,
      description: getAssistantDescription(assistant),
      invokeType,
      data: { assistantId: assistant.id },
    };
    return searchAnswerItem;
  }

  buildLoadingItem(query: string, res: Assistants.AnswersSearchResponse) {
    return {
      type: 'answer',
      query,
      state: res.status,
      searchId: res.searchId,
      debugInfo: res.debugInfo,
      intent: res.intent,
      progress: res.progress,
      reason: res.reason,
      hasAnswer: !!res.federatedAnswer?.answer,
    } as AnswerSearchItem;
  }

  buildAnswerStaticSearchItem(currentSession: SessionInfo, request: SearchRequest<AnswersSourceSettings>): StaticSearchItem {
    return {
      type: 'static-search-item',
      icon: { type: 'font-icon', value: 'icon-answer' },
      title: `Ask ${currentSession?.workspace?.name}`,
      description: request.sourceSettings.redirectToChat
        ? 'Chat with Unleash AI for answers from your company’s knowledge'
        : 'Use Unleash AI to find answers based on your company’s knowledge',
      invokeType: request.sourceSettings.redirectToChat ? 'open-chat' : 'search-answer',
    };
  }
}
