import { Filters, Resources, SaveFilters } from '@local/client-contracts';
import { invoker } from '@unleash-tech/js-rpc';
import { Observable } from 'rxjs';

export class SaveFiltersRpcInvoker implements SaveFilters.Service {
  @invoker
  get all$(): Observable<SaveFilters.SaveFilter[]> {
    return;
  }

  @invoker
  create(saveFilters: SaveFilters.SaveFilter): Promise<SaveFilters.SaveFilter> {
    return;
  }

  @invoker
  delete(id: string): Promise<void> {
    return;
  }

  @invoker
  update(id: string, actions: SaveFilters.UpdateAction[]): Promise<void> {
    return;
  }

  @invoker
  refresh(): Promise<void> {
    return;
  }

  @invoker
  buildClientFilter(filter: Resources.SearchAppliedFilters): Promise<Filters.Values> {
    return;
  }
}
