import { MemorySearch } from '@local/client-contracts';
import { Constants, ManualPromise, observable } from '@local/common';
import { LogService } from '@shared/services';
import { ApplicationsService } from '@shared/services/applications.service';
import { LinksService } from '@shared/services/links.service';
import { MemorySearchService } from '@shared/services/memory-search.service';
import { ResourcesService } from '@shared/services/resources.service';
import { VisitsService } from '@shared/services/visits.service';
import { concatAll, firstValueFrom, from, map, Observable } from 'rxjs';
import { SearchResults } from 'src/app/bar/views/results/models/results-types';
import { Action } from 'src/app/bar/views/results/models/view-filters';
import { FiltersService } from '../../../filters.service';
import { ResultsService } from '../../../results.service';
import { ResourcesMemorySearchClient } from '../resources-memory-search/resources-memory-search-client';
import { SearchRequest } from '../search-request';
import { SearchResponse } from '../search-response';
import { VisitsSourceSettings } from './visits-source-settings';
import { PeopleService } from 'src/app/bar/views/preview/people-preview/services/people.service';
import { WorkspacesService } from '../../../workspaces.service';

export class VisitsSearchClient extends ResourcesMemorySearchClient<VisitsSourceSettings> {
  private ready: { [id: string]: { promise: ManualPromise<void>; searchId: number } } = {};
  constructor(
    logService: LogService,
    memorySearchService: MemorySearchService,
    private visitsService: VisitsService,
    private resultsService: ResultsService,
    private resourcesService: ResourcesService,
    appsService: ApplicationsService,
    filters: FiltersService,
    linksService: LinksService,
    peopleService: PeopleService,
    protected workspaceService: WorkspacesService
  ) {
    super(logService, memorySearchService, filters, appsService, linksService, peopleService, ['Alphabetical', 'Time']);
    this.logger = logService.scope('visits-search-client');
  }

  @observable
  getInput(request: SearchRequest<VisitsSourceSettings>, response: SearchResponse): Observable<MemorySearch.Item[]> {
    // const sessionId = string
    const settings = request.sourceSettings;

    return from(
      (async () => {
        const disabledItems = [];
        const isDisabledWiki = await this.workspaceService.isFeatureFlagDisabled(Constants.DISABLED_WIKIS_WORKSPACE_FEATURE_FLAG);
        if (isDisabledWiki) {
          disabledItems.push('wiki:card');
          disabledItems.push('wiki:card:file');
        }

        const res = await this.visitsService.search({
          sessionName: request.sessionName,
          filters: request.sourceSettings.filters,
          cache: settings.cache,
          defaultSort: settings.defaultSort,
          disableHighlights: settings.disableHighlights,
          disableSnippet: settings.disableSnippet,
          displayOneLine: settings.displayOneLine,
          excludeTypes: [...(settings.excludeTypes || []), ...disabledItems],
          includeInteractions: settings.includeInteractions,
          lastVisitDaysAgo: settings.lastVisitDaysAgo,
          maxCount: settings.maxCount,
          minDays: settings.minDays,
          minVisitCount: settings.minVisits,
          query: request.query,
          resource: true,
          sorting: settings.sorting,
        });
        if (response.cancelled) {
          return;
        }
        return res.pipe(
          map((r) => {
            response.extra = { totalResults: r.totalResults };
            return r.visits;
          })
        );
      })()
    ).pipe(concatAll());
  }

  async rank(queryTokens: string[], items: MemorySearch.Item[]): Promise<MemorySearch.Item[]> {
    return items;
  }

  async getOutput(items: MemorySearch.Item[], sourceSettings: VisitsSourceSettings): Promise<SearchResults[]> {
    this.adjustItemIcons(items, sourceSettings);
    const typeMap = await firstValueFrom(this.resourcesService.typeMap$);
    const arrOutput: SearchResults[] = [];
    for (const item of items) {
      item.data.filterType = typeMap[item.data?.resource?.type]?.title;
      const action: Action = await this.resultsService.getResultAction(item.data);
      arrOutput.push({ ...item.data, action });
    }
    return arrOutput;
  }

  destroy(id: number, sessionName: string): void {
    // const promise = this.ready[sessionId]?.promise;
    // if (promise && !promise.status) {
    //   promise.resolve();
    //   delete this.ready[sessionId];
    // }
    // this.ready[sessionId] = { promise: new ManualPromise(), searchId: id };
    this.visitsService.cancelSearches(sessionName); //.then(() => {
    //   if (this.ready[sessionId]?.searchId === id) {
    //     this.ready[sessionId]?.promise.resolve();
    //   }
    // });
  }
}
