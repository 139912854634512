<div *ngFor="let option of options; let i = index">
  <p-radioButton
    class="u-radio-button"
    [label]="option.label"
    [value]="option.value"
    [(ngModel)]="selectedOption"
    (onClick)="onRadioButtonClick(option)"
  ></p-radioButton>

  <ng-container *ngIf="optionTemplate && selectedOption === option.value">
    <ng-container *ngTemplateOutlet="optionTemplate"></ng-container>
  </ng-container>
</div>
