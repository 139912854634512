import { Injectable } from '@angular/core';
import { Config } from '@environments/config';
import { Results, Workspace } from '@local/client-contracts';
import { WorkspacesRpcInvoker, observable } from '@local/common';
import { getTimeDifference } from '@local/ts-infra';
import { IconConstants } from '@shared/consts';
import { AccountsService } from '@shared/services/accounts.service';
import { AppService } from '@shared/services/app.service';
import { ClientStorageService } from '@shared/services/client-storage.service';
import { SessionService } from '@shared/services/session.service';
import Cookies from 'js-cookie';
import { Observable, ReplaySubject, filter, firstValueFrom, map } from 'rxjs';
import { NativeServicesRpcService, ServicesRpcService } from '../../shared/services';

@Injectable({
  providedIn: 'root',
})
export class WorkspacesService {
  isOwnerOrAdmin: boolean;

  private service: Workspace.Service;
  private _ownerOrAdmin$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  private _onTrial$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  private _trialDaysLeft$: ReplaySubject<number> = new ReplaySubject<number>(null);
  private currentWorkspace: Workspace.Workspace;
  private readonly WORKSPACE_ALLOWED_CHARACTERS = /^[A-Za-z0-9!@#$%^&*()_+\-=?.' ]*$/;
  private isFeatureFlagDisabledMap: Map<string, boolean> = new Map();

  get dedicateWorkspaceId(): string {
    return Config.isSelfHosted ? 'default' : Cookies.get('workspaceId');
  }

  constructor(
    services: ServicesRpcService,
    nativeServices: NativeServicesRpcService,
    private appService: AppService,
    private session: SessionService,
    private accountsService: AccountsService,
    private clientStoreService: ClientStorageService
  ) {
    this.service = (nativeServices || services).invokeWith(WorkspacesRpcInvoker, 'workspaces');
    this.current$.pipe(filter((w) => !!w)).subscribe((workspace) => {
      this.isOwnerOrAdmin = workspace.isAdmin || workspace.isOwner;
      this._onTrial$.next(this.isTrialPlan(workspace));
      this._ownerOrAdmin$.next(this.isOwnerOrAdmin);
      this._trialDaysLeft$.next(Math.round(getTimeDifference(workspace.plan?.endDate, 'day')));
      this.initFeatureFlagsMap(workspace.settings.features);
    });
    this.session.current$.subscribe((c) => (this.currentWorkspace = c?.workspace));
    this.appService.focus$.pipe(filter((f) => !!f)).subscribe(() => this.refresh());
  }

  @observable
  signInSettings$(workspaceId: string, returnUrl: string, state: string): Observable<Workspace.WorkspaceSignInSettings> {
    return this.service.signInSettings$(workspaceId, returnUrl, state);
  }

  get all$(): Observable<Workspace.Workspace[]> {
    // we currently support only 1 workspace per user
    return this.service.all$;
  }

  get current$(): Observable<Workspace.Workspace> {
    return this.session.current$.pipe(map((s) => s?.workspace));
  }

  get ownerOrAdmin$() {
    return this._ownerOrAdmin$;
  }

  get onTrial$() {
    return this._onTrial$;
  }

  get trialDaysLeft$() {
    return this._trialDaysLeft$;
  }

  isMe(accountId: string): boolean {
    return accountId === this.currentWorkspace?.accountId;
  }

  refresh() {
    return this.service.refresh();
  }
  // user can not delete if - user is the owner of the workspace , the workspace is of type 'TEAM' , and has more then 2 members
  canDeleteWorkspace$(): Observable<boolean> {
    return this.current$.pipe(
      map((workspace) => {
        if (!workspace) return false;
        return !(this.isOwnerOrAdmin && workspace.members > 1);
      })
    );
  }

  async update(workspace: Partial<Workspace.Workspace>): Promise<Workspace.Workspace> {
    const res = await this.service.update({ ...this.currentWorkspace, ...workspace });
    await this.accountsService.refresh();
    return res;
  }

  delete(id: string): Promise<void> {
    return this.service.delete(id);
  }

  leave(id: string): Promise<void> {
    return this.service.leave(id);
  }

  async deleteMember(accountId: string): Promise<void> {
    const workspaceId = (await firstValueFrom(this.current$)).id;
    return this.service.deleteMember(workspaceId, accountId);
  }

  getLogo(disableDefault: boolean = false): Results.Icon {
    const workspace = this.currentWorkspace;
    const defaultAvatar = IconConstants.APP_ICON;
    let value: Results.Icon = disableDefault ? { lightUrl: '' } : defaultAvatar;
    if (workspace?.media?.logo) {
      const { light, dark } = workspace.media.logo;
      if (light || dark) {
        if (light) {
          value = { ...value, lightUrl: light };
        }
        if (dark) {
          value = { ...value, darkUrl: dark };
        }
      }
    }
    return value;
  }

  getBanner(disableDefault = false): Workspace.Themes {
    const workspace = this.currentWorkspace;
    let value: Workspace.Themes = disableDefault
      ? {}
      : { light: IconConstants.APP_BANNER.lightUrl, dark: IconConstants.APP_BANNER.darkUrl };
    if (workspace?.media?.banner) {
      const { light, dark } = workspace.media.banner;
      if (light || dark) {
        if (light) {
          value = { ...value, light };
        }
        if (dark) {
          value = { ...value, dark };
        }
      }
    }
    return value;
  }

  workspaceNameValid(name: string) {
    return this.WORKSPACE_ALLOWED_CHARACTERS.test(name) && name.length > 1;
  }

  invite(emails: string[]) {
    return this.service.invite(emails);
  }

  getInviteLink() {
    return this.service.getInviteLink();
  }

  inviteCodeStatus(inviteCode: string): Promise<Workspace.WorkspaceCodeStatus> {
    return this.service.inviteCodeStatus(inviteCode);
  }

  private isTrialPlan(workspace: Workspace.Workspace) {
    if (!workspace?.plan) return false;
    return !workspace.plan.isPaid && workspace.plan.endDate !== undefined;
  }

  updateFeatureControl(features: Workspace.Features) {
    const settings = this.currentWorkspace.settings;
    settings.features = features;
    return this.update({ settings });
  }

  isFeatureFlagDisabled$(flag: string) {
    return this.service.isFeatureFlagDisabled$(flag);
  }

  isAnyFeatureFlagEnabled$(flags: string[]) {
    return this.service.isAnyFeatureFlagEnabled$(flags);
  }

  isFeatureFlagDisabled(flag: string): Promise<boolean> {
    return firstValueFrom(this.isFeatureFlagDisabled$(flag));
  }

  initFeatureFlagsMap(features: Workspace.Features) {
    if (!features) {
      return;
    }
    for (const [key, value] of Object.entries(features)) {
      this.isFeatureFlagDisabledMap.set(key, value.disabled);
    }
  }

  isFeatureDisabled(flag: string): boolean {
    return this.isFeatureFlagDisabledMap.get(flag);
  }

  isAnyFeatureFlagEnabled(flags: string[]) {
    return flags.some((flag) => !this.isFeatureDisabled(flag));
  }
}
