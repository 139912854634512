<div class="panel-search-container">
  <div class="search-inputs-container">
    <u-input
      #searchInput
      (onChange)="onQueryChange($event)"
      (onClear)="clearSearchParams()"
      (onEnter)="onEnterInput()"
      (onFocus)="inputSearchOnFocus = true"
      (onBlur)="inputSearchOnFocus = false"
      [model]="inputQuery$ | async"
      [isSearchInput]="true"
      [placeholder]="'Search for anything...'"
      [styles]="{ borderRadius: '16px', fontSize: '14px', height: '36px', flexGrow: 1 }"
    >
    </u-input>
    <div class="search-buttons-container">
      <results-filters-box
        [filters]="resultFilters()"
        [enableClearAll]="true"
        [showClearIcon]="false"
        (filterChange)="onFilterChange($event)"
        (clearAll)="clearAllFilters()"
      ></results-filters-box>
      <u-button
        #sortButtonRef
        [versionNumber]="2"
        typeElement="secondary"
        fontIcon="sort-list-down"
        [sizeElement]="'small'"
        (onClick)="openSort(sortButtonRef)"
        [pTooltip]="'Sort'"
        tooltipStyleClass="u-tooltip"
        tooltipPosition="bottom"
      ></u-button>
    </div>
  </div>
  <search-results-list
    [loading]="loading()"
    [displayedContext]="displayContext()"
    [items]="items()"
    [showSearchResults]="false"
    [listName]="'search-side-panel'"
    pageType="search-side-panel"
    [layoutMode]="'list'"
    [defaultResultSections]="{
      showSubtitleInSecondLine: true,
      showFavoriteAction: false,
      showContextMenu: false,
      calcBullets: true,
      enableAction: true,
    }"
    [componentFocused]="true"
    [smallView]="true"
    [emptyResults]="emptyResults()"
    [isLoadingAnswer]="isLoadingAnswer()"
    (nextPage)="prepareNextPage($event)"
    (resultHeaderClick)="expandSearchGroup($event)"
    (openChatClicked)="followUpChat($event)"
    (selectedIndexChanged)="onSelectedIndexChanged($event)"
  >
  </search-results-list>
</div>
