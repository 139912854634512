<h1 class="suggest-tagline">Welcome back!</h1>

<p *ngIf="!isLinux" class="suggest-text">
  You’ve been gone for a while and so your data needs to be synced again. <br />
  In the meantime, only local search is available.
</p>

<p *ngIf="isLinux" class="suggest-text">You’ve been gone for a while and so your data needs to be synced again.</p>

<u-button label="Sync now" class="sync-button" type="primary" [styles]="{ width: '224px' }" (onClick)="resyncClick('sync')"></u-button>
<u-button
  label="No, I will do it later"
  class="later-button"
  type="secondary"
  [styles]="{ width: '224px' }"
  (click)="resyncClick('no_sync')"
></u-button>
