<div class="history-popup-wrapper" (click)="closePopup()">
  <div #contextContainer class="history-popup-container" (click)="$event.stopPropagation()">
    <div class="history-popup-header">
      <span>Verification Requests</span>
      <u-icon class="close-icon" (click)="closePopup()" [model]="closeIcon" [styles]="{ fontSize: '12px' }"></u-icon>
    </div>
    <ng-scrollbar visibility="hover">
      <div class="history-popup-content">
        <request-history-item *ngFor="let item of requests" [item]="item"></request-history-item>
      </div>
    </ng-scrollbar>
    <div class="history-popup-footer" *ngIf="isVerifier">
      <u-button [label]="'Dismiss'" type="primary" (onClick)="onDismissRequests()" [styles]="{ height: '32px' }"></u-button>
    </div>
  </div>
</div>
