import { Assistants, Experiences, Filters, Search } from '@local/client-contracts';
import { isGeneralAssistant } from '@local/common-web';
import { EventInfo, EventsService, LogService } from '@shared/services';
import { AssistantsService } from '@shared/services/assistants.service';
import { SessionService } from '@shared/services/session.service';
import { Logger } from '@unleash-tech/js-logger';
import { isEmpty } from 'lodash';
import { Subscription, firstValueFrom } from 'rxjs';
import { buildResourceLookupFromJson } from 'src/app/bar/utils/formatting-utils';
import {
  AnswerCompletionTypes,
  AnswerGenerateState,
  AnswerSearchItem,
  AnswerSearchReady,
  SearchResults,
  StaticSearchItem,
  TelemetryTrigger,
} from 'src/app/bar/views/results/models/results-types';
import { AnswerResourcesService } from '../../../answer-resources.service';
import { AnswerSearchService } from '../../../answers-search.service';
import { ChatAssistantsService } from '../../../chat-assistants.service';
import { ExperiencesService } from '../../../experiences.service';
import { FiltersService } from '../../../filters.service';
import { ResultMarkdownService } from '../../../result-markdown.service';
import { ResultsService } from '../../../results.service';
import { WikiCardsService } from '../../../wikis/wiki-cards.service';
import { SearchClient } from '../search-client';
import { SearchRequest } from '../search-request';
import { SearchResponse } from '../search-response';
import { SearchResponseType } from '../search-response-type';
import { AnswersSourceSettings } from './answers-source-settings';

export class AnswersSearchClient implements SearchClient<AnswersSourceSettings> {
  private readonly SEPARATOR = /\s+/;
  private readonly INTERVAL_ANSWERS = 15;
  private logger: Logger;

  private instances: { [sessionName: string]: Subscription } = {};

  constructor(
    logService: LogService,
    private resultsService: ResultsService,
    private resultMarkdownService: ResultMarkdownService,
    private sessionService: SessionService,
    private wikiCardService: WikiCardsService,
    private assistantService: AssistantsService,
    private resourcesService: AnswerResourcesService,
    private answerSearchService: AnswerSearchService,
    private chatAssistantsService: ChatAssistantsService,
    private filtersService: FiltersService,
    private experiencesService: ExperiencesService,
    private eventsService: EventsService
  ) {
    this.logger = logService.scope('answers');
  }

  supportsSort(_sort: Search.Sort): boolean {
    return true;
  }

  supportsFilters(_filters: Filters.Values): boolean {
    return true;
  }

  search(request: SearchRequest<AnswersSourceSettings>, response: SearchResponse): SearchResponseType {
    const subscription = this.instances[request.sessionName];
    if (subscription) {
      subscription.unsubscribe();
      this.instances[request.sessionName] = null;
    }
    return this.innerSearch(request, response);
  }

  nextPage(_request: SearchRequest<AnswersSourceSettings>, _response: SearchResponse, _trigger: TelemetryTrigger): Promise<void> {
    return;
  }

  destroy(id: number, sessionName: string): void {
    const subscription = this.instances[sessionName];
    if (subscription) {
      subscription?.unsubscribe();
      delete this.instances[sessionName];
    }
    this.resourcesService.clearResources();
  }

  private isQueryTooShort(query: string, sourceSettings: AnswersSourceSettings) {
    return !query || query.split(this.SEPARATOR).length < sourceSettings.minWords;
  }

  private async buildSearchAnswerRequest(request: SearchRequest<AnswersSourceSettings>, query: string) {
    let knowledgeType = Experiences.KnowledgeType.Internal;
    if (request.sourceSettings.assistantId) {
      const experience = await this.experiencesService.getExperience(request.sourceSettings.assistantId, 'only');
      knowledgeType = (isGeneralAssistant(experience) && experience?.settings?.general?.knowledgeType) || knowledgeType;
    }
    if (knowledgeType == Experiences.KnowledgeType.External) {
      return this.answerSearchService.buildExternalRequest(request, query);
    }

    const mergeFilters = request.sourceSettings.ignoreFilters ? null : this.filtersService.allFilters;
    if (mergeFilters && request.sourceSettings.collectionId) {
      mergeFilters['collectionId'] = [request.sourceSettings.collectionId];
    }
    return this.answerSearchService.buildInternalRequest(request, query, mergeFilters);
  }

  private async innerSearch(request: SearchRequest<AnswersSourceSettings>, response: SearchResponse) {
    const sourceSettings = request.sourceSettings;

    const query: string = request.query?.trim();
    if (this.isQueryTooShort(query, sourceSettings) && !sourceSettings.blobIds?.length && isEmpty(sourceSettings.action || {})) {
      response.complete(true);
      return;
    }

    const req = await this.buildSearchAnswerRequest(request, query);
    if (response.cancelled) {
      return;
    }

    let renderResultsInterval: string | number | NodeJS.Timeout;
    const tasks: (() => Promise<void>)[] = [];
    let searchDone = false;
    const reqFilters = (req as Assistants.InternalAnswersSearchRequest)?.filters;
    this.instances[request.sessionName] = this.assistantService.answers$(req).subscribe({
      next: (res) => {
        if (response.cancelled) {
          clearInterval(renderResultsInterval);
          return;
        }
        const task = () => this.handleResults(res, request, response, query, req.knowledgeType, reqFilters);
        if (!AnswerGenerateState.includes(res.status)) {
          task();
          return;
        }
        tasks.push(task);
        if (!renderResultsInterval) {
          // set a fixed pace of interval even if backend returns a bulk of events at once
          renderResultsInterval = setInterval(() => {
            if (response.cancelled) {
              clearInterval(renderResultsInterval);
              return;
            }
            const currentTask = tasks.shift();
            if (!currentTask) {
              if (searchDone) {
                clearInterval(renderResultsInterval);
              }
              return;
            }
            currentTask();
          }, this.INTERVAL_ANSWERS);
        }
      },
      error: () => {
        searchDone = true;
      },
      complete: () => {
        searchDone = true;
      },
    });
  }

  private async handleResults(
    res: Assistants.AnswersSearchResponse,
    request: SearchRequest<AnswersSourceSettings>,
    response: SearchResponse,
    query: string,
    knowledgeType: Experiences.KnowledgeType,
    filters: Filters.Values
  ) {
    let items = [];
    switch (res.status) {
      case 'Skipped':
        break;
      case 'IsQuestion': {
        items = await this.handleIsQuestion(request, items, filters);
        break;
      }
      case 'NoResults':
      case 'Loading':
      case 'RephraseRequired': {
        const loadingItem = this.answerSearchService.buildLoadingItem(query, res);
        items = [loadingItem];
        break;
      }
      case 'Generating':
      case 'GeneratingDone':
      case 'Full':
      case 'Cache':
        items =
          knowledgeType === Experiences.KnowledgeType.External
            ? await this.onExternalAnswerReady(request, res, query)
            : await this.onInternalAnswerReady(request, response, res, query);
        break;
    }
    if (request.sourceSettings.displayOpenChatResult && AnswerSearchReady.includes(res.status)) {
      const openChatItem: StaticSearchItem = this.answerSearchService.buildFollowUpItem(res);
      const currentSession = await firstValueFrom(this.sessionService.current$);
      openChatItem.description = `Ask about anything in ${currentSession?.workspace?.name || 'N/A'}`;
      items.push(openChatItem);
    }
    response.extra = {};
    if (res.searchId) {
      response.extra.searchId = res.searchId;
    }
    if (res.status) {
      response.extra.responseStatus = res.status;
    }
    response.items = items;
    if (res.status === 'Loading') {
      response.notifyUpdated();
    } else {
      response.complete(true);
      if (AnswerCompletionTypes.includes(res.status)) {
        this.sendAnswerCompletedEvent(request.sourceSettings.type, request.sessionId, res.searchId, query, request.clientSearchId);
      }
    }
  }

  private sendAnswerCompletedEvent(type: string, sessionId: string, searchId: string, query: string, clientSearchId: string) {
    const searchEnd: Partial<EventInfo> = {
      label: 'answer_completed',
      search: {
        origin: type,
        query,
        sessionId,
        searchId,
        clientSearchId,
      },
    };
    this.eventsService.event('search.end', searchEnd);
  }

  private async handleIsQuestion(request: SearchRequest<AnswersSourceSettings>, items: any[], filters: Filters.Values) {
    let defaultSearchAnswerItem: StaticSearchItem;
    if (request.sourceSettings.assistantId) {
      const globalAssistant = await this.experiencesService.getExperience(request.sourceSettings.assistantId, 'only');
      defaultSearchAnswerItem = this.answerSearchService.buildAssistantAnswerStaticSearchItem(globalAssistant, 'search-answer');
    } else {
      const currentSession = await firstValueFrom(this.sessionService.current$);
      defaultSearchAnswerItem = this.answerSearchService.buildAnswerStaticSearchItem(currentSession, request);
    }
    items = [defaultSearchAnswerItem];
    const noFilters = isEmpty(filters);
    if (!request.sourceSettings.assistantId && noFilters) {
      const assistantItems = await this.getQuestionAssistantsItems();
      items.push(...(assistantItems || []));
    }
    return items;
  }

  private async getQuestionAssistantsItems(): Promise<StaticSearchItem[]> {
    const assistants = await firstValueFrom(this.chatAssistantsService.assistantForSearch$);
    const items: StaticSearchItem[] = assistants.map((a) => this.answerSearchService.buildAssistantAnswerStaticSearchItem(a));
    items.sort((a, b) => {
      return a.title?.localeCompare(b.title);
    });
    return items;
  }

  private async onInternalAnswerReady(
    request: SearchRequest<AnswersSourceSettings>,
    response: SearchResponse,
    res: Assistants.AnswersSearchResponse,
    query: string
  ) {
    let items: SearchResults[] = (res.intent == 'ResourceLookup' ? res.results?.slice(0, 5) : res.results) || [];
    let text: string;
    let formattedAnswer: string;
    if (res.federatedAnswer) {
      text = formattedAnswer = res.federatedAnswer.answer;
      if (res.federatedAnswer.resourceIds?.length) {
        const uniqueIds = new Set();
        items = res.results
          ?.filter((r) => {
            if (res.federatedAnswer.resourceIds.includes(r.id) && !uniqueIds.has(r.id)) {
              uniqueIds.add(r.id);
              return true;
            }
            return false;
          })
          ?.slice(0, 12);
      }
    }
    for (const item of items) {
      item.action = await this.resultsService.getResultAction(item);
      if (response.cancelled) {
        return;
      }
    }
    if (res.intent === 'ResourceLookup') {
      if (!request.sourceSettings.preventFormattedAnswer) {
        formattedAnswer = buildResourceLookupFromJson(items as Search.ResultResourceItem[]);
      }
    }
    this.resourcesService.resources = items as Search.ResultResourceItem[];
    const template = { type: 'answer', query } as AnswerSearchItem;
    const searchItems: SearchResults[] = [];
    if (!request.sourceSettings.preventFormattedAnswer && res.federatedAnswer?.references) {
      formattedAnswer = this.answerSearchService.insertReferencesIntoText(res.federatedAnswer, items as Search.ResultResourceItem[]);
    }

    if (!request.sourceSettings.preventFormattedAnswer) {
      formattedAnswer = this.resultMarkdownService.render(
        formattedAnswer || '',
        res.federatedAnswer?.references?.length ? { items, sourceType: 'result-answer', searchId: res.searchId } : null
      );
    }

    const answerItem = {
      ...template,
      text,
      resources: items,
      references: res.federatedAnswer.references,
      resourceIds: res.federatedAnswer.resourceIds,
      state: res.status,
      debugInfo: res.debugInfo,
      searchId: res.searchId,
      intent: res.intent,
      assistantId: request.sourceSettings.assistantId,
      formattedAnswer,
    } as AnswerSearchItem;
    searchItems.push(answerItem);
    return searchItems;
  }

  private async onExternalAnswerReady(request: SearchRequest<AnswersSourceSettings>, res: Assistants.AnswersSearchResponse, query: string) {
    let text: string;
    let formattedAnswer: string;
    if (res.federatedAnswer) {
      text = formattedAnswer = res.federatedAnswer.answer;
    }

    const template = { type: 'answer', query, resources: [], references: [], resourceIds: [] } as AnswerSearchItem;
    const searchItems: SearchResults[] = [];

    const answerItem = {
      ...template,
      text,
      state: res.status,
      debugInfo: res.debugInfo,
      searchId: res.searchId,
      dataSources: res.results,
      formattedAnswer: request.sourceSettings.preventFormattedAnswer
        ? formattedAnswer
        : this.resultMarkdownService.render(formattedAnswer || '', null),
      tools: res.tools,
    } as AnswerSearchItem;
    searchItems.push(answerItem);
    return searchItems;
  }

  getTelemetryEndEvent(_response: SearchResponse): Partial<EventInfo>[] {
    const questionSuccess = AnswerSearchReady.includes(_response?.extra?.responseStatus);
    const endEvent: Partial<EventInfo> = { jsonData: { questionSuccess } };
    if (_response?.extra?.searchId) {
      endEvent.search = { searchId: _response.extra.searchId };
    }
    return [endEvent];
  }
}
