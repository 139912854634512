import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Params, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { SaveFiltersService } from '../../services/save-filters.service';
import { Filters } from '@local/client-contracts';
import { isEmbed, isExtension } from '@local/common-web';
import { getFiltersAsQueryParams } from '../../utils/filters-utils';
import { cloneDeep } from 'lodash';

@Injectable()
export class SaveFiltersGuard implements CanActivate {
  constructor(
    private saveFiltersService: SaveFiltersService,
    private router: Router
  ) {}
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    if (this.hasExistingFilterParams(route) || (isEmbed() && !isExtension())) {
      return true;
    }

    const saveFiltersResult = await this.validateSaveFilters();
    if (!saveFiltersResult.useSaveFilters) {
      return true;
    }
    const filtersQueryParams = saveFiltersResult.filtersQueryParams;
    if (this.hasAllFiltersQueryParams(route.queryParams, filtersQueryParams)) {
      return true;
    }

    return this.createRedirectUrlTree(state, route, filtersQueryParams);
  }

  private hasExistingFilterParams(route: ActivatedRouteSnapshot): boolean {
    return Object.keys(route.queryParams).some((key) => key.startsWith('if-'));
  }

  async validateSaveFilters(): Promise<{ useSaveFilters: boolean; filtersQueryParams: Filters.Values }> {
    const saveFilters = await firstValueFrom(this.saveFiltersService.all$);
    const saveFiltersCopy = cloneDeep(saveFilters);

    if (!saveFiltersCopy || !saveFiltersCopy[0]?.enable) {
      return { useSaveFilters: false, filtersQueryParams: {} };
    }

    saveFiltersCopy[0].filters = await this.saveFiltersService.buildClientFilter(saveFiltersCopy[0].filters);
    const filtersQueryParams = getFiltersAsQueryParams({
      ...saveFiltersCopy[0].filters,
      ...saveFiltersCopy[0].clientFilters,
    });
    return { useSaveFilters: true, filtersQueryParams };
  }

  private hasAllFiltersQueryParams(currentParams: Params, filtersQueryParams: Filters.Values): boolean {
    return Object.entries(filtersQueryParams).every(([key, values]) => {
      const currentValues = currentParams[key] ? (Array.isArray(currentParams[key]) ? currentParams[key] : [currentParams[key]]) : [];
      return (values as string[]).every((v) => currentValues.includes(v));
    });
  }

  private createRedirectUrlTree(state: RouterStateSnapshot, route: ActivatedRouteSnapshot, filtersQueryParams: Filters.Values): UrlTree {
    const mergedQueryParams = { ...route.queryParams };
    for (const [key, values] of Object.entries(filtersQueryParams)) {
      mergedQueryParams[key] = values;
    }

    const url = state.url.split('?')[0];
    return this.router.createUrlTree([url], { queryParams: mergedQueryParams });
  }
}
