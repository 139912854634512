<ng-container *ngIf="inputsReady">
  <div *ngIf="label" [innerHtml]="label" class="u-input-label"></div>
  <div class="u-input-container" [class.loading-right-input]="isLoadingRight">
    <i
      *ngIf="isLoading"
      class="pi pi-spin pi-spinner inline-extra-icon-left"
      [ngClass]="{}"
      [class.loading-right]="isLoadingRight"
      style="font-size: 14px"
    ></i>
    <i
      *ngIf="inlineExtraIcon"
      [ngClass]="'font-icon ' + inlineExtraIconClass + ' ' + inlineExtraIcon?.value"
      [style]="{ cursor: onInlineExtraIconClick.observed ? 'pointer' : 'default' }"
      [ngStyle]="inlineExtraIconStyle"
      tooltipPosition="bottom"
      [pTooltip]="pTooltipTextInlineExtraIcon"
      tooltipStyleClass="u-tooltip"
      (click)="onInlineExtraIconClick.observed && invokeInlineExtraIconClick($event)"
    ></i>
    <input
      #input
      id="input"
      [type]="type"
      [readonly]="readonly"
      [placeholder]="placeholder"
      class="u-input"
      [maxlength]="maxlength"
      pInputText
      [style]="styles"
      [style.color]="textColor"
      [style.backgroundColor]="backgroundColor"
      [disabled]="disabled"
      [(ngModel)]="model"
      (ngModelChange)="onChangeEvent($event)"
      (keydown)="keydown($event)"
      (keydown.enter)="onEnterEvent($event)"
      (paste)="onPaste($event)"
      (blur)="blur($event)"
      (focus)="focus($event)"
      [autocomplete]="autoComplete"
      [ngClass]="[inputSize + '-input', isSearchInput || inlineExtraIcon ? 'inline-extra-icon' : '', errorBorder ? 'error-border' : '']"
      [tooltipPosition]="positionOverflowTooltip"
      [pTooltip]="showOverflowTooltip ? inputTooltipText : ''"
      tooltipStyleClass="u-tooltip"
    />
    <ng-content></ng-content>
    <i
      *ngIf="!disabled && (isSearchInput || removeText) && model != null && model !== ''"
      class="pi pi-times right-icon clear-icon"
      (click)="onClearEvent()"
      tooltipPosition="bottom"
      [pTooltip]="pTooltipTextClear"
      tooltipStyleClass="u-tooltip"
      [ngClass]="{ 'has-extra-icon': extraIcon }"
    ></i>
    <div *ngIf="extraIcon && !disabled && (isSearchInput || removeText) && model != null && model !== ''" class="divider"></div>
    <u-icon (click)="invokeIconClick($event)" *ngIf="extraIcon" class="extra-icon" [model]="extraIcon"></u-icon>
  </div>
  <div class="error-text" *ngIf="(errorBorder && errorText) || errorTextOnly">
    {{ errorText || errorTextOnly }}
  </div>
</ng-container>
