import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  viewChild,
  ViewChild,
} from '@angular/core';
import { NavTree } from '@local/client-contracts';
import { KeyName } from '@local/ts-infra';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TreeComponent, TreeNode } from '@odymaui/angular-tree-component';
import { ITreeOptions } from '@odymaui/angular-tree-component/lib/defs/api';
import { LogService } from '@shared/services';
import { KeyboardService } from '@shared/services/keyboard.service';
import { RouterService } from '@shared/services/router.service';
import { Logger } from '@unleash-tech/js-logger';
import { cloneDeep } from 'lodash';
import { Observable, take } from 'rxjs';
import { CollectionsService } from 'src/app/bar/services/collections.service';
import { SidebarService } from 'src/app/bar/services/sidebar.service';
import { MultiChoicePopupType } from 'src/app/bar/views/collections-page/components/multi-choice-popup/multi-choice-popup.component';
import { COLLECTIONS_ROUTE_TYPES } from 'src/app/bar/views/collections-page/models';
import { NavTreeService } from '../../../../../services/nav-tree.service';
import { NgScrollbar } from 'ngx-scrollbar';

@UntilDestroy()
@Component({
  selector: 'nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavMenuComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(TreeComponent) private tree: TreeComponent;
  @Output() hasScroll = new EventEmitter<boolean>();

  private logger: Logger;
  private keysHandlerId: string;
  private scrollbar = viewChild<NgScrollbar>('scrollbarRef');

  nodes: NavTree.Node[];
  treeOptions: ITreeOptions;
  activeNode: any;
  nodes$: Observable<NavTree.Node[]>;
  activeActions = new Set();

  constructor(
    private cdr: ChangeDetectorRef,
    private navTreeService: NavTreeService,
    private sidebarService: SidebarService,
    private routerService: RouterService,
    logService: LogService,
    private collectionsService: CollectionsService,
    private keyboardService: KeyboardService
  ) {
    this.logger = logService.scope(this.constructor.name);
    this.nodes$ = this.sidebarService.nodes$.pipe(untilDestroyed(this));
  }

  ngOnInit(): void {
    this.sidebarService.activeNode$.pipe(untilDestroyed(this)).subscribe((activeNode) => {
      this.activeNode = activeNode;
      this.cdr.markForCheck();
    });

    this.keysHandlerId = this.keyboardService.registerKeyHandler((keys, event) => {
      const key = keys[0];
      this.onKeyDown(key, event);
    }, 6);
  }

  ngAfterViewInit(): void {
    this.treeOptions = this.sidebarService.getTreeOptions(this.tree);
    this.nodes$.pipe(untilDestroyed(this)).subscribe((nodes) => {
      this.nodes = cloneDeep(nodes);
    });
    if (this.tree) this.tree.treeModel.update();
    this.sidebarService.loadTree(this.tree, this.activeNode);
    this.cdr.markForCheck();
  }

  ngOnDestroy(): void {
    this.sidebarService.activeNode$.next(this.activeNode);
    this.navTreeService.unsubscribeAll();
    this.keyboardService.unregisterKeyHandler(this.keysHandlerId);
  }

  onKeyDown(key: KeyName, event: KeyboardEvent): void {
    if (key === 'ArrowUp' || key === 'ArrowDown') {
      // Prevent the default scrolling behavior
      event.preventDefault();
    }
  }

  unsubscribe(id: string) {
    this.navTreeService.unsubscribe(id);
  }

  onNodeClicked(node: TreeNode) {
    if (node.data.headerNode) {
      if (node.isExpanded) {
        node.collapse();
      } else {
        node.expand();
      }
    }
    if (!!node?.data?.id && node.data.id === this.sidebarService.activeNode$.getValue()?.data?.id) {
      const url = this.navTreeService.getNodeUrl(node);
      this.routerService.navigateByUrl(url == 'home' ? '' : url);
    }
  }

  onActionIconClick(model: NavTree.Node, event) {
    if (COLLECTIONS_ROUTE_TYPES.includes(model.id as any)) {
      event.stopPropagation();
      this.activeActions.add(model.id);
      this.cdr.markForCheck();
      const activeAction = this.collectionsService.openNewCollectionPopup(event.srcElement, true, null, model.id as MultiChoicePopupType);
      activeAction.destroy$.pipe(take(1)).subscribe(() => {
        this.activeActions.clear();
        this.cdr.markForCheck();
      });
    }
  }

  updateScrollBar() {
    this.hasScroll.emit(this.scrollbar()?.state.verticalUsed);
  }
}
