import { Injectable, NgZone } from '@angular/core';
import { ActivatedRouteSnapshot, Route, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { OnboardingState, SessionInfo, Workspace } from '@local/client-contracts';
import { Constants, ManualPromise, performanceCheckpoint } from '@local/common';
import { isEmbed, isExtension, isMobile, isNativeWindow } from '@local/common-web';
import { PopupRef, PopupService } from '@local/ui-infra';
import { pushTag } from '@shared/analytics';
import { escape } from 'lodash';
import { Observable, Subscription, combineLatest, firstValueFrom, takeUntil } from 'rxjs';
import { WorkspacesService } from '../bar/services';
import { PricingService } from '../bar/services/pricing.service';
import { GlobalErrorHandler } from '../global-error-handler';
import { InviteService } from '../onboarding-web/services/invite.service';
import { PlanCanceledPopupComponent } from './components/plan-canceled-popup/plan-canceled-popup.component';
import { SessionExpirePopupComponent } from './components/session-expire-popup/session-expire-popup.component';
import { TrialExpiredComponent } from './components/trial-expired-popup/trial-expired-popup.component';
import { EmbedService } from './embed.service';
import { LogService } from './services';
import { RouterService } from './services/router.service';
import { SessionStorageService } from './services/session-storage.service';
import { SessionService } from './services/session.service';
import { FlagsService } from './services/testim-flags.service';

@Injectable({
  providedIn: 'root',
})
export class Guard {
  private protectSub: Subscription;
  private first = true;
  private isEmbed = isEmbed();
  private isNativeWindow = isNativeWindow();
  private trialPopupRef: PopupRef<TrialExpiredComponent, any>;
  private planCanceledPopupRef: PopupRef<PlanCanceledPopupComponent, any>;

  constructor(
    private routerService: RouterService,
    private session: SessionService,
    private sessionStorage: SessionStorageService,
    private ngzone: NgZone,
    private globalErrorHandler: GlobalErrorHandler,
    private logger: LogService,
    private popupService: PopupService,
    private embedService: EmbedService,
    private inviteService: InviteService,
    private workspaceService: WorkspacesService,
    private flagService: FlagsService,
    private pricingService: PricingService
  ) {
    this.session.signout$.subscribe(() => this.unprotectSession());
  }
  canLoad(route: Route, segments: UrlSegment[]): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.check(segments[0]?.path, '');
  }
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    return this.check(route.url[0]?.path, state.url);
  }

  public unprotectSession() {
    if (this.protectSub) {
      this.protectSub.unsubscribe();
      this.protectSub = null;
    }
  }

  private isTestingInEmbed() {
    let TEST_EMBED_MODE = false;
    try {
      TEST_EMBED_MODE = (window as any)?.parent?.Cypress;
    } catch (error) {}

    return TEST_EMBED_MODE;
  }

  // if an authentication required route is resolved we keep track of the session
  // and if it is expired we show a message
  private protectSession(targetUrl: string) {
    if (this.protectSub) return;
    this.protectSub = combineLatest([this.session.current$, this.workspaceService.current$]).subscribe(([s, w]) => {
      if (!s && !this.isNativeWindow && !this.flagService.isFlagOn('noSessionPopup') && !this.isTestingInEmbed()) {
        // wait some time in case user initiated the signout
        if (isExtension()) {
          this.routerService.navigateByUrl('/signin?r=' + escape(location.pathname + location.search));
          return;
        }
        if (this.isEmbed) {
          window.parent.postMessage({ type: 'unleash:hard:reset' }, '*');
          return;
        }
        const ref = this.popupService.open(
          'center',
          SessionExpirePopupComponent,
          {},
          {
            backdropStyle: 'blur-1',
            fullScreenDialog: true,
            closeOnClickOut: false,
          }
        );

        ref.compInstance.signIn.pipe(takeUntil(ref.destroy$)).subscribe(async () => {
          ref.destroy();
          location.reload();
        });
        return;
      }
      if (this.isTrialExpired(w) && targetUrl !== '/pricing') {
        if (this.trialPopupRef) {
          return;
        }
        this.clearPopups();
        this.trialPopupRef = this.popupService.open(
          'center',
          TrialExpiredComponent,
          { workspace: w },
          {
            position: 'center',
            backdropStyle: 'blur-2',
            closeOnClickOut: false,
          }
        );
        this.trialPopupRef.compInstance.selectPlanClicked.subscribe(() => {
          this.pricingService.navigateToPricingPage();
        });
      } else if (this.isCanceledPlan(w) && targetUrl !== '/pricing') {
        if (this.planCanceledPopupRef) {
          return;
        }
        this.clearPopups();
        if (!this.flagService.isFlagOn('noPlanCanceled')) {
          this.planCanceledPopupRef = this.popupService.open(
            'center',
            PlanCanceledPopupComponent,
            { workspace: w },
            {
              position: 'center',
              backdropStyle: 'blur-2',
              closeOnClickOut: false,
            }
          );
          this.planCanceledPopupRef.compInstance.selectPlanClicked.subscribe(() => {
            this.pricingService.navigateToPricingPage();
          });
        }
      } else {
        this.clearPopups();
      }
    });
  }
  async check(fragment: string, targetUrl: string): Promise<boolean> {
    // Browser Extension is loading from index.html
    if (targetUrl.startsWith('/index.html') && !targetUrl.includes('offscreen')) {
      this.routerService.navigateByUrl('/', { replaceUrl: true });
      return false;
    }

    if (targetUrl == '/init-tenant') {
      return true;
    }

    const logger = this.logger.scope('Guards');
    if (this.first) performanceCheckpoint('first_guard_started');

    this.unprotectSession();

    if (!NgZone.isInAngularZone()) {
      return new Promise<boolean>((res, rej) => {
        this.ngzone.run(async () => {
          try {
            const c = await this.check(fragment, targetUrl);
            res(c);
          } catch (e) {
            rej(e);
          }
        });
      });
    }
    try {
      if (this.embedService) {
        if (!(await firstValueFrom(this.embedService.verified$)) || !(await firstValueFrom(this.embedService.authenticated$))) {
          this.routerService.navigateByUrl('/error');
          return false;
        }
      }

      let session = await firstValueFrom(this.session.current$);
      if (this.first) performanceCheckpoint('first_guard_got_session');

      logger.info('got session in guard: ' + session?.id);

      if (isMobile() && session) {
        logger.info('user is in mobile, redirecting to webflow');
        window.location.href = Constants.MOBILE_WEB_URL;
      }
      // special injection of session from outside
      const dx = targetUrl.indexOf('#');
      const hash = dx != -1 ? targetUrl.substring(dx) : '';

      if (hash.startsWith('#user=') && window.opener) {
        const params = hash.split('&');
        const userId = params[0].substring(6);
        const id = params[1].substring(3)?.replace('/', '');
        const sesPromise: ManualPromise<SessionInfo> = new ManualPromise();
        if (userId != session?.user?.id) {
          window.addEventListener('message', async (m) => {
            if (m.data.type == 'unleash:signin' && m.origin == location.origin && m.data.id == id) {
              await this.session.injectSession(m.data.session, true);
              sesPromise.resolve(await firstValueFrom(this.session.current$));
            }
          });

          window.opener.postMessage({ type: 'unleash:signin-required', id }, location.origin);
          session = await sesPromise;
        }
      }

      if (hash.startsWith('#non') && !targetUrl.startsWith('/go')) {
        const p = hash.split('&');
        const non = p[0].split('=')[1].split('#')[0];
        let session = <SessionInfo>JSON.parse(atob(p[1].split('=')[1].split('#')[0]));

        await this.session.verifyNonce(non);

        const inj = await this.session.injectSession(session);

        if (fragment === 'select-account') {
          if (inj != 'conflict') {
            this.routerService.navigateByUrl('/');
            return false;
          }

          this.protectSession(targetUrl);
          if (this.first) {
            performanceCheckpoint('first_guard_resolved');
            pushTag({ event: 'ng-page-route-resolved', track_time: performance.now() - (<any>self).__START_TIME });
          }

          this.first = false;
          return true;
        }

        if (inj == 'injected') session = await firstValueFrom(this.session.current$);

        if (inj == 'conflict') {
          let redirUrl = targetUrl.substr(0, dx);
          if (redirUrl.startsWith('/')) redirUrl = redirUrl.substr(1);
          if (redirUrl) redirUrl = '?r=' + escape(redirUrl);

          this.routerService.navigateByUrl('/select-account' + redirUrl + hash);
          return false;
        }
      }
      if (fragment === 'pricing' && session && (this.isTrialExpired(session.workspace) || this.isCanceledPlan(session.workspace))) {
        this.clearPopups();
        this.unprotectSession();
        return true;
      }
      if (fragment == 'select-account') {
        this.routerService.navigateByUrl('/');
        return false;
      }

      // if we have session and final page is /desktop (the page for launching desktop), report the session to the desktop app asap
      if (session && (fragment == 'desktop' || new URL(location.href).searchParams.get('r') == 'desktop')) {
        if (!new URL(location.href).searchParams.get('desktop_done')) {
          this.session.syncApp().then((redir) => {
            if (redir) location.href = redir + '&r=' + escape(location.href);
          });
        }
      }

      /*
      if (fragment === 'user-created') {
        
        // invitation page is not needed any more 

        //if (this.inviteService.invite) {
        //  return true;
        // }

        this.routerService.navigateByUrl(`/signin`);
        return false;
      }*/
      const params = targetUrl?.split('?')[1];
      const queryString = new URLSearchParams(params);
      if (fragment === 'join-workspace' || targetUrl?.startsWith('/join-workspace?')) {
        return true;
      }
      if (['empty', 'install-extension'].includes(fragment)) {
        return true;
      }
      if (fragment == 'signin' || fragment == 'signup') {
        if (this.isEmbed) {
          if (!(await this.embedService.isExternalWebSite())) {
            return true;
          }

          this.globalErrorHandler.error = new Error('User is not authenticated');
          this.routerService.navigateByUrl('/error');
          return false;
        }
        if (this.inviteService.invite) {
          this.routerService.navigateByUrl('/user-created');
          return false;
        }

        //for automation purposes we check for the flag(query) 'new'
        //to prevent from going straight to the application when there is already a session.
        if (session && queryString.has('new')) {
          return true;
        }

        //sign-out before sign in (for zendesk assistant process)
        if (session && queryString.has('signout')) {
          await this.session.signOut(true);
          return true;
        }

        if (session && !queryString.has('code')) {
          let retUrl = queryString.get('r') || '/';
          if (retUrl && !retUrl.startsWith('/')) retUrl = '/' + retUrl;

          this.routerService.navigateByUrl(retUrl);
          return false;
        }
        if (this.first) {
          performanceCheckpoint('first_guard_resolved');
          pushTag({ event: 'ng-page-route-resolved', track_time: performance.now() - (<any>self).__START_TIME });
        }
        this.first = false;
        return true;
      }

      if (!session) {
        const inviteUser = this.inviteService.invite;
        if (inviteUser && ['signin', 'signup', 'user-created'].includes(fragment)) {
          return true;
        }
        let redirUrl = targetUrl || '';
        if (redirUrl.startsWith('/')) redirUrl = redirUrl.substr(1);

        if (redirUrl) redirUrl = 'r=' + escape(redirUrl);

        this.routerService.navigateByUrl('/signin?' + redirUrl);
        return false;
      }
      const checkOnBoard = async () => {
        if (session?.workspace?.type === 'Team') {
          this.updateOnBoardCompleted();
          return true;
        }
        if (this.isEmbed) {
          return true;
        }
        if (this.first) performanceCheckpoint('first_guard_check_onboarding');
        const storage = this.sessionStorage.getStore('roaming', 'account');
        const state = await storage?.entry<OnboardingState>('onboarding').get();
        if (this.first) performanceCheckpoint('first_guard_check_onboarding');

        return state?.completed;
      };

      // if (fragment === 'pricing') {
      // logger.info('pricing page');
      // if (this.isPLanExist(session.workspace)) {
      //   const params = targetUrl?.split('?')[1];
      //   this.routerService.navigateByUrl(`/setup${params ? `?${params}` : ''}`);

      //   return false;
      // }
      //   return true;
      // }
      this.protectSession(targetUrl);
      if (fragment === 'pricing-completed') {
        const oldPlanId = JSON.parse(atob(unescape(queryString.get('s'))))?.planId;
        const promise = new ManualPromise<boolean>();
        const intervalFunc = async () => {
          if (promise.status) {
            return;
          }
          await this.workspaceService.refresh();
          const newWorkspace = await firstValueFrom(this.workspaceService.current$);
          if (oldPlanId != newWorkspace?.plan?.id) {
            if (!promise.status) {
              promise.resolve(true);
            }
            return;
          }
          setTimeout(intervalFunc, 1000);
        };
        setTimeout(intervalFunc, 0);
        setTimeout(async () => {
          if (!promise.status) {
            promise.resolve(false);
          }
        }, 60 * 1000);

        const res = await promise;
        if (res) {
          logger.info('workspace contains plan');
          const redirect = '/';
          this.routerService.navigateByUrl(redirect);
        } else {
          this.routerService.navigateByUrl('/pricing?subscription=canceled');
        }
        return false;
      }
      if (fragment === 'setup') {
        logger.info('checking onboarding state for setup');
        if (!this.isNativeWindow) {
          const session = await firstValueFrom(this.session.current$);
          // if (!this.isPlanExist(session.workspace)) {
          //   const params = targetUrl?.split('?')[1];
          //   this.routerService.navigateByUrl(`/pricing${params ? `?${params}` : ''}`);
          //   return false;
          // }
          // on native window we have different windows for sign in/setup & main app
          if (!session.user.isNew && (await checkOnBoard())) {
            logger.info('onboarded completed');

            const params = targetUrl
              ?.split('?')[1]
              ?.split('&')
              .map((x) => x?.split('='));
            const rurl = params?.find((p) => p[0] == 'r');

            this.routerService.navigateByUrl('/' + (rurl ? unescape(rurl[1]) : ''));
            return false;
          }
        }
        this.protectSession(targetUrl);
        if (this.first) {
          performanceCheckpoint('first_guard_resolved');
          pushTag({ event: 'ng-page-route-resolved', track_time: performance.now() - (<any>self).__START_TIME });
        }
        this.first = false;
        return true;
      }

      // if (!this.isNativeWindow) {
      //   logger.info('checking plan state');
      //   const session = await firstValueFrom(this.session.current$);
      //   if (!this.isPLanExist(session.workspace)) {
      //     const params = targetUrl?.split('?')[1];
      //     this.routerService.navigateByUrl(`/pricing${params ? `?${params}` : ''}`);
      //     return false;
      //   }
      // }

      logger.info('checking onboarding state');
      if (!session?.completedOnboarding && !(await checkOnBoard())) {
        logger.info('onboarding not completed.. redirecting');
        let redirUrl = targetUrl || '';
        if (redirUrl.startsWith('/')) redirUrl = redirUrl.substr(1);

        const params = targetUrl?.split('?')[1];
        if (redirUrl) redirUrl = 'r=' + escape(redirUrl) + (params ? `&${params}` : '');
        this.routerService.navigateByUrl('/setup?' + redirUrl);
        return false;
      }
      logger.info('route valid');

      if (this.first) {
        performanceCheckpoint('first_guard_resolved');
        pushTag({ event: 'ng-page-route-resolved', track_time: performance.now() - (<any>self).__START_TIME });
      }
      this.first = false;

      targetUrl = targetUrl?.split('?')[0];
      if ((!targetUrl?.length || targetUrl === '/') && this.isEmbed) {
        const opts = await firstValueFrom(this.embedService.options$);
        if (this.embedService.isSearchEmbedType(opts.type)) {
          this.routerService.navigateByUrl('/search');
          return false;
        }
      }
      return true;
    } catch (e) {
      this.globalErrorHandler.error = e;
      this.routerService.navigateByUrl('/error?' + '&r=');
      return false;
    }
  }

  private async updateOnBoardCompleted() {
    const storage = this.sessionStorage.getStore('roaming', 'account');

    let state = await storage.entry<OnboardingState>('onboarding').get();

    if (!state?.completed) {
      state = state || {
        name: { completed: true },
        completed: true,
        workspaceName: { completed: true },
        invite: { completed: true },
        connectApps: { current: undefined, apps: [], completed: true },
        kyc: { completed: true },
        selectApps: { completed: true },
      };
      storage.entry<OnboardingState>('onboarding').set({ ...state, completed: true });
    }
  }

  private isTrialExpired(workspace: Workspace.Workspace) {
    return !workspace?.plan && !workspace?.hasPurchased;
  }

  private isCanceledPlan(workspace: Workspace.Workspace) {
    return !workspace?.plan && workspace?.hasPurchased;
  }

  private clearPopups() {
    this.planCanceledPopupRef?.destroy();
    this.planCanceledPopupRef = null;
    this.trialPopupRef?.destroy();
    this.trialPopupRef = null;
  }
}
