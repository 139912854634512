<div class="u-textarea-interactive" [ngClass]="{ focused: isFocus() }">
  <div class="u-textarea-interactive-content">
    @if (leftSideTemplate) {
      <ng-container [ngTemplateOutlet]="leftSideTemplate"></ng-container>
    }
    <u-textarea
      [rows]="rows"
      [cols]="cols"
      [placeholder]="placeholder"
      [styles]="styles"
      [disabled]="disabled"
      [maxLength]="maxLength"
      [maxWords]="maxWords"
      [ngClass]="{ 'disable-auto-resize': disableAutoResize }"
      [model]="model"
      [ignoreEnter]="ignoreEnter"
      (onChange)="onChangeEvent($event)"
      (onFocus)="onFocusEvent($event)"
      (onBlur)="onBlurEvent($event)"
      (onEnter)="onEnterEvent($event)"
      (handleValidationResult)="handleMaxContentLengthEvent($event)"
      [readonly]="readonly"
      [autoResize]="autoResize"
      [autofocus]="autofocus"
      [maxRowLimit]="maxRowLimit"
      [minHeight]="minHeight"
      pInputTextarea
      class="inline-textarea"
      name="uTextareaInteractive"
      ngDefaultControl
      #textareaInteractive
    ></u-textarea>
    @if (rightSideTemplate) {
      <ng-container [ngTemplateOutlet]="rightSideTemplate"></ng-container>
    }
  </div>
  @if (this.footerTemplate) {
    <div class="u-textarea-interactive-footer">
      <ng-container [ngTemplateOutlet]="footerTemplate"></ng-container>
    </div>
  }
</div>
<div class="max-char-warning" *ngIf="displayLimitWarning && !isValidLength()">{{ MAX_INPUT_WORDS_REACHED_MSG }}</div>
