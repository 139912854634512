import { LogService, ServicesRpcService } from '@shared/services';
import { ShowToasterService } from './show-toaster.service';
import { Logger } from '@unleash-tech/js-logger';
import { Filters, Resources, SaveFilters } from '@local/client-contracts';
import { Observable, ReplaySubject } from 'rxjs';
import { SaveFiltersRpcInvoker } from './invokers/save-filters.rpc-invoker';
import { Injectable } from '@angular/core';
import { RouterService } from '@shared/services/router.service';
import { ToasterData } from '../components/toaster/toaster-data';
import { observable } from '@local/common';

@Injectable()
export class SaveFiltersService {
  private readonly ROUTES = { SEARCH: '/search' };
  private readonly CREATE_TITLE = 'Default filter was successfully created';
  private readonly UPDATE_TITLE = 'Default filter was updated';
  private readonly TOASTER_DATA: ToasterData = {
    id: 'save-filters-create',
    title: 'Default filter was successfully created ',
    icon: { type: 'font', value: 'icon-check-circle' },
    iconIntent: 'success',
    intent: 'primary',
    buttonText: 'Go to Search page',
  };
  private readonly TOASTER_ERROR_DATA: ToasterData = {
    id: 'save-filters-error',
    title: `Oops.. something went wrong. Please try again! `,
    icon: { type: 'font', value: 'icon-duo-exclamation-circle' },
    iconIntent: 'danger',
  };
  private logger: Logger;
  private service: SaveFilters.Service;
  private _all$ = new ReplaySubject<SaveFilters.SaveFilter[]>(1);

  constructor(
    services: ServicesRpcService,
    logger: LogService,
    private showToasterService: ShowToasterService,
    private routerService: RouterService
  ) {
    this.logger = logger.scope('SaveFilter.Service');
    this.service = services.invokeWith(SaveFiltersRpcInvoker, 'saveFilters');
    this.service.all$.subscribe((all) => this._all$.next(all));
  }

  @observable
  get all$(): Observable<SaveFilters.SaveFilter[]> {
    return this._all$.asObservable();
  }

  private showSuccessToaster(title: string): void {
    const toasterData: ToasterData = {
      ...this.TOASTER_DATA,
      title,
    };

    const toaster = this.showToasterService.showToaster(toasterData);
    toaster.compInstance.invoke.subscribe(() => {
      this.routerService.navigateByUrl(this.ROUTES.SEARCH);
      toaster.destroy();
    });
  }

  async create(saveFilters: SaveFilters.SaveFilter, showToaster: boolean): Promise<SaveFilters.SaveFilter> {
    try {
      const newSaveFiltersItem = await this.service.create(saveFilters);
      if (showToaster) {
        this.showSuccessToaster(this.CREATE_TITLE);
      }
      return newSaveFiltersItem;
    } catch (err) {
      this.showToasterService.showToaster(this.TOASTER_ERROR_DATA);
    }
  }

  async update(saveFilters: SaveFilters.SaveFilter, showToaster: boolean) {
    try {
      const action: SaveFilters.UpdateAction[] = [
        {
          type: 'Update',
          field: 'filters',
          value: saveFilters.filters ?? {},
        },
        {
          type: 'Update',
          field: 'clientFilters',
          value: saveFilters.clientFilters ?? {},
        },
        {
          type: 'Update',
          field: 'enable',
          value: saveFilters.enable,
        },
      ];

      await this.service.update(saveFilters.id, action);
      if (showToaster) {
        this.showSuccessToaster(this.UPDATE_TITLE);
      }
    } catch (err) {
      this.showToasterService.showToaster(this.TOASTER_ERROR_DATA);
    }
  }

  async buildClientFilter(filter: Resources.SearchAppliedFilters): Promise<Filters.Values> {
    return this.service.buildClientFilter(filter);
  }
}
