import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UTruncateText } from './u-truncate-text.component';
import { TooltipModule } from 'primeng/tooltip';
@NgModule({
  declarations: [UTruncateText],
  imports: [CommonModule, TooltipModule],
  exports: [UTruncateText],
})
export class UTruncateTextModule {}
