import { Injectable } from '@angular/core';
import { Filters, Omnibox, Resources, Search, Style } from '@local/client-contracts';
import { ASSISTANT_FILTERS_ID, DatePickerType, SettingsFilter, isEmbed, stringToFormattedDate } from '@local/common-web';
import { removeProperties, upperCaseWords } from '@local/ts-infra';
import {
  DisplaySearchFilterValue,
  Filter,
  FilterRequestType,
  FilterViewDetails,
  GroupDisplaySearchFilterValue,
  NestedFilter,
  NestedSearchFilterValue,
  SpecialSearchFilterValue,
  isGroupFilterValue,
} from '@shared/components/filters/models';
import { EmbedService } from '@shared/embed.service';
import { LogService, ServicesRpcService } from '@shared/services';
import { ApplicationsService } from '@shared/services/applications.service';
import { DatePickerService } from '@shared/services/date-picker.service';
import { RouterService } from '@shared/services/router.service';
import { Logger } from '@unleash-tech/js-logger';
import { chain, cloneDeep, isEmpty, isEqual, keyBy } from 'lodash';
import moment from 'moment';
import { BehaviorSubject, Observable, ReplaySubject, Subject, combineLatest, filter, firstValueFrom } from 'rxjs';
import * as uuid from 'uuid';
import { deepMergeFilters, isTimeFilter } from '../utils/filters-utils';
import { pcAppName } from '../views';
import { HubService } from './hub.service';
import { FiltersRpcInvoker } from './invokers/filters-rpc-invoker';
import { SuggestionProvider } from './suggestions/suggestion-provider';
import { TagsService } from './tags.service';

type FilterDefinitionMap = { [name: string]: Filters.FilterDefinition };
export type FilterCounter = {
  [name: string]: {
    value: string;
    count: number;
  }[];
};
export interface SuggestionsSettings {
  limit?: boolean;
  returnAllValues?: boolean;
  maxItems?: number;
  withGroupValues?: boolean;
  selectedOnTop?: boolean;
  filtersToSearchOnlySelected?: 'all' | string[];
  displayActiveFilters?: Filters.ActiveFilters;
  preventDisableItemsWithTerm?: boolean;
  withScore?: boolean;
  retrieveSelected?: boolean;
  searchAssistantId?: string;
}
export type MoreFiltersOptions = {
  addSelected?: boolean;
  withFreeText?: boolean;
  excludeSelectedFromHeader?: boolean;
  includeSelectedGroups?: boolean;
  title?: string;
  icon?: Style.EntityIcon<Style.EntityIconType>;
  inlineFilter?: GroupDisplaySearchFilterValue | SpecialSearchFilterValue;
  shortSelected?: boolean;
  disabled?: boolean;
  hideTitle?: boolean;
  onlyIcon?: boolean;
};
type ResultsFilterOptions = {
  sessionName: string;
  baseFilters: SettingsFilter[];
  typeSuggestedFilters?: Search.FilterCountDetails[];
  selectedFilters?: { [name: string]: string[] };
  moreFilters?: MoreFiltersOptions;
  excludeSelectedFromHeader?: boolean;
  includeSelected?: boolean;
  minimumCount?: number;
  spreadAsMultiSelect?: boolean;
  counters?: FilterCounter;
  supportSingleBaseTag?: boolean;
  recommendationsWhenNoFilters?: boolean;
  suggestionProvider?: SuggestionProvider;
  optionsSuggestionProvider?: SuggestionsSettings;
  moreFilterValuesViewDetails?: FilterViewDetails;
  allowSingleItem?: boolean;
  allowEmptyFilter?: boolean;
  addSelectedValuesToMoreFilters?: boolean;
  nestedFilters?: string[][];
  timeFilterSettings?: TimeFilterSettings;
  preventLiveSearch?: boolean;
  defaultViewDetails?: FilterViewDetails;
  nestedViewDetails?: FilterViewDetails;
  viewDetailsPerFilter?: { [filterName: string]: FilterViewDetails };
  assistantId?: string;
};
export interface TimeFilterSettings {
  pickerTypes?: DatePickerType[];
  maxDate?: Date;
  minDate?: Date;
  defaultCustomTimeFilter?: DatePickerType;
}

export interface CreateClientFilterRequest {
  optionsSuggestionProvider: SuggestionsSettings;
  suggestionProvider: SuggestionProvider;
  definitions: FilterDefinitionMap;
  filterDef: Filters.FilterDefinition;
  name: string;
  baseFilters: string[];
  title: string;
  selectedValues: { [name: string]: string[] };
  fetchValues: boolean;
  spreadAsMultiSelect: boolean;
  counters: { value: string; count: number }[];
  supportTag?: boolean;
  viewDetails: FilterViewDetails;
  timeFilterSettings?: TimeFilterSettings;
  preventLiveSearch?: boolean;
}

export interface FetchFilterData {
  optionsSuggestionProvider: SuggestionsSettings;
  suggestionProvider: SuggestionProvider;
  selectedFilters?: Filters.Values;
  term: string;
  definitions: FilterDefinitionMap;
  filter: Filters.FilterDefinition;
  baseFilters: string[];
  picker: Filters.PickerType;
  countMap?: {
    [name: string]: number;
  };
  filterName?: string;
  withGroupValues?: boolean;
  selectedOnTop?: boolean;
  supportTag?: boolean;
  sessionName: string;
}

type FilterCache = { filters: Filters.ActiveFilters; timestamp: number; relevantTypes?: Promise<string[]>; tags?: Promise<Omnibox.Tag[]> };
@Injectable()
export class FiltersService {
  routeFilters$: BehaviorSubject<Filters.Values> = new BehaviorSubject<Filters.Values>(undefined);
  definitions$ = new ReplaySubject<FilterDefinitionMap>(1);

  private tagsChangeId: string;
  private service: Filters.Service;
  private _allFilters$ = new BehaviorSubject<Filters.Values>({});
  private cache: FilterCache[] = [];
  private filterSessions: { [name: string]: { id: number; subject$: ReplaySubject<Filter[]> } } = {};
  private embedInline: boolean;
  private activePage: string;

  private readonly MAX_RECURSION_NESTED_FILTERS = 2;
  private readonly CACHE_TIMEOUT = 1000 * 60;
  private readonly MORE_FILTERS_KEY = 'more-filters';
  private readonly MORE_FILTERS_TOOLTIP =
    'Filters<div style="background: rgba(255, 255, 255, 0.1);border-radius: 3px;width: 14px;color: #AEADAD;display:inline-block;margin-left:8px">;</div>';
  private readonly TAG_SYMBOL = 't';
  private readonly logger: Logger;
  private readonly DEFAULT_MORE_FILTERS_ICON: Style.EntityIcon<Style.EntityIconType> = { type: 'font-icon', value: 'icon-sort-down' };
  private readonly DEFAULT_MORE_FILTERS_TITLE: string = 'Filters';
  readonly FREE_TEXT_KEY = 'free-text';
  readonly FREE_TEXT_NAME = 'Free Text';
  private readonly pcAppName = pcAppName();
  private readonly isEmbed: boolean = isEmbed();
  private readonly BASE_FILTERS: string[] = ['app', 'type'];
  private readonly MAX_APPS_COUNT: number = 12;
  private readonly DEFAULT_MIN_FILTER_VALUES_COUNT: number = 2;

  get allFilters(): Filters.Values {
    return this._allFilters$.value;
  }

  get allFilters$(): Observable<Filters.Values> {
    return this._allFilters$.asObservable();
  }

  get routeFilters(): Filters.Values {
    return this.routeFilters$.getValue();
  }

  get postFilters(): Filters.Values {
    return this.getFilters('of');
  }

  get tagFilters(): Filters.Values {
    return this.getFilters('if-t');
  }

  get inlineFilters(): Filters.Values {
    return this.getFilters('if');
  }

  get unTaggedInlineFilters(): Filters.Values {
    return this.getFilters('if', false);
  }

  get hasFilters(): boolean {
    return !!Object.keys(this.allFilters).length;
  }

  get baseFilters(): string[] {
    return this.BASE_FILTERS;
  }

  set routeFilters(filters: Filters.Values) {
    this.routeFilters$.next(filters);
  }

  constructor(
    private services: ServicesRpcService,
    private hubService: HubService,
    private routerService: RouterService,
    log: LogService,
    private datePickerService: DatePickerService,
    private tagsService: TagsService,
    private applicationService: ApplicationsService,
    private embedService: EmbedService
  ) {
    this.logger = log.scope('FiltersService');
    this.service = this.services.invokeWith(FiltersRpcInvoker, 'filters');
    this.initData();
  }

  private async initData() {
    const assistantId = await this.hubService.globalAssistantId;
    this.service.definitions$(assistantId).subscribe((d) => {
      const def: FilterDefinitionMap = keyBy(d, 'name');
      if (!isEmpty(def)) {
        this.definitions$.next(def);
      }
    });
    this.initRouteFilters();
    this.subscribeToState();
    this.setEmbedInline();
  }

  private initRouteFilters() {
    this.routerService.data$.subscribe((data: any) => {
      if (!this.hubService.activePage) {
        const newRouteFilters = data?.node?.data?.filters;
        this.routeFilters = newRouteFilters;
      }
    });

    this.routerService.activeRoute$.subscribe(async () => {
      if (!(await this.hubService.getIsLauncher())) return;
      const changeId = (this.tagsChangeId = uuid.v4());
      await firstValueFrom(this.applicationService.all$);
      const currentTags = this.tagsService.all;
      const tagFilters = await this.tags(this.tagFilters);
      if (this.tagsChangeId !== changeId) return;
      const newTags = [];
      let changed = false;
      for (const tag of currentTags) {
        if (tag.type !== 'filter') {
          newTags.push(tag);
          continue;
        }
        const tagFilter = tagFilters.find((t) => t.id === tag.id);
        if (tagFilter) {
          newTags.push(tagFilter);
          continue;
        }
        changed = true;
      }
      for (const tag of tagFilters) {
        const currentTag = currentTags.find((t) => t.id === tag.id);
        if (!currentTag) {
          newTags.push(tag);
          changed = true;
        }
      }

      if (changed) {
        this.tagsService.all = newTags;
      }
    });

    this.hubService.activePage$.subscribe((ap) => {
      if (this.activePage && !ap) {
        this.removeAllFilters('all', false);
      }
      this.activePage = ap;
    });
  }

  private async setEmbedInline() {
    if (this.isEmbed) {
      this.embedInline = await this.embedService?.isInline();
    }
  }

  private get innerPreFilters(): Filters.ActiveFilters {
    const preFilters: Filters.ActiveFilters = {};
    const filters = [
      { values: this.routeFilters, exclusive: true },
      { values: this.inlineFilters, exclusive: false },
    ];
    for (const filter of filters) {
      for (const [key, values] of Object.entries(filter.values || {})) {
        preFilters[key] = preFilters[key] || [];
        const oldValues = new Set(preFilters[key].map((f) => f.value) || []);
        for (const value of values) {
          if (!oldValues.has(value)) {
            preFilters[key].push({ value, exclusive: filter.exclusive });
          } else {
            const existing = preFilters[key].find((v) => v.value === value);
            existing.exclusive = filter.exclusive;
          }
        }
      }
    }
    return preFilters;
  }

  getPreFilters(nonExclusiveOnly?: boolean): Filters.Values {
    const filters: Filters.Values = {};
    for (const [key, values] of Object.entries(this.innerPreFilters)) {
      let nonExclusive: Filters.ActiveFilterValue[];
      if (nonExclusiveOnly) {
        nonExclusive = values.filter((a) => !a.exclusive);
      }
      filters[key] = (nonExclusive?.length ? nonExclusive : values).map((f) => f.value);
    }
    return filters;
  }

  getSuggestionProvider(allowGroups?: string[], assistantId?: string): SuggestionProvider {
    const allowedSet = new Set(allowGroups);
    return async (term: string, group: string, ctx: Omnibox.SuggestionContext, options?: SuggestionsSettings) => {
      let groups = group ? [group] : [];
      if (allowGroups?.length === 0) return [];
      if (allowGroups?.length) {
        groups = groups.length ? groups.filter((g) => allowedSet.has(g)) : allowGroups;
      }
      const suggestions = await this.getSuggestions(term, groups, ctx, options, null, null, assistantId);

      return suggestions;
    };
  }

  isCustomDateFilterValue(value: string) {
    const datesIndex = value.includes(':') ? 1 : 0;
    const dates = value.split(':')[datesIndex].split('-');
    return dates.every((date) => !date || date === 'undefined' || moment(date, 'MM/DD/YYYY', true).isValid());
  }

  async getSuggestions(
    term: string,
    group: string | string[],
    ctx: Omnibox.SuggestionContext,
    options: SuggestionsSettings,
    activeFilters?: Filters.ActiveFilters,
    sessionName?: string,
    assistantId?: string
  ): Promise<Omnibox.Suggestion[]> {
    const innerPreFilters = activeFilters ?? this.innerPreFilters;
    let groups = [];
    if (group) {
      groups = Array.isArray(group) ? group : [group];
    }
    const searchOptions: Omnibox.SuggestionSearchOptions = {
      activeFilters: innerPreFilters,
      groups,
      sessionName: sessionName || uuid.v4(),
      suggestionContext: ctx,
      term,
      displayActiveFilters: options?.displayActiveFilters,
      maxItems: options?.maxItems,
      returnAllValues: options?.returnAllValues,
      selectedOnTop: options?.selectedOnTop,
      withGroupValues: options?.withGroupValues,
      onlyGroups: !groups.length,
      retrieveSelected: options?.retrieveSelected,
    };
    const currentAssistantId = (await this.hubService.globalAssistantId) || assistantId;
    let suggestions = await this.service.getSuggestions(searchOptions, currentAssistantId);
    if (this.embedInline && groups?.includes('app')) {
      suggestions = suggestions.filter((s) => {
        const filterName = s.data?.name;
        if (s.title !== this.pcAppName || filterName !== 'app') {
          return true;
        }
      });
    }
    return suggestions;
  }

  async getSuggestionsAssistant(
    sessionName: string,
    term: string,
    group: string,
    ctx: Omnibox.SuggestionContext,
    options: SuggestionsSettings,
    appState?: string,
    activeFilters?: Filters.ActiveFilters,
    isAssistantCreator?: boolean,
    linksSharedWithCreator?: Set<string>
  ): Promise<Omnibox.Suggestion[]> {
    const { displayActiveFilters, maxItems, returnAllValues, selectedOnTop, withGroupValues, searchAssistantId } = options;
    const innerPreFilters = activeFilters ?? this.innerPreFilters;
    const groups = group ? [group] : [];
    const searchSettings: Omnibox.SuggestionSearchOptions = {
      sessionName,
      activeFilters: innerPreFilters,
      displayActiveFilters,
      groups,
      maxItems,
      returnAllValues,
      selectedOnTop,
      suggestionContext: ctx,
      term,
      withGroupValues,
      onlyGroups: !groups.length,
      withScore: true,
      onlySelected: options?.filtersToSearchOnlySelected === 'all' || options?.filtersToSearchOnlySelected?.includes(group),
      searchAssistantId,
    };
    const settings: Omnibox.AssistantSuggestionSearchOptions = {
      searchSettings,
      isAssistantCreator,
      linksSharedWithCreator,
      appState,
    };
    return await this.service.getSuggestionsAssistant(settings, ASSISTANT_FILTERS_ID);
  }

  private toActiveFilters(filter: Filters.Values): Filters.ActiveFilters {
    const activeFilters = {};
    for (const [key, values] of Object.entries(filter)) {
      activeFilters[key] = [];
      for (const value of values) {
        activeFilters[key].push({ value, exclusive: false });
      }
    }
    return activeFilters;
  }

  async tags(inlineFilters: Filters.Values, confirmValueExists?: boolean, assistantId?: string): Promise<Omnibox.Tag[]> {
    const filters = this.toActiveFilters(inlineFilters);
    const currentAssistantId = (await this.hubService.globalAssistantId) || assistantId;
    return this.getOrFetch(filters, () => this.service.getTags(inlineFilters, confirmValueExists, currentAssistantId), 'tags');
  }

  private subscribeToState() {
    combineLatest([this.hubService.state$, this.routerService.data$]).subscribe(() => {
      const mergedFilters = deepMergeFilters(this.getPreFilters(), this.postFilters);
      this._allFilters$.next(mergedFilters);
    });
  }

  private getFilters(pref: string, withTagged = true): Filters.Values {
    const results = {};
    for (const e of Object.entries(this.hubService.state).filter((e) => e[0].startsWith(pref + '-'))) {
      let name = e[0].substring(pref.length + 1);
      const value = (<string[]>e[1])?.length > 0 ? e[1] : null;
      if (!value) {
        continue;
      }

      const taggedFilter = name.startsWith('t-');
      if (taggedFilter && !withTagged) {
        continue;
      }
      name = taggedFilter ? name.replace('t-', '') : name;
      results[name] = [...(results[name] || []), ...value];
    }
    return results;
  }

  setFilters(name: string, values: string[], filterType: FilterRequestType, addTag = false) {
    this.hubService.inputQuery = this.hubService.query;
    this.hubService.setState(this.getFilterName(name, filterType, addTag ? this.TAG_SYMBOL : undefined), values);
  }

  async addFilter(name: string, value: string | string[], addTag = false, filterType: FilterRequestType = 'pre') {
    this.hubService.addState(
      this.getFilterName(name, filterType, addTag ? this.TAG_SYMBOL : undefined),
      Array.isArray(value) ? value : [value]
    );
  }

  async removeFilter(
    name: string,
    value: string,
    isTag = false,
    filterType: FilterRequestType = 'pre',
    symbolPrefix: string = this.TAG_SYMBOL
  ) {
    this.hubService.removeState(this.getFilterName(name, filterType, isTag ? symbolPrefix : undefined), value);
  }

  private getFilterName(name: string, filterType: FilterRequestType = 'pre', symbol: string) {
    let prefix = filterType === 'post' ? 'of' : 'if';
    prefix = symbol ? `${prefix}-${symbol}` : prefix;
    const infName = `${prefix}-${name === 'appId' ? 'app' : name}`;
    return infName;
  }

  async getRelevantStandardTypes(filters?: Filters.ActiveFilters, assistantId?: string): Promise<string[]> {
    const req = filters || this.innerPreFilters;
    const currentAssistantId = (await this.hubService.globalAssistantId) || assistantId;
    return this.getOrFetch(req, () => this.service.getRelevantStandardTypes(req, currentAssistantId), 'relevantTypes');
  }

  private async getOrFetch<T>(filters: Filters.ActiveFilters, getter: () => Promise<T>, field: 'relevantTypes' | 'tags') {
    const now = Date.now();
    const req = filters;
    const cacheRes = this.cache.find((t) => isEqual(t.filters, req));
    if (cacheRes?.[field] && cacheRes.timestamp + this.CACHE_TIMEOUT > now) {
      return (<any>cacheRes[field]) as Promise<T>;
    }
    const res = getter();
    if (cacheRes) {
      cacheRes.timestamp = now;
      (<any>cacheRes[field]) = res;
    } else {
      this.cache.push({ filters: req, timestamp: now, [field]: res });
    }
    return res;
  }

  removeAllFilters(type: 'pre' | 'post' | 'all' = 'all', removeTagsFilters = true, skip: string[] = []) {
    const types: ('pre' | 'post')[] = type === 'all' ? ['pre', 'post'] : [type];
    const keys = [];
    const filters = !skip.length ? this.inlineFilters : removeProperties(cloneDeep(this.inlineFilters), skip);
    for (const name of Object.keys(filters)) {
      for (const type of types) {
        if (removeTagsFilters) {
          keys.push(this.getFilterName(name, type, this.TAG_SYMBOL));
        }
        keys.push(this.getFilterName(name, type, null));
      }
    }
    this.hubService.removeMultiState(keys);
  }

  async getRelevantTypes(filters?: Filters.ActiveFilters, assistantId?: string, allTypes = false): Promise<string[]> {
    const currentAssistantId = (await this.hubService.globalAssistantId) || assistantId;
    return this.service.getRelevantTypes(filters || this.innerPreFilters, allTypes, currentAssistantId);
  }

  async toRequestFilters(filters: Filters.Values): Promise<Resources.SearchAppliedFilters> {
    return this.service.toRequestFilters(filters);
  }

  private async getTypesRecommendedFilters(types: Search.FilterCountDetails[], assistantId?: string) {
    return this.service.getTypesRecommendedFilters(types, assistantId);
  }

  getFloatingResultsFilters(request: ResultsFilterOptions): Observable<Filter[]> {
    const { sessionName, assistantId } = request;
    let id = 1;
    const session = this.filterSessions[sessionName];
    if (session) {
      id = session.id + 1;
      session.subject$.complete();
    }
    const subject$ = new ReplaySubject<Filter[]>(1);
    this.filterSessions[sessionName] = {
      id,
      subject$,
    };
    if (!request.suggestionProvider) {
      request.suggestionProvider = (term, group, ctx, options, activeFilters, sessionName) =>
        this.getSuggestions(term, group, ctx, options, activeFilters, sessionName, assistantId);
    }
    this.innerFloatingResultsFilters(subject$, request);
    return subject$.asObservable();
  }

  private async innerFloatingResultsFilters(subject$: Subject<Filter[]>, request: ResultsFilterOptions) {
    const {
      sessionName,
      baseFilters: requBaseFilters,
      typeSuggestedFilters,
      includeSelected,
      minimumCount,
      moreFilters,
      spreadAsMultiSelect,
      selectedFilters,
      counters,
      supportSingleBaseTag,
      recommendationsWhenNoFilters,
      suggestionProvider,
      optionsSuggestionProvider,
      moreFilterValuesViewDetails,
      addSelectedValuesToMoreFilters,
      allowSingleItem,
      allowEmptyFilter,
      nestedFilters,
      timeFilterSettings,
      preventLiveSearch,
      defaultViewDetails,
      viewDetailsPerFilter,
      assistantId,
    } = request;
    const { withFreeText } = moreFilters || {};
    const currentId = this.filterSessions[sessionName].id;
    const isAlive = () => this.filterSessions[sessionName]?.id === currentId;
    const filters: Filter[] = [];
    const definitions = await firstValueFrom(this.definitions$.pipe(filter((f) => !!f)));
    const baseFilters = requBaseFilters.filter((f) => !f.optional);
    const baseFilterNames = baseFilters.map((f) => f.name);
    if (nestedFilters) {
      const nestedFilterValues = await this.getNestedFilters(nestedFilters, request, definitions);
      filters.push(...(nestedFilterValues || []));
      if (!isAlive()) {
        return;
      }
    }
    const currentAssistantId = (await this.hubService.globalAssistantId) || assistantId;
    const filterNames = await this.getResultsFiltersNames(
      baseFilterNames,
      typeSuggestedFilters,
      definitions,
      includeSelected,
      minimumCount,
      withFreeText,
      currentAssistantId
    );
    if (!isAlive()) {
      return;
    }
    for (const [group, names] of Object.entries(filterNames)) {
      if (group === 'suggestions' && recommendationsWhenNoFilters && filters.length) {
        continue;
      }
      for (const [index, filterName] of names.entries()) {
        let supportTag = false;
        if (supportSingleBaseTag && this.BASE_FILTERS.includes(filterName)) {
          if (this.tagFilters[filterName]) continue;
          supportTag = true;
        }
        const showIcon = this.showFilterIcon(filterName);
        const filterDef = definitions[filterName];
        if (filterName !== this.FREE_TEXT_KEY && !filterDef) {
          continue;
        }
        const picker = filterDef?.floating?.picker;
        const showSeparate = group === 'suggestions' && index === 0 && !!filters.length;
        const fetchValues = baseFilterNames.includes(filterName);
        const filterViewDetails = viewDetailsPerFilter?.[filterName];
        const filter = await this.getFilter({
          optionsSuggestionProvider,
          suggestionProvider,
          definitions,
          filterDef,
          name: filterName,
          baseFilters: baseFilterNames,
          title: baseFilters.find((f) => f.name === filterName)?.title,
          selectedValues: selectedFilters,
          fetchValues,
          spreadAsMultiSelect,
          counters: counters?.[filterName],
          supportTag,
          timeFilterSettings,
          preventLiveSearch,
          viewDetails: {
            ...(defaultViewDetails || {}),
            showItemIcon: showIcon,
            noCheckbox: picker === 'time-dropdown',
            showBackIcon: false,
            showSeparate,
            shortSelected: false,
            onlyIcon: false,
            hideOnSelect: picker === 'time-dropdown',
            limitItemsCount: picker === 'spread' && filterName === 'app' ? this.MAX_APPS_COUNT : null,
            showItemIconLabel: moreFilterValuesViewDetails?.showItemIconLabel,
            enableEmptyFilter: allowEmptyFilter,
            ...(filterViewDetails || {}),
          },
        });
        const minValues: number = allowEmptyFilter ? 0 : allowSingleItem ? 1 : this.DEFAULT_MIN_FILTER_VALUES_COUNT;
        if (fetchValues && filter.picker === 'multi-select' && filter.values.length < minValues) {
          continue;
        }
        const enableValues = filter.values.filter((v) => !v.disabled);
        if (!allowEmptyFilter && fetchValues && enableValues.length <= 1 && 'spread' === filter.picker) {
          continue;
        }
        if (!isAlive()) {
          return;
        }
        filters.push(filter);
      }
    }
    if (!filters.length && requBaseFilters.some((f) => f.optional)) {
      const optionalFilters = requBaseFilters.filter((f) => f.optional).map((f) => ({ ...f, optional: false }) as SettingsFilter);
      return this.innerFloatingResultsFilters(subject$, { ...request, baseFilters: optionalFilters });
    }
    if (!isAlive()) {
      return;
    }
    if (moreFilters?.inlineFilter?.childFilter) {
      filters.push(moreFilters.inlineFilter?.childFilter);
    }
    let moreFiltersFilter: Filter;
    if (moreFilters && !moreFilters.disabled) {
      const names = moreFilters?.includeSelectedGroups ? [] : Object.values(filterNames || {}).flat();
      moreFiltersFilter = await this.getGroupFilters(
        moreFilters,
        moreFilterValuesViewDetails,
        optionsSuggestionProvider,
        suggestionProvider,
        definitions,
        names,
        moreFilters.addSelected ? selectedFilters : undefined,
        baseFilters,
        withFreeText,
        supportSingleBaseTag,
        counters,
        addSelectedValuesToMoreFilters,
        timeFilterSettings
      );
      filters.push(moreFiltersFilter);
    }
    if (!isAlive()) {
      return;
    }
    subject$.next(cloneDeep(filters));
    subject$.complete();
  }

  private showFilterIcon(filterName: string) {
    return filterName === 'app';
  }

  private async getNestedFilters(nestedFiltersNames: string[][], request: ResultsFilterOptions, definitions: FilterDefinitionMap) {
    const filters = [];
    for (const filterNames of nestedFiltersNames) {
      if (isEmpty(filterNames)) {
        continue;
      }
      const filterDef = definitions[filterNames[0]];
      const filter = await this.getNestedFilter(filterDef?.title || filterDef?.name, filterNames, request, definitions, {}, true);
      if (!filter) {
        continue;
      }
      filters.push(filter);
    }
    return filters;
  }

  private async getNestedFilter(
    fixedTitle: string,
    filterNames: string[],
    request: ResultsFilterOptions,
    definitions: FilterDefinitionMap,
    selectedValues: { [name: string]: string[] },
    isFirstFilter?: boolean,
    currentRecursionDepth = 0
  ): Promise<NestedFilter> {
    if (currentRecursionDepth >= this.MAX_RECURSION_NESTED_FILTERS) {
      return;
    }
    const {
      optionsSuggestionProvider,
      suggestionProvider,
      spreadAsMultiSelect,
      allowEmptyFilter,
      defaultViewDetails,
      viewDetailsPerFilter,
      selectedFilters,
      nestedViewDetails,
    } = request;
    const currentFilters = cloneDeep(filterNames);
    const filterName = currentFilters?.shift();
    if (!filterName) {
      return;
    }
    const filterDef = definitions[filterName];
    const showIcon = this.showFilterIcon(filterName);
    const filterViewDetails = viewDetailsPerFilter?.[filterName];
    const createFilterModel: CreateClientFilterRequest = {
      optionsSuggestionProvider,
      suggestionProvider,
      definitions,
      filterDef,
      name: filterName,
      baseFilters: currentFilters,
      title: fixedTitle,
      selectedValues: isFirstFilter ? selectedFilters : selectedValues,
      fetchValues: true,
      spreadAsMultiSelect,
      counters: [],
      viewDetails: {
        ...(defaultViewDetails || {}),
        ...(nestedViewDetails || {}),
        showItemIcon: showIcon,
        showBackIcon: !isFirstFilter,
        enableEmptyFilter: allowEmptyFilter,
        enableBack: true,
        ...(filterViewDetails || {}),
      },
      preventLiveSearch: true,
    };
    const filter = await this.getFilter(createFilterModel);
    filter.picker = 'nested';
    const nextFilter = currentFilters[0];
    if (!nextFilter) {
      return filter as NestedFilter;
    }
    for (const filterValue of filter.values || []) {
      filterValue.type = 'nested';
      const currentFilter = { [filterName]: [filterValue.value] };
      const mergeSelected = { ...(selectedValues || {}), ...currentFilter };
      if (request?.selectedFilters?.[nextFilter]) {
        mergeSelected[nextFilter] = request.selectedFilters[nextFilter];
      }
      const childFilter = await this.getNestedFilter(
        fixedTitle,
        currentFilters,
        request,
        definitions,
        mergeSelected,
        false,
        currentRecursionDepth + 1
      );
      if (!childFilter) {
        continue;
      }
      (filterValue as NestedSearchFilterValue).childFilter = childFilter as NestedFilter;
    }
    return filter as NestedFilter;
  }

  private async getResultsFiltersNames(
    baseNames: string[],
    typeSuggestedFilters: Search.FilterCountDetails[],
    definitions: FilterDefinitionMap,
    includeSelected: boolean,
    minimumCount: number,
    withFreeText: boolean,
    assistantId?: string
  ): Promise<Record<'baseAndSelected' | 'suggestions', string[]>> {
    const selectedNonBaseFilters = includeSelected ? Object.keys(this.allFilters).filter((k) => !baseNames.includes(k)) : [];
    const orderedFilters = typeSuggestedFilters?.length ? await this.getTypesRecommendedFilters(typeSuggestedFilters, assistantId) : [];
    const baseAndSelected = [...baseNames, ...selectedNonBaseFilters];
    if (withFreeText && this.hubService.query) {
      baseAndSelected.push(this.FREE_TEXT_KEY);
    }
    let countFilters = baseAndSelected.length;
    const suggestionNames: string[] = [];
    while (orderedFilters.length && minimumCount > countFilters) {
      const next = orderedFilters.shift();
      const def = definitions[next];
      if (!selectedNonBaseFilters.includes(next) && def?.field !== 'query-filter' && !def?.floating?.disabled) {
        suggestionNames.push(next);
        countFilters++;
      }
    }
    return {
      baseAndSelected,
      suggestions: suggestionNames,
    };
  }

  private async getGroupFilters(
    moreFiltersOptions: MoreFiltersOptions,
    moreFilterValuesViewDetails: FilterViewDetails,
    optionsSuggestionProvider: SuggestionsSettings,
    suggestionProvider: SuggestionProvider,
    definitions: FilterDefinitionMap,
    exclude: string[],
    selectedValues: Filters.Values,
    baseFilters: SettingsFilter[],
    withFreeText: boolean,
    supportSingleBaseTag: boolean,
    counters: FilterCounter,
    addSelectedValuesToMoreFilters: boolean,
    timeFilterSettings?: TimeFilterSettings
  ) {
    const {
      title,
      icon,
      includeSelectedGroups: selectedOnTop,
      shortSelected,
      inlineFilter,
      excludeSelectedFromHeader,
      hideTitle,
      onlyIcon,
    } = moreFiltersOptions;
    const moreInlineFilter = inlineFilter?.childFilter ? null : inlineFilter;
    const excludeSet = new Set(exclude);
    const filterNames = Object.entries(definitions)
      .filter(([k, v]) => v.field !== 'query-filter' && !excludeSet.has(k))
      .map(([k]) => k);
    const namesSet = new Set(filterNames);
    const selectedNames = new Set(Object.keys(selectedValues || {}).filter((s) => namesSet.has(s)));
    const freeTextFilterName = this.FREE_TEXT_KEY;
    const baseFilterNames = baseFilters.map((f) => f.name);
    const freeTextFilter = {
      id: `filter:${freeTextFilterName}`,
      type: 'group',
      title: this.FREE_TEXT_NAME,
      value: '',
      subtitle: '',
      icon: { type: 'font-icon', value: 'icon-edit-line' },
      childFilter: this.getFreeTextFilter(),
      order: this.getFilterValueOrder(freeTextFilterName, definitions, selectedNames.has(freeTextFilterName), baseFilterNames),
      filterName: freeTextFilterName,
    } as GroupDisplaySearchFilterValue;
    const selectedFilters = supportSingleBaseTag ? this.unTaggedInlineFilters : this.inlineFilters;
    const selectedCount = Object.values(selectedFilters || {}).flat().length;
    const sessionName = uuid.v4();
    const fixedTitle = upperCaseWords(title || this.DEFAULT_MORE_FILTERS_TITLE);
    return {
      name: this.MORE_FILTERS_KEY,
      title: fixedTitle,
      icon: icon || this.DEFAULT_MORE_FILTERS_ICON,
      values: [],
      valuesFetcher: async (term) => {
        const displayActiveFilters = supportSingleBaseTag ? this.unTaggedInlineFilters : this.inlineFilters;
        const fetchFilterData: FetchFilterData = {
          optionsSuggestionProvider: {
            ...optionsSuggestionProvider,
            displayActiveFilters: this.toActiveFilters(displayActiveFilters || {}),
          },
          suggestionProvider,
          selectedFilters: addSelectedValuesToMoreFilters ? selectedValues : this.inlineFilters,
          term,
          definitions,
          filter: null,
          baseFilters: baseFilterNames,
          picker: 'multi-select',
          filterName: null,
          withGroupValues: true,
          selectedOnTop,
          supportTag: supportSingleBaseTag,
          sessionName,
        };
        const newValues = await this.fetchFilterValues(fetchFilterData);
        const updatedValues = [];
        for (const value of newValues) {
          const filterName = value.filterName;
          if (!namesSet.has(filterName)) {
            continue;
          }
          if (isGroupFilterValue(value) && value.childFilter?.picker !== 'free-text') {
            const filterDef = definitions[filterName];
            if (!filterDef) {
              continue;
            }
            const showIcon = filterDef.floating?.picker !== 'time-dropdown';
            const order = this.getFilterValueOrder(filterName, definitions, selectedNames.has(filterName), baseFilterNames);
            const filterTitle = (filterDef.title || filterDef.name).toLowerCase();
            const filter = await this.getFilter({
              optionsSuggestionProvider,
              suggestionProvider,
              definitions,
              filterDef: {
                ...filterDef,
                title: title || this.DEFAULT_MORE_FILTERS_TITLE,
                icon: icon || this.DEFAULT_MORE_FILTERS_ICON,
              },
              name: filterName,
              baseFilters: baseFilterNames,
              title: baseFilters.find((f) => f.name === filterName)?.title,
              selectedValues,
              fetchValues: false,
              spreadAsMultiSelect: true,
              counters: counters?.[filterName],
              supportTag: supportSingleBaseTag && this.BASE_FILTERS.includes(filterName),
              timeFilterSettings,
              viewDetails: {
                placeholder: `Filter by ${filterTitle}`,
                showItemIcon: showIcon,
                excludeSelectedFromHeader: true,
                noCheckbox: true,
                showRemoveIcon: true,
                showBackIcon: true,
                showSeparate: false,
                shortSelected,
                onlyIcon,
                customSelectedCount: selectedOnTop ? selectedCount : null,
                hideOnSelect: true,
                position: 'Side',
                tooltipSelected: this.MORE_FILTERS_TOOLTIP,
                wideWidth: true,
                separateSelected: true,
                filterTitle,
                hideTitle,
                ...(moreFilterValuesViewDetails || {}),
              },
            });
            value.order = order;
            value.childFilter = filter;
          }
          updatedValues.push(value);
        }
        if (withFreeText && this.FREE_TEXT_NAME.toLowerCase().includes(term)) {
          updatedValues.unshift(freeTextFilter);
        }
        if (moreInlineFilter && (!term || moreInlineFilter.title.toLowerCase().startsWith(term.toLowerCase()))) {
          updatedValues.push(moreInlineFilter);
        }
        return updatedValues;
      },
      picker: 'multi-select',
      type: 'pre',
      viewDetails: {
        isFullDetailLine: true,
        placeholder: 'Search for...',
        showItemIcon: true,
        showItemLabel: true,
        noCheckbox: true,
        openOnSemicolon: true,
        updateOnRemove: true,
        showClearAll: true,
        hideOnSelect: true,
        excludeSelectedFromHeader,
        limitShowSelectedLabels: null,
        // sortBy: [{ name: 'order', order: 'asc' }],
        showRemoveIcon: true,
        shortSelected,
        onlyIcon,
        tooltipSelected: this.MORE_FILTERS_TOOLTIP,
        position: 'Side',
        wideWidth: true,
        enableBack: true,
        customSelectedCount: selectedOnTop ? selectedCount : null,
        separateSelected: true,
        hideTitle,
        ...(moreFilterValuesViewDetails || {}),
      },
    } as Filter;
  }

  private getFilterValueOrder(filterName: string, definitions: FilterDefinitionMap, isFilterSelected = false, baseFilters: string[] = []) {
    if (baseFilters.includes(filterName)) {
      return 0;
    }
    if (isFilterSelected) {
      return 1;
    }
    if (filterName === this.FREE_TEXT_KEY) {
      return 2;
    }
    return definitions[filterName].inline.selectionTag.order + 2;
  }

  private getFreeTextFilter(value = ''): Filter {
    return {
      name: this.FREE_TEXT_NAME,
      picker: 'free-text',
      type: 'pre',
      title: this.FREE_TEXT_NAME,
      values: [
        {
          id: `filter:${this.FREE_TEXT_KEY}:value`,
          value,
          title: '',
          subtitle: '',
          filterName: this.FREE_TEXT_KEY,
          order: this.getFilterValueOrder(this.FREE_TEXT_KEY, undefined),
        },
      ],
      icon: { type: 'font-icon', value: 'icon-edit-line' },
      viewDetails: {
        placeholder: 'Type your text...',
        showHasValues: true,
      },
    };
  }

  private getSelectedFiltersSet(filterName: string, selectedFilters: Filters.Values) {
    const list = (selectedFilters ? selectedFilters[filterName] : this.allFilters[filterName]) || [];
    return new Set<string>(list);
  }

  private async fetchFilterValues(data: FetchFilterData): Promise<DisplaySearchFilterValue[]> {
    const {
      baseFilters,
      definitions,
      optionsSuggestionProvider,
      picker,
      selectedFilters,
      suggestionProvider,
      term,
      countMap,
      filterName,
      selectedOnTop,
      withGroupValues,
      supportTag,
      sessionName,
    } = data;
    const isToggle = picker === 'toggle';
    const options: SuggestionsSettings = {
      limit: false,
      withGroupValues,
      selectedOnTop,
      preventDisableItemsWithTerm: true,
      ...optionsSuggestionProvider,
    };
    const suggestionsActiveFilters = selectedFilters ? this.toActiveFilters(selectedFilters || {}) : null;
    const suggestions: Omnibox.Suggestion[] = await suggestionProvider(
      term || '',
      filterName,
      'box',
      options,
      suggestionsActiveFilters,
      sessionName
    );
    const values: DisplaySearchFilterValue[] = [];
    const selectedValues: Set<string> = filterName ? this.getSelectedFiltersSet(filterName, selectedFilters) : null;
    const selectable = chain(suggestions)
      .keyBy((a) => a.id)
      .value();
    const countMapHasUnSelectedFilters = Object.keys(countMap || {}).find((k) => !selectedFilters?.[k]);
    for (const s of suggestions) {
      const value = this.suggestionToDisplayFilter(
        s,
        definitions,
        selectedFilters,
        selectedValues,
        countMapHasUnSelectedFilters ? countMap : null,
        baseFilters,
        isToggle,
        !!selectable[s.id],
        supportTag && this.BASE_FILTERS.includes(s.group)
      );
      if (value) {
        values.push(value);
      }
    }
    if (countMap) {
      values.sort((a, b) => {
        return (b.count || -1) - (a.count || -1);
      });
    }
    return values;
  }

  private suggestionToDisplayFilter(
    suggestion: Omnibox.Suggestion,
    definitions: FilterDefinitionMap,
    selectedFilters: Filters.Values,
    selectedValues: Set<string>,
    countMap: {
      [name: string]: number;
    },
    baseFilters: string[],
    isToggle: boolean,
    isSelectable: boolean,
    supportTag?: boolean
  ): DisplaySearchFilterValue {
    const isGroupSuggestion = suggestion.type === 'group';
    const filterName: string = suggestion.data?.name;
    if (isGroupSuggestion) {
      const def = definitions[filterName];
      const groupSelected = selectedFilters?.[filterName] || this.allFilters[filterName] || [];
      if (!def || (def.floating.picker === 'toggle' && groupSelected?.length)) {
        return;
      }
    }
    const filterValue = suggestion.data?.value || suggestion.title;
    const set = selectedValues || this.getSelectedFiltersSet(filterName, selectedFilters);
    const order = this.getFilterValueOrder(suggestion.data?.name, definitions, !!set.size, baseFilters);
    const selected = (isToggle && set.size > 0) || set.has(filterValue);
    let count: number;
    if (countMap) {
      count = countMap[filterValue] || 0;
    }
    const value = {
      ...suggestion,
      value: filterValue,
      selected,
      disabled: suggestion.disabled !== undefined ? suggestion.disabled : (!isSelectable && !selected) || (countMap && !count),
      filterName,
      count,
      order,
      label: countMap ? count.toString() : suggestion.label,
      supportTag,
      type: suggestion.valueType || suggestion.type,
    } as DisplaySearchFilterValue;
    return value;
  }

  private async getFilter(model: CreateClientFilterRequest): Promise<Filter> {
    const {
      name,
      filterDef,
      counters,
      baseFilters,
      definitions,
      fetchValues,
      optionsSuggestionProvider,
      selectedValues,
      spreadAsMultiSelect,
      suggestionProvider,
      title,
      viewDetails,
      supportTag,
      timeFilterSettings,
      preventLiveSearch,
    } = model;
    if (name === this.FREE_TEXT_KEY) {
      return this.getFreeTextFilter(this.hubService.query);
    }
    const countMap = counters?.length
      ? chain(counters)
          .keyBy((k) => k.value)
          .mapValues((v) => v.count)
          .value()
      : null;
    let picker = filterDef.floating?.picker;
    const isToggle = picker === 'toggle';
    if (spreadAsMultiSelect && picker === 'spread') {
      picker = 'multi-select';
    }
    const fixedTitle: string = upperCaseWords(title || filterDef.title || filterDef.name);
    const isMultiSelect = picker === 'multi-select';
    const supportLiveSearch = !preventLiveSearch && (isMultiSelect || picker === 'time-dropdown');
    const sessionName = uuid.v4();
    const valuesFetcher = (term: string) => {
      return this.fetchFilterValues({
        optionsSuggestionProvider,
        suggestionProvider,
        term,
        definitions,
        filter: filterDef,
        baseFilters,
        picker,
        countMap,
        filterName: filterDef.name,
        selectedFilters: selectedValues,
        supportTag,
        sessionName,
        selectedOnTop: true,
      });
    };
    const { placeholder, showItemIcon, showItemLabel, showBackIcon, position } = viewDetails;
    const updatedViewDetails: FilterViewDetails = {
      isFullDetailLine: true,
      placeholder: placeholder || `Search for ${fixedTitle.toLowerCase()}...`,
      showItemIcon,
      showItemLabel: !!countMap || showItemLabel,
      showSelectedItemIcons: showItemIcon,
      showSplitLine: isToggle,
      oneValue: !isMultiSelect,
      limitShowSelectedLabels: 2,
      showHasValues: true,
      showClearAll: true,
      showBackIcon,
      position: position || 'Main',
      enabledOnly: picker === 'spread',
      enableBack: showBackIcon,
      ...(viewDetails || {}),
    };
    const filter: Filter = {
      name,
      title: fixedTitle,
      icon: filterDef.icon,
      values: !supportLiveSearch || fetchValues ? await valuesFetcher('') : [],
      picker,
      type: 'pre',
      valuesFetcher: supportLiveSearch ? valuesFetcher : undefined,
      viewDetails: updatedViewDetails,
      supportSingleTag: supportTag,
      applyType: filterDef?.applyType,
    };
    if (isTimeFilter(filter) && timeFilterSettings) {
      filter.pickerTypes = timeFilterSettings.pickerTypes;
      filter.minDate = timeFilterSettings.minDate;
      filter.maxDate = timeFilterSettings.maxDate;
      filter.defaultCustomTimeFilter = timeFilterSettings.defaultCustomTimeFilter;
    }
    return filter;
  }

  hasSingleFilterTag(filterName: string): boolean {
    const tagFilters = Object.keys(this.tagFilters);
    const allFilters = Object.keys(this.allFilters);
    return allFilters.length === 1 && tagFilters.length === 1 && !tagFilters.includes(filterName);
  }

  convertTagFiltersToRegular() {
    for (const [k, v] of Object.entries(this.tagFilters || {})) {
      this.setFilters(k, [], 'pre', true);
      this.setFilters(k, v, 'pre', false);
    }
  }

  getCustomSelectedTimeFilter(
    values: DisplaySearchFilterValue[],
    selected: Set<string>,
    filterName: string,
    filterIcon: Style.EntityIcon<Style.EntityIconType>
  ): DisplaySearchFilterValue[] {
    const dateValues = values.map((v) => v.title);
    const customValues = [...selected].filter((f) => !dateValues.includes(f));
    if (customValues.length > 0) {
      const selected = customValues.map((v) => this.datePickerService.getFilter(filterName, v, filterIcon) as DisplaySearchFilterValue);
      return selected;
    }
    return [];
  }

  async generateExcludeFilters(excludeFilters: Filters.ExcludeFilterType[], assistantId?: string): Promise<Filters.Values> {
    const currentAssistantId = (await this.hubService.globalAssistantId) || assistantId;
    return this.service.generateExcludeFilters(excludeFilters, currentAssistantId);
  }

  async transformFiltersForDisplay(filters: Filters.Values): Promise<Filters.Values> {
    if (isEmpty(filters)) {
      return {};
    }
    const definitions = await firstValueFrom(this.definitions$);
    const updatedFilters = {};
    for (const [key, values] of Object.entries(filters || {})) {
      const def = definitions[key];
      let updateValues = cloneDeep(values);
      if (def?.floating?.picker === 'time-dropdown') {
        updateValues = updateValues.map((v) => stringToFormattedDate(v));
      }
      updatedFilters[def?.title || def?.name || key] = updateValues;
    }
    return updatedFilters;
  }

  getFiltersAsUrlParams(filters: Filters.Values): string {
    const queryParams: string[] = [];
    for (const [key, valueArray] of Object.entries(filters)) {
      valueArray.forEach((value) => {
        const encodedKey = encodeURIComponent(`if-${key}`);
        const encodedValue = encodeURIComponent(value);
        queryParams.push(`${encodedKey}=${encodedValue}`);
      });
    }
    return queryParams.join('&');
  }
}
