<div [class]="'assistant-container ' + type()">
  <u-tab-view
    [tabsStatic]="assistantTabs()"
    (onChangeActiveTab)="setActiveTab($event.tab)"
    [selectedTabId]="selectedTab().id"
    [openLeftMenuBar]="true"
    id="assistant-tabs"
  ></u-tab-view>

  {{ track() }}

  @switch (selectedTab()?.id) {
    @case ('summary') {
      <ng-container *ngTemplateOutlet="summary"></ng-container>
    }
    @default {
      <ng-container *ngTemplateOutlet="recommendations; context: { tab: selectedTab() }"></ng-container>
    }
  }
</div>

<ng-template #summary>
  <div class="ticket-summary-tab">
    @switch (ticketSummaryState()) {
      @case ('Loading') {
        <result-ghost class="summary-popup-loading" [showAvatar]="false" [ghostingLine]="7"></result-ghost>
      }
      @case ('Empty') {
        <app-empty-state
          class="ticket-summary-empty-state"
          [title]="emptySummaryText()"
          [svgUrl]="'./assets/bar/calendar/empty-state.svg'"
        ></app-empty-state>
      }
      @case ('Completed') {
        <ng-container *ngTemplateOutlet="summaryTextContent; context: { textContent: ticketSummary().data }"></ng-container>
      }
    }
  </div>
</ng-template>

<ng-template #summaryTextContent let-textContent="textContent">
  <div class="ticket-summary">
    <div class="text-context" [innerHTML]="textContent.summary | richTextFormatted"></div>
    <div class="text-context">
      @if (textContent.customerSentiment) {
        <div class="title">Customer Sentiment:</div>
        {{ textContent.customerSentiment }}
      }
    </div>
    @if (textContent.keyDetails?.length) {
      <div class="title">Key Details:</div>
      @for (detail of textContent.keyDetails; track $index) {
        <span>{{ detail }} </span>
      }
    }
  </div>
</ng-template>

<ng-template #recommendations let-tab="tab">
  <div class="ticket-recommendations-tab">
    <div class="assistant-result-container">
      @if (tab.emptyResults !== false && !loading() && !tab.loading) {
        <div class="no-results">
          @if (tab.id === 'recommendations') {
            @if (isForceButtonClicked()) {
              <span class="no-results-title">{{ ANSWER_EMPTY_MESSAGE }}</span>
            }
            <u-button
              class="show-resolution-button"
              label="Show resolution"
              type="secondary"
              fontIcon="answer"
              [styles]="{ width: '180px', height: '40px' }"
              (onClick)="refreshIgnoreCache(true)"
            >
            </u-button>
          } @else {
            <span class="no-results-title">{{ emptyResourcesText() }}</span>
            <u-button
              class="refresh-button"
              label="Refresh"
              type="secondary-light"
              fontIcon="upgrade"
              (click)="refreshIgnoreCache(tab.id === 'tickets')"
            ></u-button>
          }
        </div>
      } @else {
        <search-results-list
          [loading]="(loading() || tab.loading) && tab.name !== 'Resolution'"
          [isLoadingAnswer]="(loading() || tab.loading) && tab.name === 'Resolution'"
          [displayedContext]="displayedContext()"
          [items]="!loading() ? tab.items : []"
          [listName]="'assistant-incontext'"
          pageType="assistant-incontext"
          [searchId]="searchId()"
          layoutMode="list"
          [defaultResultSections]="{
            showSubtitleInSecondLine: true,
            showFavoriteAction: false,
            showAddToCollectionAction: false,
            showFeedback: true,
            showFavoriteContextMenuItem: false,
            showAddToCollectionContextMenuItem: false,
          }"
          [showSearchResults]="false"
          [listView]="{ hideFollowUp: true }"
        >
        </search-results-list>
      }
    </div>
  </div>
</ng-template>

@if (errorMessage()) {
  <error-overlay [displayText]="errorMessage()" (again)="tryAgain()" (back)="back()"> </error-overlay>
}
