import { Filters, Omnibox, Resources, Search } from '@local/client-contracts';
import { invoker } from '@unleash-tech/js-rpc';
import { Observable } from 'rxjs';

export class FiltersRpcInvoker implements Filters.Service {
  @invoker
  all$(experienceId?: string): Observable<Filters.FilterDefinition[]> {
    return;
  }

  @invoker
  getSuggestions(settings: Omnibox.SuggestionSearchOptions, experienceId?: string): Promise<Omnibox.Suggestion[]> {
    return;
  }

  @invoker
  getSuggestionsAssistant(settings: Omnibox.AssistantSuggestionSearchOptions, experienceId?: string): Promise<Omnibox.Suggestion[]> {
    return;
  }

  @invoker
  definitions$(experienceId?: string): Observable<Filters.FilterDefinition[]> {
    return;
  }

  @invoker
  getTags(inlineFilters: Filters.Values, confirmValueExists?: boolean, experienceId?: string): Promise<Omnibox.Tag[]> {
    return;
  }

  @invoker
  getRelevantStandardTypes(activeFilters: Filters.ActiveFilters, experienceId?: string): Promise<string[]> {
    return;
  }

  @invoker
  getRelevantTypes(activeFilters: Filters.ActiveFilters, allTypes?: boolean, experienceId?: string): Promise<string[]> {
    return;
  }

  @invoker
  getTypesRecommendedFilters(types: Search.FilterCountDetails[], experienceId?: string): Promise<string[]> {
    return;
  }

  @invoker
  generateExcludeFilters(excludeFilters: Filters.ExcludeFilterType[], experienceId?: string): Promise<Filters.Values> {
    return;
  }

  @invoker
  toRequestFilters(filters: Search.RequestFilters): Promise<Resources.SearchAppliedFilters> {
    return;
  }
}
