import { ChangeDetectionStrategy, Component, effect, inject, input, Input, signal, type WritableSignal } from '@angular/core';
import { UiIconModel } from '@local/ui-infra';
import { Config } from '@environments/config';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AssistantsService } from '@shared/services/assistants.service';
import { AssistantItem } from '../../../results';
import { AssistantChatData } from '../../model';
import { isGeneralAssistant } from '@local/common-web';
import { Experiences } from '@local/client-contracts';
import { AIModel } from '../../../settings/components/assistant-page/components/assistant-knowledge/assistant-knowledge.component';

export type ChatAssistantIconModel = { emoji?: string; icon?: UiIconModel };
@UntilDestroy()
@Component({
  selector: 'assistant-icon',
  templateUrl: './assistant-icon.component.html',
  styleUrls: ['./assistant-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssistantIconComponent {
  readonly DEFAULT_ASSISTANT_ICON: UiIconModel = { type: 'font', value: 'icon-assistant' };
  readonly MODELS_FONT_ICON = { gpt: 'icon-gpt' };
  model = input<AssistantItem | AssistantChatData>();
  @Input() iconStyles: any = { 'font-size': '19px', height: '19px', width: '19px' };
  @Input() spriteEmojiSize = 24;
  @Input() displayBorder: boolean;
  @Input() useFont: boolean;
  @Input() isTooltip: boolean;
  chatDefaultIcon: WritableSignal<UiIconModel> = signal(null);
  assistantService: AssistantsService = inject(AssistantsService);
  constructor() {
    effect(
      () => {
        this.chatDefaultIcon.set(null);
        const { knowledgeType, model, emoji, state } = (this.model() as AssistantItem)?.settings
          ? this.processAssistantItem(this.model() as AssistantItem)
          : this.processAssistantChatData(this.model());
        if (state === 'deleted') {
          this.chatDefaultIcon.set({
            type: 'img',
            value: {
              lightUrl: this.isTooltip ? './assets/assistants/disabled-assistant-light.svg' : './assets/assistants/disabled-assistant.svg',
            },
          });
        } else {
          if (!emoji && knowledgeType === Experiences.KnowledgeType.External && model) {
            this.initDefaultIcon(model);
          } else {
            this.chatDefaultIcon.set(null);
          }
        }
      },
      { allowSignalWrites: true }
    );
  }

  initDefaultIcon(modelId) {
    this.assistantService.llms$.pipe(untilDestroyed(this)).subscribe((aiModels: AIModel[]) => {
      const defaultIcon = aiModels.find((model) => model.id === modelId);
      if (defaultIcon) {
        if (this.MODELS_FONT_ICON[defaultIcon.type] && this.useFont) {
          this.chatDefaultIcon.set({ type: 'font', value: this.MODELS_FONT_ICON[defaultIcon.type] });
        } else {
          this.chatDefaultIcon.set({ type: 'img', value: { lightUrl: `${Config.chatIconsBaseUrl}${defaultIcon?.icon}` } });
        }
      }
    });
  }

  private processAssistantItem(item: AssistantItem) {
    if (!isGeneralAssistant(item)) {
      return {};
    }
    return {
      knowledgeType: item?.settings?.general?.knowledgeType,
      model: item?.settings?.model,
      emoji: item?.emoji || item.settings.emoji,
      state: 'enabled',
    };
  }

  private processAssistantChatData(item: AssistantChatData) {
    return { knowledgeType: item?.knowledgeType, model: item?.model, emoji: item?.emoji, state: item?.state };
  }
}
