import {
  ChangeDetectionStrategy,
  Component,
  effect,
  input,
  signal,
  viewChild,
  ElementRef,
  WritableSignal,
  HostListener,
} from '@angular/core';

@Component({
  selector: 'u-truncate-text',
  templateUrl: './u-truncate-text.component.html',
  styleUrls: ['./u-truncate-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UTruncateText {
  text = input<string>();
  tooltipPosition = input<string>();
  element = viewChild<ElementRef>('textElement');
  showTooltip: WritableSignal<boolean> = signal(true);

  ngAfterViewInit() {
    this.showTooltip.set(this.element().nativeElement.offsetWidth < this.element().nativeElement.scrollWidth);
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.showTooltip.set(this.element().nativeElement.offsetWidth < this.element().nativeElement.scrollWidth);
  }
}
