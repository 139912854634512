import { HttpClient } from '@angular/common/http';
import { Directive, ElementRef, inject, Input } from '@angular/core';
import { ContentCacheService } from '../services/svg-content.service';
import { firstValueFrom } from 'rxjs';

export interface ColorReplacer {
  colors: string[];
  replaceWith: string;
}
/** Used to read and parse the svg in a way that supports theming. */
@Directive({
  selector: '[svg]',
})
export class SvgRendererDirective {
  private WHITES: ColorReplacer = { colors: ['white', '#FFFFFF', '#ECEDF0', '#191919'], replaceWith: 'var(--color-text-primary)' };
  private BACKGROUND: ColorReplacer = { colors: ['#FBFCFD'], replaceWith: 'var(--color-bg)' };
  private GREYS: ColorReplacer = { colors: ['#D2D4DA'], replaceWith: 'var(--color-text-highlight)' };
  private YELLOWS: ColorReplacer = { colors: ['#FFB13D'], replaceWith: 'var(--yellow-500)' };
  private REDS: ColorReplacer = { colors: ['#ff6b5b'], replaceWith: 'var(--red-500)' };
  private GREENS: ColorReplacer = { colors: ['#1BC47D'], replaceWith: 'var(--green-500)' };
  private PRIMARY: ColorReplacer = { colors: ['#BE8DF6'], replaceWith: 'var(--color-primary)' };
  private REVERSE_BG: ColorReplacer = { colors: ['#24292E'], replaceWith: 'var(--color-bg-reverse)' };

  private replacers: ColorReplacer[] = [
    this.WHITES,
    this.GREYS,
    this.YELLOWS,
    this.REDS,
    this.GREENS,
    this.PRIMARY,
    this.BACKGROUND,
    this.REVERSE_BG,
  ];
  private contentCacheService: ContentCacheService = inject(ContentCacheService);

  @Input('svgIgnore') svgIgnoreColors: string[] = [];
  @Input('svgFallback') fallback: string;
  @Input('svg') set url(v: string) {
    this.replaceColorValues(v)
      .then((html) => this.appendSVG(html))
      .catch((error) => {
        console.error(`Error processing svg. displaying fallback. url: ${v}, fallback: ${this.fallback}`, {
          error,
          url: v,
          fallback: this.fallback,
        });
        this.el.nativeElement.innerHTML = `<img src='${this.fallback}'>`;
      });
  }

  constructor(
    private hostRef: ElementRef,
    private http: HttpClient,
    private el: ElementRef
  ) {}

  /** Replaces the colors with our variables colors which results in theming support.  */
  private async replaceColorValues(url: string): Promise<string> {
    let content: string;
    try {
      content = await firstValueFrom(this.contentCacheService.getContent(url));
    } catch (error) {
      console.error('got error trying to fetch url', { url, error });
      throw error;
    }

    let next: string = content.slice(0); // new ref
    for (const replacer of this.replacers) {
      if (this.svgIgnoreColors?.length) {
        replacer.colors = replacer.colors.filter((c) => !this.svgIgnoreColors.map((c) => c.toLowerCase()).includes(c.toLowerCase()));
      }
      if (replacer.colors.length) {
        const regex = new RegExp(replacer.colors.join('|'), 'gi');
        next = next.replace(regex, replacer.replaceWith);
      }
    }

    return next;
  }

  /** Renderers the svg inside the element */
  private appendSVG(svgHTML: string) {
    if (!this.hostRef) {
      throw new Error('No host element to render svg as a child.');
    }
    this.hostRef.nativeElement.innerHTML = svgHTML;
  }
}
