<div class="card-versions-wrapper">
  <div class="card-version-header">
    <div class="card-version-header-content">
      <div class="card-version-title">
        <u-icon [model]="{ type: 'font', value: 'icon-clock' }" [styles]="{ fontSize: '12px' }"></u-icon>
        <span class="title-text">Versions history</span>
      </div>
      <div class="card-version-header-left">
        <div class="manage-version-toggle">
          <div class="toggle-changes">Changes from previous versions</div>
          <u-toggle-switch
            type="small"
            [checked]="displayPrevVersion()"
            [disabled]="!versions()?.length"
            (onChange)="onToggleButtonChange($event)"
          ></u-toggle-switch>
        </div>
        <ng-container *ngTemplateOutlet="closeButton"></ng-container>
      </div>
    </div>
    <div class="card-version-divider"></div>
  </div>
  @if (loading()) {
    <ng-container *ngTemplateOutlet="loadingContent"></ng-container>
  } @else {
    <div class="card-version-content">
      @if (displayPrevVersion() && !currentVersion()?.isFirstVersion) {
        <ng-container *ngTemplateOutlet="compareVersion; context: { version: prevVersion() }"></ng-container>
        <div class="card-version-divider-column"></div>
      } @else {
        <div class="space-saver"></div>
      }
      <ng-container *ngTemplateOutlet="compareVersion; context: { version: currentVersion() }"></ng-container>
      @if (!hideVersionList()) {
        <div class="card-version-divider-column"></div>
        <wiki-card-version-list
          [selectedIndex]="selectedIndex()"
          [versions]="versions()"
          [verificationStatus]="verification?.status"
          (onSelectedVersion)="selectedVersion($event)"
        ></wiki-card-version-list>
      }
    </div>
  }
</div>

<ng-template #compareVersion let-version="version">
  <div
    class="card-version-compare-wrapper"
    [ngClass]="{ 'single-view': !displayPrevVersion() || currentVersion()?.isFirstVersion, 'hide-version-list': hideVersionList() }"
  >
    <div class="manage-versions">
      <div class="compare-version-number" [pTooltip]="version?.updatedTime" tooltipPosition="right" tooltipStyleClass="u-tooltip">
        @if (version?.isFirstVersion) {
          Original
        } @else {
          Revision #{{ version?.versionNumber }}
        }
        @if (version?.isLatestVersion) {
          (Current)
        }
      </div>
      @if (version?.isCurrent) {
        <div class="manage-versions-action">
          @if (!version?.isLatestVersion) {
            <u-button
              [label]="smallWidth() && !version?.isFirstVersion ? 'Restore' : 'Restore version'"
              type="primary"
              [styles]="{ height: '28px' }"
              (onClick)="restoreVersion()"
            ></u-button>
          }
          <u-button
            fontIcon="clock"
            type="secondary"
            [styles]="{ fontSize: '12px', height: '28px', width: '28px', padding: '7px' }"
            class="hide-version-button"
            [ngClass]="{
              active: !hideVersionList(),
              blur: hideVersionList(),
            }"
            (onClick)="onHideVersionList()"
            [pTooltip]="hideVersionList() ? 'Show version history' : 'Hide version history'"
            tooltipPosition="top"
            tooltipStyleClass="u-tooltip"
          >
          </u-button>
        </div>
      }
    </div>
    <div class="card-version-divider"></div>
    <div class="compare-version-content">
      <wiki-card-compare-version [html]="displayPrevVersion() ? version?.diffContent : version?.content"></wiki-card-compare-version>
    </div>
  </div>
</ng-template>

<ng-template #closeButton>
  <u-button
    class="icon-button-clean"
    elementSize="small"
    fontIcon="times"
    type="secondary"
    [styles]="{ fontSize: '20px', width: '28px', height: '28px' }"
    (onClick)="closePopup()"
  ></u-button>
</ng-template>

<ng-template #loadingContent>
  <div class="loading-version-history">
    <result-ghost [showAvatar]="false" [ghostingLine]="4"></result-ghost>
  </div>
</ng-template>
