import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit, signal } from '@angular/core';
import { Assistants } from '@local/client-contracts';
import { ManualPromise } from '@local/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LoaderService } from '@shared/loader.service';
import { LogService, TelemetryService } from '@shared/services';
import { AppService } from '@shared/services/app.service';
import { AssistantsService } from '@shared/services/assistants.service';
import { SessionService } from '@shared/services/session.service';
import { isProdEnv } from '@shared/utils';
import { Logger } from '@unleash-tech/js-logger';
import { cloneDeep, isEqual, upperFirst } from 'lodash';
import { Subscription } from 'rxjs';
import { ExperiencesService } from 'src/app/bar/services/experiences.service';
import { SearchOptions, SearchResultContext, SearchService, SearchSession } from 'src/app/bar/services/search';
import { AssistantIncontextSourceSettings } from 'src/app/bar/services/search/client';
import {
  AssistantIncontextExtraData,
  AssistantTicketResult,
} from 'src/app/bar/services/search/client/assistants-incontext/assistant-incontext-extra-data';
import { isAnswerItem, isTimeActionItem } from '../../../results';
import { AssistantIncontextService } from '../../services/assistant-incontext.service';
import { TicketTracker } from './assistant-ticket-tracker';

interface AssistantTab {
  id: string;
  name: string;
  amount?: number;
  items?: AssistantTicketResult[];
  emptyResults?: boolean;
  loading?: boolean;
}

export enum TicketSummaryState {
  Idle = 'IDLE',
  Loading = 'LOADING',
  Empty = 'EMPTY',
  Error = 'ERROR',
  Completed = 'COMPLETED',
}
type TicketSummaryStateType = keyof typeof TicketSummaryState;

@UntilDestroy()
@Component({
  selector: 'assistant-incontext',
  templateUrl: './assistant-incontext.component.html',
  styleUrls: ['./assistant-incontext.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssistantIncontextComponent implements OnInit, OnDestroy, AfterViewInit {
  private readonly ASSISTANT_SOURCE_TYPE = 'assistant-incontext';
  private readonly ASSISTANT_EMPTY_MESSAGE = 'No results found for this ';
  private readonly SUMMARY_EMPTY_MESSAGE = 'No summary for this ';
  private readonly ASSISTANTS_TABS = [
    { id: 'summary', name: 'Summary' },
    { id: 'recommendations', name: 'Resolution' },
    { id: 'knowledge', name: 'Knowledge' },
    { id: 'tickets', name: 'Tickets' },
  ];
  readonly ANSWER_EMPTY_MESSAGE = 'No answer found';
  private logger: Logger;
  private shouldReloadTabsData = false;
  private ticketData: Assistants.AssistantTicketRequest;
  private searchSession: SearchSession;
  private wiki: ManualPromise<string> = new ManualPromise();
  private ticketSummary$: Subscription;
  private ticketTracker: TicketTracker;
  private ctx: SearchResultContext;
  private questionContext: Assistants.QuestionContext;
  private searchResultContextCache: SearchResultContext;
  private debug: boolean;
  private assistantTabsData = signal<AssistantTab[]>(this.ASSISTANTS_TABS);

  assistantTabs = signal<AssistantTab[]>(this.ASSISTANTS_TABS);
  type = signal<string>('');
  selectedTab = signal<AssistantTab>(this.ASSISTANTS_TABS[0]);
  ticketSummary = signal<Assistants.AssistantSummaryResponse>(null);
  ticketSummaryState = signal<TicketSummaryStateType>('Idle');
  loading = signal(false);
  errorMessage = signal<string>(null);
  displayedContext = signal<SearchResultContext>(null);
  searchId = signal<string>('');
  isForceButtonClicked = signal<boolean>(false);

  emptyResourcesText = signal(null);
  emptySummaryText = signal(null);

  constructor(
    private loaderService: LoaderService,
    private searchService: SearchService,
    private assistantIncontextService: AssistantIncontextService,
    private experiencesService: ExperiencesService,
    private assistantsService: AssistantsService,
    private logService: LogService,
    appService: AppService,
    telemetryService: TelemetryService,
    sessionService: SessionService
  ) {
    this.ticketTracker = new TicketTracker(appService, telemetryService);
    sessionService.current$.pipe(untilDestroyed(this)).subscribe((s) => {
      this.ticketTracker.updateAccount(s);
    });
  }

  ngOnInit(): void {
    this.logger = this.logService.scope('AssistantIncontext');
    this.searchSession = this.searchService.getOrCreateSearchSession('assistant-incontext');
    this.loaderService.ready$.next(true);
    this.assistantIncontextService.context$.pipe(untilDestroyed(this)).subscribe(async (data) => {
      if (isEqual(this.ticketData, data)) {
        return;
      }
      this.type.set(data.type);
      const status = data.ticket.status;
      this.questionContext = {
        source: 'Web',
        platform: 'Embed',
        location: (<Assistants.AnswerPlatformType>upperFirst(this.type())) as Assistants.AnswerLocationType,
      };
      this.ticketData = data;
      this.ticketData.ticket = {
        ...this.ticketData.ticket,
        status,
      };
      if (data.debug !== undefined) {
        this.debug = data.debug;
      }
      if (this.type() === 'zendesk') {
        this.updateEmptyText('ticket');
        this.experiencesService.findWiki(data.subdomain).then((wiki) => {
          if (!this.wiki.status) {
            this.wiki.resolve(wiki);
          }
        });
      } else {
        this.updateEmptyText('case');
        if (!this.wiki.status) {
          this.wiki.resolve(null);
        }
      }
      this.loadingSummary(data);
      if (!this.searchResultContextCache) {
        this.search(data, 'init-ticket-data');
      }
    });
  }

  ngOnDestroy(): void {
    this.searchSession?.destroy();
  }

  ngAfterViewInit() {
    const overlay = document.getElementsByClassName('router-outlet-container');
    if (overlay.length > 0) {
      overlay[0]['style'].overflow = 'hidden';
    }
    const body: HTMLBodyElement = document.documentElement.querySelector('body');
    body.style.backgroundColor = 'transparent';
  }

  track() {
    this.ticketTracker.track(this.ctx);
  }

  private async loadingSummary(data: Assistants.AssistantTicketRequest): Promise<void> {
    try {
      if (this.ticketSummary$) {
        this.ticketSummary$.unsubscribe();
        this.ticketSummary$ = null;
      }
      this.errorMessage.set(null);
      if (!data.ticket.messages?.length && !data.ticket.description) {
        this.ticketSummaryState.set('Empty');
        return;
      }
      this.ticketSummaryState.set('Loading');
      const summaryTicket$ = this.assistantsService.summaryTicket$({ ticket: data.ticket, context: this.questionContext });
      this.ticketSummary$ = summaryTicket$.pipe(untilDestroyed(this)).subscribe({
        next: (s) => {
          this.ticketSummary.set(s.response);
          this.ticketSummaryState.set(this.ticketSummary()?.text ? 'Completed' : 'Empty');
        },
        error: (error) => {
          this.onSummaryError(error);
        },
      });
    } catch (error) {
      this.onSummaryError(error);
    }
  }

  private onSummaryError(error) {
    this.errorMessage.set(`Summary error: ${error?.message || ''} ${error?.title || ''} ${error?.data?.title || ''}`);
    this.logger.error('Received an error while loading summary.', error);
  }

  tryAgain() {
    this.updateDataByActiveTab('try-again');
  }

  async back() {
    this.errorMessage.set(null);
    await this.setActiveTab(this.selectedTab()?.id === 'summary' ? 'recommendations' : 'summary');
    this.shouldReloadTabsData = true;
  }

  private search(data: Assistants.AssistantTicketRequest, trigger: string) {
    this.ticketTracker.start(Date.now(), trigger);
    this.ticketTracker.updateSearchParams(data?.ticket?.id);

    this.loading.set(true);
    this.errorMessage.set(null);

    const options: SearchOptions = {
      resetSession: false,
      sources: [
        {
          id: 'assistant-incontext',
          type: this.ASSISTANT_SOURCE_TYPE,
          requestMaxCount: 3,
          caching: { strategy: 'cache-or-source' },
          noHeader: true,
          disableCloudGroup: true,
          useSourceFilters: true,
          filters: { preFilters: {}, postFilters: {} },
          advancedSearch: true,
          contentSearch: true,
          tag: `assistant-incontext-${trigger}`,
          data: {
            ...(data || {}),
            debug: !isProdEnv(),
            context: this.questionContext,
          },
        } as AssistantIncontextSourceSettings,
      ],
      trigger,
    };
    this.searchSession
      .search$(options)
      .pipe(untilDestroyed(this))
      .subscribe(async (ctx: SearchResultContext) => {
        this.ctx = ctx;
        if (ctx?.searchCompleted) {
          this.searchResultContextCache = ctx;
          const source = this.searchResultContextCache.sources[0];
          const extra = source.extra;
          if (source.error) {
            this.errorMessage.set(
              `Search error: ${
                source.error?.data?.reason ||
                source.error?.data?.detail ||
                source.error?.data?.title ||
                source.error?.title ||
                source.error?.message ||
                ''
              } `
            );
            this.loading.set(false);
            return;
          }
          this.searchId.set(extra?.searchId);
          this.displayedContext.set(ctx);
          await this.buildTicketTabs(extra?.extraData, extra?.responseStatus);
          this.loading.set(false);
        }
      });
  }

  private async handleResults(items: AssistantTicketResult[]) {
    if (!items) {
      return;
    }
    const clonedItems = cloneDeep(items);

    items = await Promise.all(
      clonedItems.map(async (i) => {
        if (isAnswerItem(i)) {
          if (this.debug === false) {
            i.debugInfo = undefined;
          }
          i.collectionId = await this.wiki;
          return i;
        }
        if (isTimeActionItem(i)) {
          i.actionButton.buttonAction = () => {
            this.refreshIgnoreCache();
          };
          return i;
        }
        return {
          ...i,
          showResultSections: {
            showSubtitleInSecondLine: true,
            showFavoriteAction: false,
            showAddToCollectionAction: false,
            showFavoriteContextMenuItem: false,
            showAddToCollectionContextMenuItem: false,
            showContextMenu: false,
            showShareAction: false,
            showPreviewIconContextMenu: true,
            showSummaryAction: true,
          },
        };
      })
    );
    return items;
  }

  private async buildTicketTabs(extraData: AssistantIncontextExtraData[], responseStatus?: Assistants.AnswerStatusType) {
    const tabsWithData = cloneDeep(this.assistantTabsData());
    for (const tab of tabsWithData) {
      const tabData = extraData?.find((data) => data.title === tab.name);
      if (!tabData) {
        continue;
      }
      if (tab.amount !== tabData.totalResults) {
        tab.amount = tabData.totalResults;
        this.assistantTabs.update((tabs) => tabs.map((t) => (t.id === tab.id ? { ...t, amount: tabData.totalResults } : t)));
      }
      tab.items = await this.handleResults(tabData.results);
      tab.emptyResults = !tabData.results?.length;
      tab.loading = tab.emptyResults && responseStatus === 'Generating';
      if (tab.id === this.selectedTab()?.id) {
        this.selectedTab.set(cloneDeep(tab));
      }
    }
    this.assistantTabsData.set(tabsWithData);
  }

  private async updateDataByActiveTab(trigger?: string): Promise<void> {
    if (this.selectedTab()?.id == 'summary' && this.ticketSummaryState() != 'Loading') {
      this.loadingSummary(this.ticketData);
    } else if (!this.loading()) {
      this.search(this.ticketData, trigger || 'click-tab');
    }
  }

  async setActiveTab(tabId: string): Promise<void> {
    if (!tabId || tabId === this.selectedTab()?.id) {
      return;
    }
    this.selectedTab.set({ ...this.assistantTabsData().find((t) => t.id === tabId) });
    if (this.shouldReloadTabsData) {
      this.updateDataByActiveTab();
      this.shouldReloadTabsData = false;
    }
  }

  private updateEmptyText(text: string): void {
    this.emptyResourcesText.set(`${this.ASSISTANT_EMPTY_MESSAGE} ${text}`);
    this.emptySummaryText.set(`${this.SUMMARY_EMPTY_MESSAGE} ${text}`);
  }

  refreshIgnoreCache(forceFullResolution?: boolean) {
    this.search({ ...this.ticketData, forceFullResolution, ignoreCache: true }, 'user_query');
    this.isForceButtonClicked.set(forceFullResolution);
  }
}
