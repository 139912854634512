import { Favorites, MemorySearch, Results, Search } from '@local/client-contracts';
import { observable } from '@local/common';
import { LogService } from '@shared/services';
import { ApplicationsService } from '@shared/services/applications.service';
import { LinksService } from '@shared/services/links.service';
import { MemorySearchService } from '@shared/services/memory-search.service';
import { NativeAppService } from '@shared/services/native-app.service';
import { AppResource, ResourcesService } from '@shared/services/resources.service';
import { isEqual } from 'lodash';
import { concatAll, firstValueFrom, from, map, Observable, of, Subscription } from 'rxjs';
import { FavoritesItem, SearchResults } from 'src/app/bar/views';
import { Action } from 'src/app/bar/views/results/models/view-filters';
import { FavoritesService } from '../../../favorites.service';
import { FiltersService } from '../../../filters.service';
import { ResultsService } from '../../../results.service';
import { ResourcesMemorySearchClient } from '../resources-memory-search/resources-memory-search-client';
import { SearchRequest } from '../search-request';
import { SearchResponse } from '../search-response';
import { ResourcesFavoritesSourceSettings } from './resources-favorites-source-settings';
import { PeopleService } from 'src/app/bar/views/preview/people-preview/services/people.service';

export class ResourcesFavoritesSearchClient extends ResourcesMemorySearchClient<ResourcesFavoritesSourceSettings> {
  private instances: { [sessionName: string]: { sub?: Subscription; refreshRunning?: boolean } } = {};

  constructor(
    logService: LogService,
    private favoritesService: FavoritesService,
    private resultsService: ResultsService,
    private resourcesService: ResourcesService,
    private nativeAppService: NativeAppService,
    filterService: FiltersService,
    appsService: ApplicationsService,
    linksService: LinksService,
    peopleService: PeopleService,
    memorySearchService: MemorySearchService
  ) {
    super(logService, memorySearchService, filterService, appsService, linksService, peopleService, ['Alphabetical', 'Time']);
    this.logger = logService.scope('resources-favorites');
  }

  private cacheFavoriteItems: { time: number; resources: Favorites.FavoriteLinkResource[]; items: any } = null;

  @observable
  getInput(request: SearchRequest<ResourcesFavoritesSourceSettings>, response: SearchResponse): Observable<MemorySearch.Item[]> {
    const sessionName = request.sessionName;
    const subscription = this.instances[sessionName]?.sub;
    if (subscription) {
      subscription.unsubscribe();
      this.instances[sessionName].sub = null;
    }
    let finished = false;
    let res = [];
    this.instances[sessionName] = {};
    this.instances[sessionName].sub = this.favoritesService.getByType$('link-resource').subscribe((favorites: Favorites.Item[]) => {
      if (finished && res.length !== favorites?.length) {
        const instance = this.instances[sessionName];
        if (instance && !instance.refreshRunning) {
          this.instances[sessionName].refreshRunning = true;
          this.refresh(request, response);
        }
      }
    });
    const settings = request.sourceSettings;
    return from(
      (async () => {
        const [favorites, typeMap] = await Promise.all([
          firstValueFrom(this.favoritesService.getByType$('link-resource')),
          firstValueFrom(this.resourcesService.typeMap$),
        ]);
        res = favorites?.map((f) => f.id) || [];
        if (!favorites?.length) {
          finished = true;
          this.instances[sessionName].refreshRunning = false;
          return of([]);
        }
        const cache = this.cacheFavoriteItems;
        if (
          cache &&
          Date.now() - cache.time <= 30000 &&
          (!cache.resources.length || cache.items.length) &&
          isEqual(
            cache.resources.map((x) => x.resourceId),
            res
          )
        ) {
          finished = true;
          this.instances[sessionName].refreshRunning = false;
          return of(cache.items);
        }
        const resources = favorites.map((f) => ({ resourceId: f.id, linkId: f.parentId }) as Results.LinkResource);
        return this.resultsService
          .getItems$({ resources, cache: request.sourceSettings.cache, excludeLocal: !this.nativeAppService.canSearchPc() })
          .pipe(
            map((items: Search.ResultResourceItem[]) => {
              const newItems = items
                .filter((x) => x?.type == 'result' && x.resource.appId !== 'wiki')
                .filter((x) => x)
                .map((r) => this.processSearchResult(r, settings, typeMap));
              finished = true;
              if (this.instances[sessionName]) {
                this.instances[sessionName].refreshRunning = false;
              }
              this.cacheFavoriteItems = { resources, time: Date.now(), items: newItems };
              return [...newItems];
            })
          );
      })()
    ).pipe(concatAll());
  }

  destroy(id: number, sessionName: string): void {
    this.instances[sessionName]?.sub?.unsubscribe();
    this.instances[sessionName] = null;
  }

  private processSearchResult(
    result: Search.ResultResourceItem,
    settings: ResourcesFavoritesSourceSettings,
    typeMap: {
      [type: string]: AppResource;
    }
  ) {
    if (settings.displayOneLine) {
      result.showResultSections = { ...(result.showResultSections || {}), showSingleLineView: true };
    }
    const view = result.view;
    const resource = result.resource;
    const resourceProp = typeMap[resource?.type];
    let sortValue;
    switch (settings.sorting?.by) {
      case 'Alphabetical':
        sortValue = view?.title?.text;
        break;
      case 'Timestamp':
        sortValue = resource?.traits?.modifiedAt;
        break;
    }
    return {
      searchText:
        view?.title?.text ||
        ' ' + ' ' + view?.subtitle?.text ||
        ' ' +
          ' ' +
          (resourceProp?.title || ' ') +
          ' ' +
          (resourceProp?.service || ' ') +
          ' ' +
          (resourceProp?.app || ' ') +
          ' ' +
          ' in favorites',
      data: result,
      sortValue: sortValue,
    } as MemorySearch.Item;
  }

  async getOutput(items: MemorySearch.Item[], sourceSettings: ResourcesFavoritesSourceSettings): Promise<SearchResults[]> {
    this.adjustItemIcons(items, sourceSettings);
    const typeMap = await firstValueFrom(this.resourcesService.typeMap$);
    const arrOutput: SearchResults[] = [];
    for (const item of items) {
      const action: Action = await this.resultsService.getResultAction(item.data);
      const data: FavoritesItem = {
        ...item.data,
        highlights: [],
        isFavorite: true,
        filterType: typeMap[item.data?.resource?.type]?.title,
      };
      arrOutput.push({ ...data, action });
    }
    return arrOutput;
  }
}
