import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Chats, Experiences, Filters, Search } from '@local/client-contracts';
import { observable, ManualPromise } from '@local/common';
import { generateId, getAssistantDescription, getAssistantTitle, isEmbed, isExtension, isGeneralAssistant } from '@local/common-web';
import { LogService, ServicesRpcService, WindowService } from '@shared/services';
import { RouterService } from '@shared/services/router.service';
import { Logger } from '@unleash-tech/js-logger';
import { isBoolean, isEmpty } from 'lodash';
import { BehaviorSubject, Observable, ReplaySubject, Subject, filter, firstValueFrom, map, of, switchMap } from 'rxjs';
import { AvatarItemModel } from 'src/app/bar/models/avatar-item.model';
import { WorkspacesService } from 'src/app/bar/services';
import { ChatAssistantsService } from 'src/app/bar/services/chat-assistants.service';
import { ExperiencesService } from 'src/app/bar/services/experiences.service';
import { FiltersService } from 'src/app/bar/services/filters.service';
import { HubService } from 'src/app/bar/services/hub.service';
import { ChatsRpcInvoker } from 'src/app/bar/services/invokers/chats.rpc-invoker';
import { CHAT_PAGE_PATH, CHAT_PAGE_PATH_SESSION, SIDE_PANEL_PAGE_PATH } from 'src/app/bar/utils/constants';
import { AnswerSearchItem } from '../../results';
import { AssistantChatData, ChatErrorState, CurrentChatData, NEW_CHAT_ID } from '../model';
import { ChatResourcesService } from './chat-resources.service';
import { AvatarListService } from 'src/app/bar/services/avatar-list.service';

const QUESTION_DRAFT_QUERY = 'q-query';
const IS_GLOBAL_ASSISTANT = 'global';

export type AssistantDetails = { assistant?: Experiences.ExperienceItem; error?: ChatErrorState };

@Injectable()
export class ChatsService {
  private readonly TEMP_CHAT_SESSION_STORAGE_KEY = 'temp_chat_session';
  private logger: Logger;
  private service: Chats.Service;
  private _sessions$ = new ReplaySubject<Chats.ChatSession[]>(1);
  private _currentChat$ = new BehaviorSubject<CurrentChatData>(null);
  private _currentSessionId$ = new BehaviorSubject<string>(null);
  private isEmbed = isEmbed();
  private localTempChat: Chats.Chat;
  private tempChatSession: Chats.Chat;
  private _assistantsForChat$ = new ReplaySubject<{ [key: string]: AssistantChatData }>(1);
  private _chatErrorState$ = new BehaviorSubject<ChatErrorState>(null);
  public forceStopChat$ = new Subject<boolean>();
  private chatSessionsPromise: { [key: string]: ManualPromise<boolean> } = {};
  private isExtension = isExtension();
  private _userMessage$ = new BehaviorSubject<Chats.UserMessage>(null);

  @observable
  get assistantsForChat$(): Observable<{ [key: string]: AssistantChatData }> {
    return this._assistantsForChat$;
  }

  @observable
  get currentChat$(): Observable<CurrentChatData> {
    return this._currentChat$;
  }

  @observable
  get currentAssistant$(): Observable<AssistantChatData> {
    return this._currentChat$.pipe(
      filter((chat) => !!chat),
      map((chatData) => chatData.assistant)
    );
  }

  @observable
  get currentSessionId$(): Observable<string> {
    return this._currentSessionId$;
  }

  @observable
  get chatErrorState$(): Observable<ChatErrorState> {
    return this._chatErrorState$.asObservable();
  }

  @observable
  get latestChatIdByCurrentChat$(): Observable<string> {
    return this.currentChat$.pipe(
      filter((currentChat) => !!currentChat),
      switchMap((currentChat) =>
        this.sessions$.pipe(
          map((sessions) => {
            const currentSessions = sessions.filter((session) => session.assistantId === currentChat.assistant?.id && !session.ignore);
            return currentSessions[0]?.id;
          })
        )
      )
    );
  }

  @observable
  get userMessage$(): Subject<Chats.UserMessage> {
    return this._userMessage$;
  }

  set chatErrorState(state: ChatErrorState) {
    this._chatErrorState$.next(state);
  }

  private set currentChat(chat: CurrentChatData) {
    this._currentChat$.next(chat);
  }

  private get currentChat(): CurrentChatData {
    return this._currentChat$.value;
  }

  @observable
  get sessions$(): Observable<Chats.ChatSession[]> {
    return this._sessions$.asObservable();
  }

  constructor(
    services: ServicesRpcService,
    logger: LogService,
    private routerService: RouterService,
    private windowService: WindowService,
    private filtersService: FiltersService,
    private hubService: HubService,
    private chatResourcesService: ChatResourcesService,
    private experiencesService: ExperiencesService,
    private workspaceService: WorkspacesService,
    private chatAssistantsService: ChatAssistantsService,
    private avatarListService: AvatarListService
  ) {
    this.logger = logger.scope('ChatsService');
    this.service = services.invokeWith(ChatsRpcInvoker, 'chats');
    this.service.sessions$.subscribe((sessions) => {
      this._sessions$.next(sessions);
    });
    this.initAssistantsData();
    this.handleChatRoute();
  }

  createAssistantChatData(item: Experiences.ExperienceItem): AssistantChatData {
    if (!item || !isGeneralAssistant(item)) {
      return;
    }
    return {
      id: item.id,
      emoji: item.settings?.emoji,
      name: getAssistantTitle(item),
      createdByInfo: this.getCreatedByInfo(item),
      description: getAssistantDescription(item),
      canAccess: ['creator', 'editor', 'viewer'].includes(item?.permissionRole),
      icon: !item.settings?.emoji ? { type: 'font', value: 'icon-assistant' } : null,
      knowledgeType: item.settings?.general?.knowledgeType,
      model: item.settings?.model,
      conversationStarterMessages: item.settings?.general?.conversationStarterMessages,
      state: this.chatAssistantsService.isValidAssistantForChat(item) ? 'enabled' : 'disabled',
      allowFileUpload: item.settings?.experienceTools?.fileSearch?.enabled,
      allowWebAccess: item.settings?.experienceTools?.webSearch?.enabled,
    };
  }

  private initAssistantsData() {
    this.chatAssistantsService.assistantForChat$.subscribe(
      async (items) => {
        const assistants: { [key: string]: AssistantChatData } = {};
        items.forEach((item) => {
          assistants[item.id] = this.createAssistantChatData(item);
        });
        const defaultAssistant = await this.getWorkspaceAssistantData();
        assistants[defaultAssistant.id] = defaultAssistant;
        this._assistantsForChat$.next(assistants);
      },
      async (error) => {
        this.logger.error('Failed to load assistants for chat', error);
        const defaultAssistant = await this.getWorkspaceAssistantData();
        this._assistantsForChat$.next({ [defaultAssistant.id]: defaultAssistant });
      }
    );
  }

  private async getWorkspaceAssistantData(): Promise<AssistantChatData> {
    const workspace = await firstValueFrom(this.workspaceService.current$);
    const icon = this.workspaceService.getLogo();
    return {
      id: undefined,
      name: workspace?.name,
      icon: { type: 'img', value: icon },
      description: 'Chat and ask questions to uncover everything you want to know from your company’s knowledge base',
      isDefault: true,
      state: 'enabled',
    };
  }

  getCreatedByInfo(assistant: Experiences.ExperienceItem): { avatar: AvatarItemModel; description: string } {
    const avatar = this.avatarListService.getAvatarById(assistant.createdBy, 'id');
    return {
      avatar,
      description: 'By',
    };
  }

  async goToChatPageWithHistory(answerItem: AnswerSearchItem, isGlobalAssistant?: boolean) {
    const { query, assistantId } = answerItem;
    const timestamp = Date.now();
    const answer: Chats.AssistantMessage = this.createMessageFromAnswer(answerItem, timestamp);
    const currentFilters: Filters.Values = this.filtersService.allFilters;
    const filtersToQuestion: Filters.Values = await this.filtersService.transformFiltersForDisplay(currentFilters);
    const question = {
      content: query,
      filters: filtersToQuestion,
      timestamp,
    };
    const chatId = generateId();
    const historyItem: Chats.ChatHistoryItem = {
      chatId,
      userMessage: question,
      assistantMessage: answer,
    };
    this.createSession(assistantId, chatId, Experiences.KnowledgeType.Internal).then(() => {
      this.createHistoryMessage(historyItem, true);
    });
    const tempChatSession: Chats.Chat = { id: chatId, assistantId, chatHistory: [historyItem] };
    const isLauncher = await this.hubService.getIsLauncher();
    if (isLauncher) {
      localStorage.setItem(this.TEMP_CHAT_SESSION_STORAGE_KEY, JSON.stringify(tempChatSession));
    } else {
      this.localTempChat = tempChatSession;
    }
    this.chatResourcesService.updateResourceInCache(answerItem.resources);
    this.openChat(assistantId, null, isGlobalAssistant, chatId);
  }

  async openChat(assistantId?: string, query?: string, isGlobalAssistant?: boolean, chatId?: string, state = {}) {
    this.forceStopChat$.next(true);
    let chatUrl = `/${CHAT_PAGE_PATH}`;
    const queryParams: string[] = [];
    if (this.isExtension) {
      chatUrl = `/${SIDE_PANEL_PAGE_PATH}${chatUrl}`;
    }
    if (assistantId) {
      chatUrl += `/${assistantId}`;
    }
    if (chatId) {
      chatUrl += `/${CHAT_PAGE_PATH_SESSION}/${chatId}`;
    }
    if (query) {
      queryParams.push(`${QUESTION_DRAFT_QUERY}=${query}`);
    }
    //HACK: Add a flag to the URL to indicate that the global assistant is in use.
    if (isGlobalAssistant) {
      queryParams.push(`${IS_GLOBAL_ASSISTANT}=${true}`);
    }
    const currentFilters = this.filtersService.allFilters;
    if (!isEmpty(currentFilters)) {
      const filtersUrl = this.filtersService.getFiltersAsUrlParams(currentFilters);
      queryParams.push(filtersUrl);
    }

    if (queryParams.length > 0) {
      chatUrl += `?${queryParams.join('&')}`;
    }

    const isLauncher = await this.hubService.getIsLauncher();
    if (this.isEmbed) {
      if (isLauncher) {
        return this.hubService.openStandardEmbed(chatUrl, true);
      }
      return this.routerService.navigateByUrl(chatUrl, { replaceUrl: true, state });
    }
    if (isLauncher) {
      return this.windowService.switchToStandard(chatUrl);
    }
    return this.routerService.navigateByUrl(chatUrl, { state });
  }

  async createSession(
    assistantId: string,
    chatId: string,
    assistantKnowledgeType: Experiences.KnowledgeType,
    ignoreChat?: boolean
  ): Promise<void> {
    this.chatSessionsPromise[chatId] = new ManualPromise();
    await this.service.createSession({ assistantId, newChatId: chatId, ignore: ignoreChat, assistantKnowledgeType });
    if (!this.chatSessionsPromise[chatId].status) {
      this.chatSessionsPromise[chatId].resolve(true);
    }
  }

  async createHistoryMessage(historyItem: Chats.ChatHistoryItem, isGenerateName?: boolean) {
    await this.getChatSessionPromise(historyItem.chatId);
    this.service.createHistoryItem(historyItem, isGenerateName);
  }

  getChatSessionPromise(chatId: string): ManualPromise<boolean> {
    return this.chatSessionsPromise[chatId];
  }

  convertToAnswerResources(resources: Search.ResultResourceItem[]): Chats.MessageResource[] {
    const updatedResources: Chats.MessageResource[] = (resources || []).map((r) => ({
      appId: r.resource?.appId,
      externalId: r.resource?.externalId,
      resourceId: r.resource?.id,
      verificationStatus: r.resource?.data?.verificationStatus,
    }));
    return updatedResources;
  }

  private handleChatRoute() {
    this.routerService.activeRoute$
      .pipe(
        filter((currentRoute: ActivatedRoute) => {
          return !this.shouldResetChat(currentRoute);
        })
      )
      .subscribe(async (currentRoute) => {
        this.chatErrorState = null;
        const assistantId = currentRoute?.snapshot?.params?.id;
        const sessionId = currentRoute?.snapshot?.params?.sessionId;
        const state = this.routerService.getCurrentNavigation()?.extras?.state;
        const isNewSession = state?.isNewSession;
        const draftQuery = currentRoute?.snapshot?.queryParams?.[QUESTION_DRAFT_QUERY];
        const isGlobalAssistant = currentRoute?.snapshot?.queryParams?.[IS_GLOBAL_ASSISTANT];
        const chatData: CurrentChatData = await this.initChatData(assistantId, draftQuery);
        this.initChatPageSession(assistantId, sessionId, isNewSession);
        if ((chatData.assistant && chatData?.assistant.state === 'enabled') || sessionId) {
          this.updateCurrentChatData(chatData, isGlobalAssistant);
        } else {
          this.handleMissingAssistantData(assistantId, chatData, isGlobalAssistant);
        }
        const userMessage: Chats.UserMessage = state?.userMessage;
        this._userMessage$.next(userMessage);
      });
  }

  private async handleMissingAssistantData(assistantId: string, chatData: CurrentChatData, isGlobalAssistant?: boolean) {
    if (isGlobalAssistant) {
      // For global assistant: Allow using all general assistants, even if toggles are off.
      const foundAssistant = await this.experiencesService.getExperience(assistantId);
      if (foundAssistant && foundAssistant.experienceType === 'general') {
        this.updateChatDataWithFoundAssistant(chatData, foundAssistant, true);
      } else {
        this.chatErrorState = 'no-access';
      }
      return;
    }
    if (!chatData?.assistant || chatData.assistant.state === 'deleted') {
      this.chatErrorState = 'loading';
      this.getAssistantDataById(assistantId, chatData);
    } else if (chatData?.assistant && chatData.assistant.state === 'disabled') {
      this.chatErrorState = 'no-access';
      return;
    }
  }

  private async getAssistantDataById(assistantId: string, chatData: CurrentChatData) {
    const validAssistant = await this.getValidAssistant(assistantId, 'skip');
    this.chatErrorState = validAssistant.error;
    if (!this.chatErrorState) {
      this.updateChatDataWithFoundAssistant(chatData, validAssistant.assistant);
    }
  }

  async getValidAssistant(assistantId: string, cache: Experiences.CacheType): Promise<AssistantDetails> {
    if (!assistantId) {
      return;
    }
    try {
      const foundAssistant = await this.experiencesService.getExperience(assistantId, cache);
      if (foundAssistant) {
        const validDetails = this.chatAssistantsService.isValidAssistantForChat(foundAssistant, true);
        return { assistant: foundAssistant, error: isBoolean(validDetails) ? null : validDetails };
      }
      return { error: 'no-access' };
    } catch (error) {
      if (error.status === 404) {
        return { error: 'not-found' };
      }
      if (error.status === 403) {
        return { error: 'no-access' };
      }
    }
  }

  private updateChatDataWithFoundAssistant(
    chatData: CurrentChatData,
    foundAssistant: Experiences.ExperienceItem,
    isGlobalAssistant?: boolean
  ) {
    chatData.assistant = this.createAssistantChatData(foundAssistant);
    this.updateCurrentChatData(chatData, isGlobalAssistant);
  }

  private updateCurrentChatData(chatData: CurrentChatData, isGlobalAssistant?: boolean) {
    if (chatData) {
      const currentChat = this.currentChat;
      const assistantChanged =
        !currentChat ||
        currentChat.assistant?.id != chatData.assistant?.id ||
        currentChat.assistant?.name != chatData.assistant?.name ||
        chatData?.draftQuery;
      if (assistantChanged) {
        if (isGlobalAssistant) {
          chatData.assistant.state = 'enabled';
        }
        this.currentChat = { ...chatData, assistant: { ...chatData.assistant, isGlobalAssistant } };
      }
    }
  }

  private async initChatData(assistantId?: string, draftQuery?: string): Promise<CurrentChatData> {
    const chatData: CurrentChatData = {};
    if (draftQuery) {
      chatData.draftQuery = draftQuery;
      await this.routerService.removeQueryParam([QUESTION_DRAFT_QUERY], true);
    }
    const assistants = await firstValueFrom(this.assistantsForChat$);
    chatData.assistant = assistants?.[assistantId || undefined];

    if (!chatData.assistant) {
      const allAssistants = await firstValueFrom(this.experiencesService.all$);
      const hideAssistant = allAssistants?.find((assistant) => assistant.id === assistantId);
      if (hideAssistant) {
        chatData.assistant = this.createAssistantChatData(hideAssistant);
      } else {
        chatData.assistant = { id: undefined, state: 'deleted' };
      }
    }
    return chatData;
  }

  private async initChatPageSession(assistantId: string, sessionId: string, isNewSession: boolean) {
    this.tempChatSession = this.getTempChatSession(assistantId);
    if (this.tempChatSession?.id !== sessionId && sessionId) {
      const _sessions = await firstValueFrom(this._sessions$);
      const currentSession = _sessions?.find((session) => session.id === sessionId);
      if ((!currentSession || currentSession?.assistantId != assistantId) && !isNewSession) {
        this.routerService.navigate([CHAT_PAGE_PATH, assistantId].filter((p) => p));
      }
    }
    this._currentSessionId$.next(sessionId || NEW_CHAT_ID);
  }

  getChatHistory$(chatId: string, pageToken: string): Observable<Chats.GetChatHistoryResponse> {
    if (this.tempChatSession?.id === chatId) {
      const { chatHistory } = this.tempChatSession;
      this.tempChatSession = null;
      return of({ items: chatHistory });
    }
    return this.service.getChatHistory$({ chatId, pageToken });
  }

  private getTempChatSession(assistantId?: string) {
    if (this.localTempChat && this.localTempChat.assistantId == assistantId) {
      const localTempChat = this.localTempChat;
      this.localTempChat = null;
      return localTempChat;
    }
    const tempChatFromStorage = this.extractSessionFromStorage();
    if (!tempChatFromStorage || tempChatFromStorage.assistantId != assistantId) {
      return;
    }
    localStorage.removeItem(this.TEMP_CHAT_SESSION_STORAGE_KEY);
    return tempChatFromStorage;
  }

  private extractSessionFromStorage() {
    try {
      const storage = localStorage.getItem(this.TEMP_CHAT_SESSION_STORAGE_KEY);
      if (!storage) {
        return;
      }
      return JSON.parse(storage) as Chats.Chat;
    } catch (error) {
      return;
    }
  }

  private shouldResetChat(currentRoute: ActivatedRoute): boolean {
    const currentPage = currentRoute?.snapshot?.data.id;
    const resetChat = currentPage !== CHAT_PAGE_PATH;
    if (resetChat) {
      this.currentChat = null;
      this.localTempChat = null;
      this.chatErrorState = null;
    }
    return resetChat;
  }

  private createMessageFromAnswer(answerItem: AnswerSearchItem, timestamp: number): Chats.AssistantMessage {
    const { state, text, searchId, resources, intent } = answerItem;
    if (state === 'NoResults') {
      return { state: Chats.AssistantMessageState.NoResultsFound, timestamp, intent };
    }
    const updatedResources = this.convertToAnswerResources(resources);
    const answer: Chats.AssistantMessage = {
      content: text,
      state: state === 'RephraseRequired' ? Chats.AssistantMessageState.RephraseRequired : Chats.AssistantMessageState.ResultsAvailable,
      resources: updatedResources,
      searchId,
      timestamp,
      references: answerItem.references,
    };
    if (intent) {
      answer.intent = intent;
    }
    return answer;
  }

  deleteSession(sessionId: string) {
    this.service.deleteSession(sessionId);
  }

  async updateSession(request: Chats.UpdateChatRequest) {
    return this.service.update(request);
  }

  updateAnswersResources(chatHistory: Chats.ChatHistoryItem[]) {
    const externalResources = (chatHistory || [])
      .map((h) => h?.assistantMessage?.resources?.map((r) => r?.externalId) || [])
      .filter((r) => !!r)
      .flat();
    this.chatResourcesService.updateRemoteResources(externalResources);
  }

  handleLeftChat(
    chatId: string,
    assistantData: AssistantChatData,
    currentMessageState,
    chatHistoryData: Chats.ChatHistoryItem[],
    draftQuestion: Chats.UserMessage,
    isIgnoreChat: boolean
  ): Chats.ChatHistoryItem {
    if (isIgnoreChat) {
      this.updateSession({ chatId: chatId, cancelIgnore: true });
    }
    const isCancelledWithResult = ['interactiveTyping', 'staticTyping'].includes(currentMessageState);
    const newChatHistoryItem = isCancelledWithResult
      ? this.getQueryCancelledWithResult(chatHistoryData, draftQuestion, chatId, assistantData)
      : this.getQueryCancelled(draftQuestion, chatId, assistantData);

    if (newChatHistoryItem) {
      newChatHistoryItem.chatId = chatId;
      delete newChatHistoryItem.assistantMessage.resourceIds;
      this.createHistoryMessage(newChatHistoryItem, isIgnoreChat || (isCancelledWithResult && chatHistoryData?.length === 1));
    }
    return isCancelledWithResult ? null : newChatHistoryItem;
  }

  private getQueryCancelledWithResult(
    chatHistoryData: Chats.ChatHistoryItem[],
    draftQuestion: Chats.UserMessage,
    chatId: string,
    assistantData: AssistantChatData
  ): Chats.ChatHistoryItem {
    if (!chatHistoryData || chatHistoryData.length === 0) {
      return this.getQueryCancelled(draftQuestion, chatId, assistantData);
    }
    const lastIndex = chatHistoryData.length - 1;
    const lastHistoryItem = chatHistoryData[lastIndex];

    if (lastHistoryItem?.assistantMessage) {
      lastHistoryItem.assistantMessage.state = Chats.AssistantMessageState.QueryCancelledWithResult;
    }
    return lastHistoryItem;
  }

  private getQueryCancelled(draftQuestion: Chats.UserMessage, chatId: string, assistantData: AssistantChatData): Chats.ChatHistoryItem {
    const cancelHistoryItem: Chats.ChatHistoryItem = {
      userMessage: {
        content: draftQuestion.content,
        filters: draftQuestion.filters,
        timestamp: draftQuestion.timestamp,
        blobIds: draftQuestion.blobIds,
        action: draftQuestion.action,
      },
      assistantMessage: { state: Chats.AssistantMessageState.QueryCancelled, timestamp: Date.now() },
      chatId,
      modelId: assistantData?.model,
    };
    return cancelHistoryItem;
  }

  createNewSession(assistantId: string, assistantKnowledgeType: Experiences.KnowledgeType, ignoreChat?: boolean, newChatId?: string) {
    const chatId = newChatId ?? generateId();
    this.createSession(assistantId, chatId, assistantKnowledgeType, ignoreChat);
    return chatId;
  }
}
