<u-emoji
  class="card-icon"
  *ngIf="model()?.emoji || model()?.settings?.emoji; else defaultIcon"
  [spriteEmojiSize]="spriteEmojiSize"
  [unicode]="model().emoji || model()?.settings?.emoji"
></u-emoji>
<ng-template #defaultIcon
  ><u-icon
    class="card-icon"
    [ngClass]="{ 'icon-with-border': displayBorder }"
    [styles]="iconStyles"
    [model]="chatDefaultIcon() || model()?.icon || DEFAULT_ASSISTANT_ICON"
    [inSvg]="chatDefaultIcon()?.value?.lightUrl"
  ></u-icon>
  <u-icon
    *ngIf="model()?.iconOverlay"
    class="card-icon-overlay"
    [ngClass]="{ background: model().experienceType === 'zendesk' }"
    [model]="model().iconOverlay"
  ></u-icon>
</ng-template>
