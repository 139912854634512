import { Route } from '@angular/router';
import { Constants } from '@local/common';
import { AssistantErrorComponent } from '@shared/components/assistant-error/assistant-error.component';
import { PageNotFoundComponent } from '@shared/components/page-not-found/page-not-found.component';
import { CHAT_PAGE_PATH, CHAT_PAGE_PATH_SESSION } from '../utils/constants';
import { AssistantIncontextComponent } from '../views/assistant-incontext/components/assistant-incontext/assistant-incontext.component';
import { UpNextComponent } from '../views/calendar-page/up-next/up-next.component';
import { ChatPageComponent } from '../views/chat-page/components/chat-page/chat-page.component';
import { SidebarPinResolver } from '../views/chat-page/resolvers/sidebar-pin.resolver';
import { CollectionViewComponent } from '../views/collections-page/components/collection-view/collection-view.component';
import { CollectionsContainerComponent } from '../views/collections-page/components/collections-container/collections-container.component';
import { WikiCardPopupComponent } from '../views/collections-page/components/wiki-card-popup/wiki-card-popup.component';
import { CollectionResolver } from '../views/collections-page/services/collection.resolver';
import { HomePageComponent } from '../views/home-page/home-page.component';
import { HubComponent } from '../views/hub';
import { PreviewHubComponent } from '../views/preview/preview-hub/preview-hub.component';
import { GoLinkRedirectComponent } from '../views/results-views/go-links/go-links-redirector.component';
import { QuestionnaireComponent } from '../views/results-views/questionnaire/questionnaire.component';
import { ResultsComponent } from '../views/results-views/results/results.component';
import { ResourceRedirectorComponent } from '../views/results/components/resource-redirector/resource-redirector.component';
import { HomeSidePanelComponent } from '../views/side-panel/components/home-side-panel/home-side-panel.component';
import { SearchSidePanelComponent } from '../views/side-panel/components/search-side-panel/search-side-panel.component';
import { SidePanelComponent } from '../views/side-panel/components/side-panel/side-panel.component';
import { ErrorPageComponent } from '../views/special-views/error/error-page.component';
import { WebSearchComponent } from '../views/web-search/web-search.component';
import { ErrorGuard } from './error.route-guard';
import { CanActivateChatGuard } from './guards/can-activate-chat.guard';
import { CanActivateCollectionsGuard } from './guards/can-activate-collection.guard';
import { CanActivateGoLinksGuard } from './guards/can-activate-go-link.guard';
import { SaveFiltersGuard } from './guards/can-activate-save-filters.guard';
import { SearchGuard } from './guards/can-activate-search.guard';
import { CanActivateWikiGuard } from './guards/can-activate-wiki.guard';
import { CanDeactivateCollectionGuard } from './guards/can-deactivate-collection.guard';
import { HomeGuard } from './guards/home.guard';
import { NavTreeNodeIdResolver } from './nav-tree-node-id.resolver';
import { NavTreeNodeTitleResolver } from './nav-tree-node-title.resolver';
import { navTreeNodeMatcher } from './nav-tree-node.matcher';
import { NavTreeNodeResolver } from './nav-tree-node.resolver';
import { RouteData } from './route-data';
import { WebSearchResolver } from './web-search.resolver';
export interface MainRoute extends Route {
  data?: RouteData;
  children?: MainRoute[];
}

export const chatRouterChildren: MainRoute[] = [
  {
    path: ':id',
    component: ChatPageComponent,
    resolve: { isPinned: SidebarPinResolver },
    data: {
      title: 'Chat',
      id: CHAT_PAGE_PATH,
      componentName: 'ChatPageComponent',
      icon: { type: 'font-icon', value: 'icon-chat-dots' },
    },
  },
  {
    path: `:id/${CHAT_PAGE_PATH_SESSION}/:sessionId`,
    component: ChatPageComponent,
    resolve: { isPinned: SidebarPinResolver },
    data: {
      title: 'Chat',
      id: CHAT_PAGE_PATH,
      componentName: 'ChatPageComponent',
      icon: { type: 'font-icon', value: 'icon-chat-dots' },
    },
  },
  {
    path: `${CHAT_PAGE_PATH_SESSION}/:sessionId`,
    component: ChatPageComponent,
    resolve: { isPinned: SidebarPinResolver },
    data: {
      title: 'Chat',
      id: CHAT_PAGE_PATH,
      componentName: 'ChatPageComponent',
      icon: { type: 'font-icon', value: 'icon-chat-dots' },
    },
  },
];

export const routes: MainRoute[] = [
  {
    matcher: (routes) => {
      if (routes[0]?.path == 'go') {
        return { consumed: routes };
      }
      return <any>null;
    },
    component: GoLinkRedirectComponent,
  },
  {
    path: 'window/questionnaire/:processId',
    component: QuestionnaireComponent,
    data: {
      windowMode: true,
      node: {
        id: 'window',
        type: 'root',
        title: 'window',
        data: {
          location: 'aux.search',
        },
      },
    },
  },
  {
    path: 'window/questionnaire',
    component: QuestionnaireComponent,
    data: {
      windowMode: true,
      node: {
        id: 'window',
        type: 'root',
        title: 'window',
        data: {
          location: 'aux.search',
        },
      },
    },
  },
  {
    path: 'window/:param/:id',
    component: PreviewHubComponent,
    data: {
      windowMode: true,
      node: {
        id: 'window',
        type: 'root',
        title: 'window',
        data: {
          location: 'aux.search',
        },
      },
    },
  },
  {
    path: 'window/:param/:id/:link/:resource',
    component: PreviewHubComponent,
    data: {
      windowMode: true,
      node: {
        id: 'window',
        type: 'root',
        title: 'window',
        data: {
          location: 'aux.search',
        },
      },
    },
  },
  {
    path: 'assistant-incontext',
    component: AssistantIncontextComponent,
  },
  {
    path: 'assistant-error',
    component: AssistantErrorComponent,
  },
  {
    path: 'side-panel',
    component: SidePanelComponent,
    children: [
      {
        path: '',
        redirectTo: 'chat',
        pathMatch: 'full',
      },
      {
        path: 'chat',
        component: ChatPageComponent,
        data: {
          title: 'Chat',
          id: CHAT_PAGE_PATH,
          componentName: 'ChatPageComponent',
          icon: { type: 'font-icon', value: 'icon-chat-dots' },
        },
        children: chatRouterChildren,
        resolve: { isPinned: SidebarPinResolver },
      },
      { path: 'home', component: HomeSidePanelComponent },
      { path: 'search', canActivate: [SaveFiltersGuard], component: SearchSidePanelComponent },
    ],
  },
  {
    path: 'admin',
    resolve: { node: NavTreeNodeResolver },
    loadChildren: () => import('../views/settings/admin.module').then((m) => m.AdminModule),
    data: {
      id: 'admin',
      title: 'Admin Center',
      clickTrack: 'hub_side_menu.my-profile',
      icon: { type: 'font-icon', value: 'icon-cog2' },
      preload: true,
    },
  },
  {
    path: '',
    component: HubComponent,
    // Bar Component is taking care of the redirection immediately to the hub, since angular router doesn't handle it well
    // (BC of the multiple router outlets)

    children: [
      {
        path: '',
        component: HomePageComponent,
        canActivate: [HomeGuard],
        resolve: { node: NavTreeNodeResolver },
        data: {
          title: 'Home',
          id: 'home',
          componentName: 'HomePageComponent',
          clickTrack: 'hub_side_menu.home',
          icon: { type: 'font-icon', value: 'icon-home' },
          disableLoaderRemoval: true,
        },
      },
      {
        path: 'search',
        component: ResultsComponent,
        canActivate: [SearchGuard, SaveFiltersGuard],
        resolve: { node: NavTreeNodeResolver },
        data: {
          title: 'Search',
          id: 'search',
          clickTrack: 'hub_side_menu.search',
          componentName: 'ResultsComponent',
          icon: { type: 'font-icon', value: 'icon-search-icon-fixed' },
        },
      },
      {
        path: 'calendar',
        component: UpNextComponent,
        resolve: { node: NavTreeNodeResolver },
        data: {
          title: 'Calendar',
          componentName: 'UpNextComponent',
          clickTrack: 'hub_side_menu.calendar',
          icon: {
            type: 'font-icon',
            value: 'icon-calendar-empty font-icon',
          },
          id: 'calendar',
        },
      },
      {
        path: 'analytics',
        redirectTo: '/admin/analytics',
      },
      {
        path: 'connect/:appId/new',
        redirectTo: '/admin/sources/:appId/new',
      },
      {
        path: 'connect/:appId',
        redirectTo: '/admin/sources/:appId',
      },
      {
        path: 'connect',
        redirectTo: '/admin/sources',
      },
      {
        path: 'settings',
        redirectTo: 'admin',
      },
      {
        path: 'card/:id',
        component: WikiCardPopupComponent,
        canActivate: [CanActivateWikiGuard],
        data: {
          componentName: 'CollectionViewComponent',
          clickTrack: 'hub_side_menu.collection_view',
          fullPage: true,
          disableWorkspaceFeatureFlag: Constants.DISABLED_WIKIS_WORKSPACE_FEATURE_FLAG,
        },
      },
      {
        path: 'wikis',
        component: CollectionsContainerComponent,
        canActivate: [CanActivateWikiGuard],
        resolve: { node: NavTreeNodeResolver },
        data: {
          title: 'Wikis',
          id: 'wikis',
          componentName: 'CollectionsContainerComponent',
          clickTrack: 'hub_side_menu.wikis',
          icon: { type: 'font-icon', value: 'icon-card' },
          disableWorkspaceFeatureFlag: Constants.DISABLED_WIKIS_WORKSPACE_FEATURE_FLAG,
        },
      },
      {
        path: 'collections',
        component: CollectionsContainerComponent,
        canActivate: [CanActivateCollectionsGuard],
        resolve: { node: NavTreeNodeResolver },
        data: {
          title: 'Collections',
          id: 'collections',
          componentName: 'CollectionsContainerComponent',
          clickTrack: 'hub_side_menu.collections',
          icon: { type: 'font-icon', value: 'icon-collection' },
          disableWorkspaceFeatureFlag: Constants.DISABLED_COLLECTIONS_WORKSPACE_FEATURE_FLAG,
        },
      },
      {
        path: 'c/:id',
        component: CollectionViewComponent,
        canDeactivate: [CanDeactivateCollectionGuard],
        canActivate: [CanActivateCollectionsGuard],
        resolve: { collection: CollectionResolver },
        data: {
          componentName: 'CollectionViewComponent',
          clickTrack: 'hub_side_menu.collection_view',
          icon: { type: 'font-icon', value: 'icon-collection' },
          disableWorkspaceFeatureFlag: Constants.DISABLED_COLLECTIONS_WORKSPACE_FEATURE_FLAG,
        },
      },
      {
        path: 'wikis/:id',
        component: CollectionViewComponent,
        canDeactivate: [CanDeactivateCollectionGuard],
        canActivate: [CanActivateWikiGuard],
        resolve: { collection: CollectionResolver },
        data: {
          componentName: 'CollectionViewComponent',
          clickTrack: 'hub_side_menu.collection_view',
          icon: { type: 'font-icon', value: 'icon-collection' },
          disableWorkspaceFeatureFlag: Constants.DISABLED_WIKIS_WORKSPACE_FEATURE_FLAG,
        },
      },
      {
        path: 'assistants',
        redirectTo: '/admin/assistants',
      },
      {
        path: 'assistant/:new/:title',
        redirectTo: '/admin/assistants/:new/:title',
      },
      {
        path: 'assistant/:id',
        redirectTo: '/admin/assistant/:id',
      },
      {
        path: 'assistants/add-to/:id',
        redirectTo: '/admin/assistants/add-to/:id',
      },
      { path: 'web-search/:engine', component: WebSearchComponent, resolve: { engine: WebSearchResolver }, data: { id: 'web-search' } },
      {
        path: CHAT_PAGE_PATH,
        component: ChatPageComponent,
        canActivate: [CanActivateChatGuard],
        data: {
          title: 'Chat',
          id: CHAT_PAGE_PATH,
          componentName: 'ChatPageComponent',
          icon: { type: 'font-icon', value: 'icon-chat-dots' },
        },
        children: chatRouterChildren,
        resolve: { node: NavTreeNodeResolver },
      },
      {
        path: 'resource/:id',
        component: ResourceRedirectorComponent,
      },
      {
        path: 'resource/:id/:link',
        component: ResourceRedirectorComponent,
      },
      {
        path: ':param/:id',
        component: PreviewHubComponent,
        resolve: { node: NavTreeNodeResolver },
      },
      {
        path: ':param/:id/:link/:resource',
        component: PreviewHubComponent,
        resolve: { node: NavTreeNodeResolver },
      },
      {
        path: 'error',
        component: ErrorPageComponent,
        resolve: { node: NavTreeNodeResolver },
        canActivate: [ErrorGuard],
        data: { title: 'Error', id: 'error' },
      },
      {
        path: 'not-found',
        component: PageNotFoundComponent,
      },
      {
        matcher: navTreeNodeMatcher,
        component: ResultsComponent,
        canActivate: [CanActivateGoLinksGuard],
        resolve: { node: NavTreeNodeResolver, id: NavTreeNodeIdResolver, title: NavTreeNodeTitleResolver },
        data: { componentName: 'ResultsComponent' },
      },
    ],
  },
];
