<div class="home-search-popup" [style.height.px]="popupHeight" [ngClass]="{ 'home-search-popup-empty': emptySearch }">
  @if (popupData.browseBarModel) {
    <browse-bar
      #browseBar
      *ngIf="!popupData.search"
      id="browse-bar"
      [model]="popupData.browseBarModel"
      [componentFocused]="false"
      (componentFocusedFinish)="componentFocusedFinish()"
      [focus]="focusOnFilters"
    >
    </browse-bar>
    <apps-empty-state
      *ngIf="popupData?.browseBarModel?.items?.length === 0 && popupData?.search && popupData?.searchData?.searchCompleted"
      [view]="'Banner'"
    ></apps-empty-state>
  }
  <smooth-height [trigger]="popupData.search">
    <div data-cy="home-search-clear-btn" class="clear-search-button" (click)="onClearSearchClick()"></div>
    <section class="search-container" [style.height.px]="scrollHeight">
      <ng-container #fullData>
        <ng-scrollbar visibility="hover" [ngClass]="{ minScrollbarHeight: minScrollbarHeight }">
          <cdk-virtual-scroll-viewport [itemSize]="40" ngScrollbarView smoothScroll scrollViewport>
            <ng-container *ngFor="let item of items; index as i">
              <ng-container [ngSwitch]="item.type">
                <ng-container *ngSwitchCase="'header'">
                  <div class="divider" *ngIf="(i !== 0 || !popupData.search) && !item.isFooter"></div>
                  <result-header
                    #resultItem
                    [model]="item"
                    [index]="i"
                    [selected]="selectedIndex === i && item.selectable"
                    [footer]="item.isFooter"
                    [attr.data-index]="i"
                    [focusedGroup]="focusedGroup"
                    [showRightButton]="item.showButton && showHeaderButton"
                    (focusGroupClick)="focusGroupClick()"
                    (mouseover)="onHoverItem(i)"
                    (buttonClicked)="clearRecentSearches()"
                    [ngClass]="{ 'non-footer': !item.isFooter, selectable: item.selectable }"
                    class="home-search-item"
                  ></result-header>
                </ng-container>
                <ng-container *ngSwitchCase="'recent-search'">
                  <recent-search-item
                    #resultItem
                    [model]="item"
                    class="result-item block-item home-search-item default-search-item"
                    [index]="i"
                    [keyboardOn]="keyboardOn"
                    [selected]="selectedIndex === i"
                    (clicked)="recentSearchClicked($event)"
                    (click)="selectedIndex = i"
                    (mouseover)="onHoverItem(i)"
                    [attr.data-index]="i"
                  ></recent-search-item>
                </ng-container>
                <ng-container *ngSwitchCase="'go-link'">
                  <go-links-view-item
                    #resultItem
                    class="home-search-item"
                    [class.disabled]="item.deleted"
                    [class.selected]="selectedIndex === i"
                    [sessionId]=""
                    [clientSearchId]=""
                    [selected]="selectedIndex === i"
                    [model]="item"
                    [showResultSections]="{
                      showSubtitle: false,
                      showResultBadge: true,
                      showResultSections: true,
                      disableRightClick: true,
                      showSingleLineView: isExtensionMode ? false : undefined,
                      showSubtitleInSecondLine: isExtensionMode ? true : undefined,
                    }"
                    tabindex="-1"
                    [index]="i"
                    [resultsIndex]="i"
                    (invoke)="selectedIndex = i; onInvoke($event, 'go-link')"
                    (click)="updateSelectedIndex(i)"
                    [attr.data-index]="i"
                    [query]="this.popupData.search"
                    listName="search-popup"
                    (contextOpen)="updateSelectedIndex(i)"
                    (mouseover)="onHoverItem(i)"
                  ></go-links-view-item>
                </ng-container>
                <ng-container *ngSwitchCase="'result'">
                  <ng-container *ngIf="item.action?.type === 'people'; else result">
                    <ng-container *ngTemplateOutlet="peopleItem; context: { item: item, i: i }"></ng-container>
                  </ng-container>
                  <ng-template #result>
                    <result-item
                      #resultItem
                      [class.keyboardOn]="keyboardOn"
                      [class.selected]="selectedIndex === i"
                      [class.results-item-with-snippet]="item.snippet"
                      [sessionId]=""
                      class="result-item home-search-item"
                      [clientSearchId]=""
                      [selected]="selectedIndex === i"
                      [index]="i"
                      [resultsIndex]="item.resultIndex"
                      (invoke)="updateSelectedIndex(i); onInvoke($event, 'result')"
                      (click)="updateSelectedIndex(i); onPreviewClick(item, 'mouse_click')"
                      [attr.data-index]="i"
                      listName="search-popup"
                      [model]="item"
                      (contextOpen)="updateSelectedIndex(i)"
                      (mouseover)="onHoverItem(i)"
                      [showResultSections]="{
                        showBullets: false,
                        showContextMenu: false,
                        showTags: false,
                        enableCommandBar: false,
                        showSnippet: true,
                        showSubView: false,
                        shortSnippet: true,
                        showAnnotation: false,
                        trimSubtitleLength: false,
                        disableRightClick: true,
                        showSingleLineView: isExtensionMode ? false : undefined,
                        showSubtitleInSecondLine: isExtensionMode ? true : undefined,
                      }"
                    ></result-item
                  ></ng-template>
                </ng-container>
                <ng-container *ngSwitchCase="'local-action'">
                  <local-actions-item
                    #resultItem
                    [class.keyboardOn]="keyboardOn"
                    [class.selected]="selectedIndex === i"
                    [selected]="selectedIndex === i"
                    [model]="item"
                    [index]="i"
                    [attr.data-index]="i"
                    [isOneLine]="true"
                    class="result-item home-search-item"
                    (mouseover)="onHoverItem(i)"
                    (click)="onActionItemClick(i, 'mouse_click')"
                    (invoke)="onActionItemClick(i, 'mouse_click')"
                  ></local-actions-item>
                </ng-container>
                <ng-container *ngSwitchCase="'browser-tab'">
                  <browser-tab-item
                    #resultItem
                    class="result-item home-search-item"
                    [class.keyboardOn]="keyboardOn"
                    [class.selected]="selectedIndex === i"
                    (click)="updateSelectedIndex(i); onTabClick(i, 'mouse_click')"
                    (mouseover)="onHoverItem(i)"
                    [model]="item"
                    [selected]="selectedIndex === i"
                    [attr.data-index]="i"
                  >
                  </browser-tab-item>
                </ng-container>
                <ng-container *ngSwitchCase="'visit'">
                  <visit-item
                    #resultItem
                    class="result-item home-search-item"
                    [class.keyboardOn]="keyboardOn"
                    [class.selected]="selectedIndex === i"
                    (click)="updateSelectedIndex(i)"
                    (mouseover)="onHoverItem(i)"
                    [model]="item"
                    [selected]="selectedIndex === i"
                    [attr.data-index]="i"
                    [query]="this.popupData.search"
                  >
                  </visit-item>
                </ng-container>
                <ng-container *ngSwitchCase="'browser-bookmark'">
                  <browser-bookmark-item
                    #resultItem
                    class="result-item home-search-item"
                    [class.keyboardOn]="keyboardOn"
                    [class.selected]="selectedIndex === i"
                    (click)="updateSelectedIndex(i); onBookmarkClick(i, 'mouse_click')"
                    (mouseover)="onHoverItem(i)"
                    [model]="item"
                    [selected]="selectedIndex === i"
                    [attr.data-index]="i"
                  >
                  </browser-bookmark-item>
                </ng-container>
                <ng-container *ngSwitchCase="'query'">
                  <query-item
                    #resultItem
                    class="result-item home-search-item"
                    [class.keyboardOn]="keyboardOn"
                    [class.selected]="selectedIndex === i"
                    (click)="updateSelectedIndex(i); onQueryClick(i)"
                    (mouseover)="onHoverItem(i)"
                    [model]="item"
                    [selected]="selectedIndex === i"
                    [attr.data-index]="i"
                    [query]="this.popupData.search"
                  >
                  </query-item>
                </ng-container>
                <ng-container *ngSwitchCase="'create-go-link'">
                  <div (click)="onOpenPopup.emit()">
                    <create-go-link-item
                      #resultItem
                      [class.keyboardOn]="keyboardOn"
                      [class.selected]="selectedIndex === i"
                      [selected]="selectedIndex === i"
                      [model]="item"
                      [index]="i"
                      [attr.data-index]="i"
                      class="result-item"
                      (mouseover)="onHoverItem(i)"
                      (click)="onCreateGoLinkItemClick(i, 'mouse_click')"
                      (invoke)="onCreateGoLinkItemClick(i, 'mouse_click')"
                    ></create-go-link-item>
                  </div>
                </ng-container>
                <ng-container *ngSwitchCase="'goto'">
                  <go-to-item
                    #resultItem
                    class="result-item home-search-item"
                    [class.keyboardOn]="keyboardOn"
                    [class.selected]="selectedIndex === i"
                    (click)="updateSelectedIndex(i); onGoToItemClick(item)"
                    (mouseover)="onHoverItem(i)"
                    [model]="item"
                    [selected]="selectedIndex === i"
                    [attr.data-index]="i"
                  >
                  </go-to-item>
                </ng-container>
                <ng-container *ngSwitchCase="'calculator'">
                  <calc-result-item
                    #resultItem
                    [class.selected]="selectedIndex === i"
                    [class.keyboardOn]="keyboardOn"
                    (mouseover)="onHoverItem(i)"
                    [selected]="selectedIndex === i"
                    (disclaimerClick)="onDisclaimerClick($event)"
                    (click)="updateSelectedIndex(i); onCalcItemClick(i)"
                    [model]="item"
                    class="result-item home-search-item"
                    [attr.data-index]="i"
                    [index]="i"
                  ></calc-result-item>
                </ng-container>
                <ng-container *ngSwitchCase="'web-search'">
                  <web-search-results-item
                    [class.selected]="selectedIndex === i"
                    [selected]="selectedIndex === i"
                    [class.keyboardOn]="keyboardOn"
                    class="result-item home-search-item"
                    (click)="updateSelectedIndex(i); onWebSearchItemClick(item)"
                    [model]="item"
                    [firstResult]="i === 0 || isFirstWebSearchResult(i)"
                    [attr.data-index]="i"
                    (mouseover)="onHoverItem(i)"
                  >
                  </web-search-results-item>
                </ng-container>
                <ng-container *ngSwitchCase="'suggestions'">
                  <suggestions-item
                    #resultItem
                    [class.selected]="selectedIndex === i"
                    [class.keyboardOn]="keyboardOn"
                    (click)="selectedIndex = i; onSuggestionItemClicked(item)"
                    [model]="item"
                    (mouseover)="onHoverItem(i)"
                    (mouseout)="focusedGroup = null"
                    class="result-item default-search-item"
                    [attr.data-index]="i"
                  >
                  </suggestions-item>
                </ng-container>
                <ng-container *ngSwitchCase="'person'">
                  <ng-container *ngTemplateOutlet="peopleItem; context: { item: item, i: i }"></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'collection'">
                  <collection-item
                    (onClickEvent)="openCollectionItem()"
                    class="result-item home-search-item"
                    [class.selected]="selectedIndex === i"
                    [attr.data-index]="i"
                    [collection]="item"
                    [selected]="i === selectedIndex"
                    [viewMode]="'search-result'"
                    (mouseover)="onHoverItem(i)"
                    [showResultSections]="{
                      showBullets: false,
                      showContextMenu: false,
                      showFavoriteIcon: true,
                      disableRightClick: true,
                      showSingleLineView: isExtensionMode ? false : undefined,
                      showSubtitleInSecondLine: isExtensionMode ? true : undefined,
                    }"
                  >
                  </collection-item>
                </ng-container>
                <ng-container *ngSwitchCase="'static-search-item'">
                  <static-search-item
                    #resultItem
                    [attr.data-index]="i"
                    [model]="item"
                    [class.selected]="selectedIndex === i"
                    (onInvoke)="onStaticItemInvoke($event, item)"
                    class="result-item home-search-item"
                    (mouseover)="onHoverItem(i)"
                    [ngStyle]="i === 0 ? { marginTop: '8px' } : {}"
                  ></static-search-item>
                </ng-container>
                <ng-container *ngSwitchCase="'assistant'">
                  <assistant-item
                    class="result-item"
                    [attr.data-index]="i"
                    [assistantItem]="item"
                    (mouseover)="onHoverItem(i)"
                    [showResultSections]="{
                      showBullets: false,
                      showContextMenu: false,
                      disableRightClick: true,
                      showSingleLineView: true,
                    }"
                  >
                  </assistant-item>
                </ng-container>
              </ng-container>
            </ng-container>
            <div class="ghost-container" *ngIf="!popupData?.searchData?.searchCompleted" [ngClass]="{ 'no-results': !items?.length }">
              <div class="line first title"></div>
              <div *ngFor="let i of 2 | fill">
                <div class="ghost first">
                  <div class="square"></div>
                  <div class="line first"></div>
                  <div class="line second"></div>
                </div>
                <div class="ghost second">
                  <div class="square"></div>
                  <div class="line"></div>
                </div>
              </div>
            </div>
          </cdk-virtual-scroll-viewport>
        </ng-scrollbar>
      </ng-container>
    </section>
  </smooth-height>

  <div
    *ngIf="!!popupData.search && !isOnlyGoLinksSearch"
    class="search-line result-item"
    [ngClass]="{ 'search-line-empty': emptySearch }"
    [class.keyboardOn]="keyboardOn"
    (click)="onSearch()"
    (mouseover)="selectedIndex = items.length"
    (mouseout)="focusedGroup = null"
  >
    <div class="base" [ngClass]="{ selected: emptySearch || selectedIndex === items?.length }">
      <u-icon class="search-line-icon" [model]="{ type: 'font', value: 'icon-magnifier' }"></u-icon>
      <div class="search-line-text">
        Search for “<strong>{{ popupData.search }}</strong
        >”
      </div>
    </div>
  </div>
</div>

<ng-template #peopleItem let-item="item" let-i="i">
  <people-view-item
    class="home-search-item"
    #resultItem
    [class.selected]="selectedIndex === i"
    [selected]="selectedIndex === i"
    [model]="item"
    [index]="i"
    [resultsIndex]="i"
    (invoke)="updateSelectedIndex(i); onInvoke($event, 'result')"
    (click)="updateSelectedIndex(i)"
    (mouseover)="onHoverItem(i)"
    (mouseout)="focusedGroup = null"
    [attr.data-index]="i"
    [query]="this.popupData.search"
    [listName]="'search-popup'"
    [displayOneLine]="true"
    [displayOneCenterLine]="true"
    [showResultSections]="{
      disableRightClick: true,
      showSingleLineView: isExtensionMode ? false : undefined,
      showSubtitleInSecondLine: isExtensionMode ? true : undefined,
    }"
  ></people-view-item>
</ng-template>
