<p-chips
  [style]="style"
  [(ngModel)]="items"
  [separator]="separator"
  [styleClass]="styleClass"
  [showClear]="showClearBtn"
  (onAdd)="onAdd()"
  (onClear)="onClear()"
  (ngModelChange)="modelChanged($event)"
  (onRemove)="onRemove($event.value)"
  [max]="maxTags"
  [allowDuplicate]="false"
  [placeholder]="placeholder"
  [disabled]="disabled"
>
  <ng-template #chipsItem let-item pTemplate="item">
    <span
      tooltipPosition="top"
      [pTooltip]="!item.isValid ? 'Invalid email' : ''"
      tooltipStyleClass="u-tooltip"
      [class.invalid-mail]="!item.isValid"
      >{{ item.value }}</span
    >
    <span class="pi pi-times" (click)="onRemove(item)"></span>
  </ng-template>
</p-chips>

<div *ngIf="errorMessage" [style]="{ width: style.width }" class="error-message">
  <ng-container *ngTemplateOutlet="exclamation"></ng-container>
  <div class="error-message-text">{{ errorMessage }}</div>
</div>

<ng-template #exclamation>
  <div class="exclamation-container">
    <div class="circle" [style]="{ width: '16px', height: '16px' }"></div>
    <div class="exclamation">!</div>
  </div>
</ng-template>
