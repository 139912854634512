import { ChangeDetectionStrategy, Component, effect, ElementRef, input, output, viewChild } from '@angular/core';
import { delay } from '@local/ts-infra';
import { UntilDestroy } from '@ngneat/until-destroy';

export type TypingViewMode = 'interactive' | 'static';

@UntilDestroy()
@Component({
  selector: 'u-typing-typography',
  templateUrl: './u-typing-typography.component.html',
  styleUrls: ['./u-typing-typography.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UTypingTypographyComponent {
  private readonly MAX_TYPING_DELAY = 20;
  private readonly MIN_TYPING_DELAY = 10;

  text = input<string>('');
  viewMode = input<TypingViewMode>('interactive');

  typingFinished = output();

  typingContainerRef = viewChild<ElementRef<HTMLElement>>('typingContainer');

  constructor() {
    effect(() => {
      if (this.viewMode() === 'interactive') {
        this.startTypingEffect();
      }
      if (this.viewMode() === 'static') {
        if (this.typingContainerRef().nativeElement) {
          this.typingContainerRef().nativeElement.innerHTML = '';
        }
      }
    });
  }

  private async startTypingEffect() {
    if (!this.text() || !this.typingContainerRef()) {
      return;
    }

    const parser = new DOMParser();
    const doc = parser.parseFromString(this.text(), 'text/html');

    for (const node of Array.from(doc.body.childNodes)) {
      await this.typeNode(node, this.typingContainerRef().nativeElement);
    }

    this.typingFinished.emit();
  }

  private async typeNode(node: Node, container: HTMLElement) {
    if (node.nodeType === Node.TEXT_NODE) {
      return this.typeText(node, container);
    }
    const element = node.cloneNode(false) as HTMLElement;
    container.appendChild(element);
    for (const child of Array.from(node.childNodes)) {
      await this.typeNode(child, element);
    }
  }

  private async typeText(node: Node, container: HTMLElement) {
    const words = node.textContent.split(/(\s+)/);
    for (const word of words) {
      container.innerHTML += word;
      await delay(this.getRandomTypingDelay());
    }
  }

  private getRandomTypingDelay(): number {
    return Math.floor(Math.random() * (this.MAX_TYPING_DELAY - this.MIN_TYPING_DELAY)) + this.MIN_TYPING_DELAY;
  }
}
