import { Commands } from '@local/client-contracts';
import { getExtensionByFileName, getIconByExtension } from '@local/ts-infra';
import { CommandsService } from 'src/app/bar/services/commands/commands.service';
import tinymce from 'tinymce';
import { Injectable } from '@angular/core';
import { ContextMenuItem } from '@shared/components';
import { BlobContextMenuService } from 'src/app/bar/services/blobs/blob-context-menu.service';

@Injectable()
export class FrameEditorContextMenuActionsService {
  constructor(
    private commandsService: CommandsService,
    private blobContextMenuService: BlobContextMenuService
  ) {}

  handleContextMenuCommand(command: ContextMenuItem, editorId: string) {
    const selectedElement = command.data.selectedElement;
    const id = selectedElement.id || selectedElement.getAttribute('data-mce-p-id');
    switch (command.id) {
      case 'delete':
        selectedElement.remove();
        break;
      case 'copy':
        tinymce.get(editorId).execCommand('Copy');
        break;
      case 'copy-video': {
        const clone = selectedElement.parentElement.cloneNode(true);
        selectedElement.parentElement.parentElement.appendChild(clone);
        break;
      }
      case 'download':
        this.startDownload(id);
        break;
      case 'duplicate': {
        selectedElement.insertAdjacentElement('afterend', selectedElement.cloneNode(true));
        setTimeout(() => {
          command.data.editor.save();
        }, 0);
        break;
      }
      case 'link': {
        setTimeout(() => {
          command.data.editor.execCommand('mceLink');
        }, 100);
        break;
      }
      case 'unlink': {
        setTimeout(() => {
          command.data.editor.execCommand('unlink');
        }, 100);
        break;
      }
      case 'openLink': {
        const open: Commands.OpenUrl = { type: 'open-url', url: selectedElement.href };
        this.commandsService.executeCommand(open, {});
        break;
      }
    }
  }

  onPreview(editorId: string) {
    const selectedElement = tinymce.get(editorId).selection.getNode();
    if (selectedElement.tagName.toLocaleLowerCase() === 'img') {
      const name = selectedElement.getAttribute('alt');
      this.openPreview(selectedElement.id, getIconByExtension(getExtensionByFileName(name)), selectedElement.id);
    }
  }

  onDownload(editorId: string) {
    const selectedElement = tinymce.get(editorId).selection.getNode();
    if (selectedElement.tagName.toLocaleLowerCase() === 'img') {
      this.startDownload(selectedElement.id);
    }
  }

  onDelete(editorId: string) {
    tinymce.get(editorId).execCommand('Delete');
  }

  async startDownload(blobId: string, name?: string) {
    return this.blobContextMenuService.download(blobId, name);
  }

  async openPreview(blobId: string, iconUrl: string, collectionId: string) {
    await this.blobContextMenuService.openPreview(blobId, iconUrl, collectionId);
  }
}
