import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, shareReplay, tap } from 'rxjs/operators';

@Injectable()
export class ContentCacheService {
  private cache = new Map<string, { data$: Observable<string>; timestamp: number }>();
  private cacheTimeout = 5 * 60 * 1000;
  private cleanupInterval: any;

  constructor(private http: HttpClient) {
    this.startCacheCleanup();
  }

  private startCacheCleanup() {
    this.cleanupInterval = setInterval(() => {
      const now = Date.now();
      this.cache.forEach((value, key) => {
        if (now - value.timestamp > this.cacheTimeout) {
          this.cache.delete(key);
        }
      });
    }, this.cacheTimeout);
  }

  private resetCacheCleanup() {
    clearInterval(this.cleanupInterval);
    this.startCacheCleanup();
  }

  getContent(url: string): Observable<string> {
    if (this.cache.has(url)) {
      this.cache.get(url).timestamp = Date.now();
      return this.cache.get(url)!.data$;
    }

    const request$ = this.http.get(url, { responseType: 'text' }).pipe(
      catchError((error) => {
        throw error;
      }),
      tap(() => this.resetCacheCleanup()),
      shareReplay(1)
    );

    this.cache.set(url, { data$: request$, timestamp: Date.now() });

    return request$;
  }

  stopCacheCleanup() {
    clearInterval(this.cleanupInterval);
  }
}
