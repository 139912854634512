<p-table
  #ptable
  [columns]="cols"
  [value]="data"
  [tableStyle]="styles"
  [scrollable]="true"
  [scrollHeight]="scrollHeight"
  [virtualScroll]="virtualScroll"
  [virtualScrollItemSize]="virtualScrollItemSize"
  class="u-table"
>
  <ng-template pTemplate="caption">
    <div class="caption"><ng-content></ng-content></div>
  </ng-template>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <ng-container *ngFor="let col of columns">
        <ng-container *ngIf="!col.displayCondition || col.displayCondition()">
          <th [ngClass]="col?.class" [style]="{ maxWidth: col.maxWidth, minWidth: col.minWidth }">
            @if (headerTemplate?.[col.field]) {
              <ng-container *ngTemplateOutlet="headerTemplate[col.field]"></ng-container>
            } @else {
              {{ col.header }}
            }
          </th>
        </ng-container>
      </ng-container>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-columns="columns">
    <tr>
      <ng-container *ngFor="let col of columns">
        <ng-container *ngIf="!col.displayCondition || col.displayCondition()">
          <td [ngClass]="col?.class" [style]="{ maxWidth: col.maxWidth, minWidth: col.minWidth }">
            <ng-container
              *ngTemplateOutlet="
                col.templateType && nodeTemplate ? nodeTemplate : defaultTemplate;
                context: { data: rowData[col?.field] ? rowData[col?.field] : rowData, type: col?.templateType }
              "
            ></ng-container>
          </td> </ng-container
      ></ng-container>
    </tr>
  </ng-template>
  <ng-container>
    <ng-template pTemplate="emptymessage">
      <ng-container class="empty-ghost-container" *ngIf="loading; else defaultEmptyTemplate">
        <tr class="ghost-item">
          <td ghost [ghostStyle]="{ width: '148px' }"></td>
          <td ghost [ghostStyle]="{ width: '568px', height: '13px', borderRadius: '2px', marginRight: '32px' }"></td>
        </tr>
      </ng-container>
      <ng-template #defaultEmptyTemplate>
        <tr>
          <td class="empty-message" colspan="7">{{ emptyMessage }}</td>
        </tr>
      </ng-template>
    </ng-template>
  </ng-container>
</p-table>
<ng-template #defaultTemplate let-data="data" let-type="type">
  {{ data }}
</ng-template>
