import { GoLinks, MemorySearch, Search } from '@local/client-contracts';
import { capitalCase } from '@local/ts-infra';
import { LogService, ServicesRpcService } from '@shared/services';
import { MemorySearchService } from '@shared/services/memory-search.service';
import { SessionService } from '@shared/services/session.service';
import { getDisplayHeader } from '@shared/utils/header-builder.util';
import * as moment from 'moment/moment';
import { Observable, Subscription, firstValueFrom, of } from 'rxjs';
import { HeaderItem, SearchResults, isGoLink, isHeader } from 'src/app/bar/views';
import { GoLinksSourceSettings } from '.';
import { FavoritesService } from '../../../favorites.service';
import { FiltersService } from '../../../filters.service';
import { GoLinksService } from '../../../go-links.service';
import { GoLinksIndexerRpcInvoker } from '../../../invokers/go-links-indexer-rpc-invoker';
import { UrlResolverService } from '../../../url-resolver.service';
import { WorkspacesService } from '../../../workspaces.service';
import { MemorySearchClient } from '../memory-search-client/memory-search-client';
import { SearchRequest } from '../search-request';
import { SearchResponse } from '../search-response';
import { GoLinksBuildResultView } from './go-links-build-result-view';
import { GoLinksResultExtra } from './go-links-result-extra';
import { DEFAULT_FOOTER_TITLE } from '../../models/search-client.constants';
import { Constants } from '@local/common';

export class GoLinksSearchClient extends MemorySearchClient<GoLinksSourceSettings> {
  private goLinksIndexerService: GoLinks.Indexer;
  private goLinksBuildResultView: GoLinksBuildResultView;
  private isOwnerOrAdmin: boolean;
  private subscriptions: { [sessionName: string]: Subscription[] } = {};
  protected workspaceDisabledFlags: string[] = [Constants.DISABLED_GO_LINKS_WORKSPACE_FEATURE_FLAG];

  constructor(
    services: ServicesRpcService,
    logService: LogService,
    memorySearchService: MemorySearchService,
    private filtersService: FiltersService,
    private goLinkService: GoLinksService,
    private sessionService: SessionService,
    private favoritesService: FavoritesService,
    protected workspaceService: WorkspacesService,
    private urlResolverService: UrlResolverService
  ) {
    super(logService, memorySearchService, ['Alphabetical', 'Time'], ['createdBy', 'tags', 'favorite', 'unlisted'], workspaceService);
    this.goLinksBuildResultView = new GoLinksBuildResultView(this.goLinkService, this.sessionService);
    this.logger = logService.scope('goLinks-search-client');
    this.goLinksIndexerService = services.invokeWith(GoLinksIndexerRpcInvoker, 'golinksindexer');
    this.workspaceService.ownerOrAdmin$.subscribe((s) => {
      this.isOwnerOrAdmin = s;
    });
  }

  async search(request: SearchRequest<GoLinksSourceSettings>, response: SearchResponse): Promise<Observable<void>> {
    if (this.isDisabled()) {
      response.complete();
      return;
    }
    if (response.cancelled) {
      return;
    }
    this.initExtra(response);
    const sourceSettings = request.sourceSettings;
    const sub = this.subscriptions[request.sessionName];
    sub?.forEach((c) => c.unsubscribe());
    this.subscriptions[request.sessionName] = [];
    const updates: Observable<any>[] = [this.favoritesService.getByType$('go-links'), this.goLinkService.updated$];
    for (const update of updates) {
      this.subscriptions[request.sessionName].push(
        update.subscribe(() => {
          if (response.cancelled || !response.done) {
            return;
          }
          if (sourceSettings.useMemSearch) {
            return this.refresh(request, response);
          }
          this._innerSearch(request, response).then((r) => of(r));
        })
      );
    }

    if (sourceSettings.useMemSearch) {
      return super.search(request, response);
    }

    return this._innerSearch(request, response).then((r) => of(r));
  }

  async getInput(request: SearchRequest<GoLinksSourceSettings>, response: SearchResponse): Promise<MemorySearch.Item[]> {
    if (response.cancelled) {
      return;
    }
    this.initExtra(response);
    const workspace = await firstValueFrom(this.workspaceService.current$);
    if (response.cancelled) {
      return;
    }
    this.addTime('getInput-beforeSearch', response);
    try {
      const resp = await this.goLinksIndexerService.search({
        postFilters: {
          ...request.sourceSettings?.filters?.postFilters,
          unlistedCreator: this.isOwnerOrAdmin ? undefined : workspace?.accountId,
        },
      });
      if (response.cancelled) {
        return;
      }
      this.addTime('getInput-afterSearch', response);
      const sorting = request.sourceSettings.sorting;
      const results = resp.results.map((x) => {
        let sortValue: number | string;
        switch (sorting?.by) {
          case 'Alphabetical':
            sortValue = x.name;
            break;
          case 'Timestamp':
            sortValue = x?.modifiedTime;
            break;
        }
        return {
          searchText: 'go ' + x.name + ' ' + x.description,
          data: x,
          sortValue,
        };
      });
      this.addTime('getInput-finish', response);
      return results;
    } catch (error) {
      this.logger.error('got error while trying to fetch go links searches', error);
      return [];
    }
  }

  destroy(id: number, sessionName: string): void {
    this.subscriptions[sessionName]?.forEach((c) => c.unsubscribe());
    delete this.subscriptions[sessionName];
  }

  async getOutput(items: MemorySearch.Item[]): Promise<SearchResults[]> {
    return items.map((i) => this.convertToSearchResult(i.data, null));
  }

  addHeaders(request: SearchRequest<GoLinksSourceSettings>, items: SearchResults[], resultCount: number, totalResults: number): void {
    const settings = request.sourceSettings;
    const goLinksOnly =
      request.query?.trim().toLowerCase().startsWith('go/') &&
      !this.workspaceService.isFeatureDisabled(Constants.DISABLED_GO_LINKS_WORKSPACE_FEATURE_FLAG);

    if (!goLinksOnly) {
      const { title, titleEnd } = getDisplayHeader({ title: settings.header?.title, titleEnd: settings.header?.titleEnd }, totalResults);
      const header: HeaderItem = {
        type: 'header',
        clickable: !!settings.header?.clickable,
        showButton: !!settings.showHeaderButton,
        origin: 'go-links',
        title,
        titleEnd,
        group: settings.showHeaderButton ? { name: 'golinks', title: 'Go Links' } : undefined,
      };
      items.unshift(header);
    }
    const footer: HeaderItem = {
      type: 'header',
      clickable: true,
      origin: `footer-${settings.type}`,
      title: settings.footer?.title || DEFAULT_FOOTER_TITLE,
      selectable: true,
      isFooter: true,
      group: {
        name: 'golinks',
        title: 'Go Links',
        type: 'active-page',
        value: 'golinks',
      },
    };
    if (settings.showHeaderButton) {
      items.push(footer);
    }
  }

  private async _innerSearch(request: SearchRequest<GoLinksSourceSettings>, response: SearchResponse) {
    if (response.cancelled) {
      return;
    }
    this.addTime('innerSearch-start', response);
    const sourceSettings = request.sourceSettings;
    let searchResults: Search.GoLinkResultItem[];
    let suggestedFilters: Search.ResponseFilters;
    try {
      const res = await this.getGoLinksSearchItems(request, response);
      if (response.cancelled) {
        return;
      }
      this.addTime('innerSearch-afterSearch', response);

      searchResults = res.searchResults;
      suggestedFilters = res.suggestedFilters;
    } catch (error) {
      if (response.cancelled) {
        return;
      }
      this.logger.error('got error while trying to fetch go links searches', error);
      const header: HeaderItem = {
        type: 'header',
        origin: 'remote',
        clickable: false,
        title: 'Failed to search the cloud.',
      };
      response.items = [header];
      response.complete(true);
    }
    if (response.cancelled) {
      return;
    }
    this.processResults(searchResults, suggestedFilters, response);
    this.addTime('processResults', response);
    response.items = [...(searchResults || [])];
    response.complete(true);
    if (response.items.length && sourceSettings.withVisits) {
      const ids = searchResults.filter((i) => isGoLink(i)).map((item) => (<Search.GoLinkResultItem>item).id);
      this.addTime('before-visits', response);
      const visits = await this.getVisitsItems(ids);
      if (response.cancelled) {
        return;
      }
      this.addTime('after-visits', response);
      response.items?.map((item: Search.GoLinkResultItem) => {
        const visit = visits?.[item.id];
        if (visit) {
          item.visit = visit;
        }
      });
      response.notifyUpdated();
    }
  }

  private async getGoLinksSearchItems(request: SearchRequest<GoLinksSourceSettings>, response: SearchResponse) {
    const searchRequest = await this.createSearchRequest(request);
    if (response.cancelled) {
      return;
    }
    this.addTime('createSearchRequest', response);
    const result: GoLinks.SearchResponse = await this.goLinksIndexerService.search(searchRequest);
    if (response.cancelled) {
      return;
    }
    this.addTime('goLinksIndexerService-search', response);
    const searchItems = await this.combineResultsAndGroups(result, response, request.sourceSettings);
    if (response.cancelled) {
      return;
    }
    this.addTime('combineResultsAndGroups', response);

    return { searchResults: searchItems as Search.GoLinkResultItem[], suggestedFilters: result.suggestedFilters };
  }

  private async getMetadata(items: GoLinks.SearchItem[]) {
    for (const item of items) {
      let iconLink = item.iconLink;
      if (!item.iconLink) {
        iconLink = (await this.urlResolverService.resolveSite(item.url))?.icon;
        if (iconLink) {
          await this.goLinkService.update(
            { ...item, lastModifiedTime: item.modifiedTime },
            { ...item, iconLink, lastModifiedTime: item.modifiedTime }
          );
        }
      }
    }
  }

  private sortItems(items: Search.GoLinkResultItem[], sorting: Search.Sort) {
    let sortedItems: Search.GoLinkResultItem[];
    switch (sorting.by) {
      case 'Alphabetical':
        sortedItems = items.sort((a, b) => a.data?.name?.localeCompare(b.data?.name));
        break;
      case 'Timestamp':
        sortedItems = items.sort((a, b) => a.data?.modifiedTime - b.data?.modifiedTime);
        break;
      default:
        break;
    }
    return sorting.order === 'Descending' ? sortedItems.reverse() : sortedItems;
  }

  private processResults(searchResults: Search.GoLinkResultItem[], suggestedFilters: Search.ResponseFilters, response: SearchResponse) {
    const extra: GoLinksResultExtra = response.extra;

    if (searchResults?.length && !Object.keys(this.filtersService.tagFilters || {}).length) {
      extra.postFilters = suggestedFilters;
    }
  }

  private async combineResultsAndGroups(result: GoLinks.SearchResponse, response: SearchResponse, sourceSettings: GoLinksSourceSettings) {
    if (!result.results?.length) {
      return [];
    }
    let finalResults: SearchResults[] = [];
    const hasGroups = result.results.length >= 5 && result.groups.length;
    const resultItems = result.results.slice(0, sourceSettings.maxCount || result.results.length);
    const flatIds: string[] = result.groups?.map((g) => g.results?.map((r) => r.id))?.flat() || [];
    const ids: string[] = [...resultItems.map((c) => c.id), ...flatIds];
    const visitCache = await this.getVisitsItems(ids, true);
    if (response.cancelled) {
      return;
    }

    let items = resultItems.map((r) => this.convertToSearchResult(r, visitCache?.[r.id]));
    this.getMetadata(result.results);

    if (sourceSettings.sorting) {
      items = this.sortItems(items, sourceSettings.sorting);
    }
    finalResults = items;

    if (!sourceSettings.noHeader) {
      const { title, titleEnd } = getDisplayHeader(
        { title: sourceSettings.header?.title, titleEnd: sourceSettings.header?.titleEnd },
        result.totalResults
      );

      const searchHeader: HeaderItem = {
        type: 'header',
        clickable: !!sourceSettings.header?.clickable,
        showButton: !!sourceSettings.showHeaderButton,
        origin: 'go-links',
        title: capitalCase(title || 'All go links'),
        titleEnd,
        group: sourceSettings.showHeaderButton ? { name: 'golinks', title: 'Go Links', type: 'active-page', value: 'golinks' } : undefined,
      };
      const footer: HeaderItem = {
        type: 'header',
        origin: 'footer-go-links',
        title: sourceSettings.footer?.title || DEFAULT_FOOTER_TITLE,
        clickable: true,
        selectable: true,
        isFooter: true,
        group: {
          name: 'golinks',
          title: 'Go Links',
          type: 'active-page',
          value: 'golinks',
        },
      };
      finalResults = [searchHeader, ...items];
      if (sourceSettings.showHeaderButton && items.length < result.totalResults) {
        finalResults = [...finalResults, footer];
      }
    }

    if (result.groups.length) {
      const groups = result.groups
        .filter(() => hasGroups)
        .map((g) => {
          const groupResultItems: Search.GoLinkResultItem[] = g.results.map((r) => this.convertToSearchResult(r, visitCache?.[r.id]));
          const header: HeaderItem = {
            type: 'header',
            clickable: false,
            origin: 'go-links',
            title: capitalCase(g.name),
            titleEnd: `${g.count}`,
          };
          if (hasGroups) {
            return [header, ...groupResultItems];
          }

          return groupResultItems;
        });
      const flatGroups = groups.flat();
      const itemsWithoutDuplicates = finalResults.filter(
        (i) => hasGroups || isHeader(i) || !flatGroups.find((gi) => (<Search.GoLinkResultItem>gi).id !== (<Search.GoLinkResultItem>i).id)
      );
      finalResults = [...flatGroups, ...itemsWithoutDuplicates];
    }

    return finalResults;
  }

  private async getVisitsItems(ids: string[], useCache = false): Promise<Record<string, GoLinks.GetVisitBatchItem>> {
    if (!ids?.length) {
      return;
    }

    const visitsBatchRequest: GoLinks.GetVisitsBatchRequest = {
      linkIds: ids,
      limit: 13,
      endDate: moment().unix() * 1000,
      startDate: moment().add(-1, 'month').unix() * 1000,
    };

    const visits: Record<string, GoLinks.GetVisitBatchItem> = await this.goLinkService.getVisited(visitsBatchRequest, useCache);

    return visits;
  }

  private convertToSearchResult(item: GoLinks.SearchItem, visit?: GoLinks.GetVisitBatchItem): Search.GoLinkResultItem {
    if (this.goLinkService.isDefaultGoLinkIcon(item.iconLink)) {
      item.iconLink = undefined;
    }
    return {
      id: item.id,
      type: 'go-link',
      view: this.goLinksBuildResultView.buildResultView(item),
      data: item,
      visit,
      isFavorite: item.favorite ? true : false,
      highlights: item?.highlights || [],
      favoriteMarkedTime: item.favorite,
    };
  }

  private async createSearchRequest(request: SearchRequest<GoLinksSourceSettings>): Promise<GoLinks.SearchRequest> {
    const workspace = await firstValueFrom(this.workspaceService.current$);
    const preFilters = this.filtersService.getPreFilters();
    const createdByFilter = 'createdBy';
    if (preFilters[createdByFilter]) {
      preFilters[createdByFilter] = preFilters[createdByFilter].map((val) => (val === 'Me' ? workspace.accountId : val));
    }
    const postFilters: GoLinks.SearchGoLinksAppliedFilters = {
      ...this.filtersService.postFilters,
      ...preFilters,
      unlistedCreator: this.isOwnerOrAdmin ? undefined : workspace?.accountId,
    };
    const sourceSettings = request.sourceSettings;
    const maxCount = sourceSettings.maxCount;
    const searchRequest: GoLinks.SearchRequest = {
      query: request.query,
      pageSize: maxCount,
      postFilters: postFilters,
      groups: sourceSettings.groupsOptions,
      withVisits: sourceSettings.withVisits,
    };

    if (sourceSettings.group?.value) {
      searchRequest.groups = searchRequest.groups || {};
      searchRequest.groups.scope = sourceSettings.group?.value;
    }

    return searchRequest;
  }

  private initExtra(response: SearchResponse) {
    const now = Date.now();
    response.extra = response.extra || ({ durations: { $lastTimestamp$: now } } as GoLinksResultExtra);
  }

  private addTime(name: string, response: SearchResponse) {
    const extra: GoLinksResultExtra = response.extra;
    const now = Date.now();
    extra.durations[name] = now - extra.durations.$lastTimestamp$;
    extra.durations.$lastTimestamp$ = now;
  }
}
