import { Injectable } from '@angular/core';
import { Commands, Search } from '@local/client-contracts';
import { PopupRef } from '@local/ui-infra';
import { ContextMenuComponent, ContextMenuData, ContextMenuItem, ContextMenuService } from '@shared/components';
import { BaseFile } from './blobs-storage.service';
import { BlobsService } from './blob.service';
import { CommandsService } from '../commands/commands.service';
import { PreviewService } from '../preview.service';
import { SearchResults } from '../../views';
import { generateId } from '@local/common-web';
import { generateTitleUrl } from '@local/ts-infra';
import { ContextMenuItemsType } from '../commands/context-menu-items-map';
import { ContextMenuItemsService } from '../commands/context-menu-items.service';

@Injectable()
export class BlobContextMenuService {
  private contextMenuPopup: PopupRef<ContextMenuComponent, ContextMenuData>;

  constructor(
    private contextMenuService: ContextMenuService,
    private contextMenuItemsService: ContextMenuItemsService,
    private blobsService: BlobsService,
    private commandsService: CommandsService,
    private previewService: PreviewService
  ) {}

  openContextMenu(event: MouseEvent, file: BaseFile, items: ContextMenuItemsType[]) {
    if (this.contextMenuPopup) {
      this.contextMenuPopup.destroy();
    }
    event.stopPropagation();
    this.contextMenuPopup = this.contextMenuService.open(
      event,
      {
        items: this.contextMenuItemsService.getContextMenuItems(items),
        onInvoke: (item: ContextMenuItem) => this.handleBlobCommand(item, file),
        minWidthItem: 90,
      },
      { position: 'right' }
    );
  }

  private async handleBlobCommand(command: ContextMenuItem, file: BaseFile) {
    if (!file) {
      return;
    }
    switch (command.id) {
      case 'preview': {
        this.openPreview(file.blobId, file.icon);
        break;
      }
      case 'download':
        this.download(file.blobId, file.name);
        break;
    }
  }

  async download(blobId: string, name?: string) {
    const blobUrl = this.blobsService.getDownloadUrl(blobId);

    const command: Commands.DownloadUrl = {
      type: 'download-url',
      grantType: 'blobs',
      url: blobUrl,
      name,
    };

    this.commandsService.executeCommand(command);
  }

  async openPreview(blobId: string, iconUrl: string, collectionId?: string) {
    const model = await this.buildFileView(blobId, iconUrl, collectionId);
    this.previewService.openPreviewPopup(model, true);
  }

  private async buildFileView(
    blobId: string,
    iconUrl: string,
    collectionId?: string
  ): Promise<Search.CollectionFileResultItem & SearchResults> {
    if (!blobId) {
      return;
    }
    const id = generateId();
    const [meta, blobUrl] = await Promise.all([this.blobsService.getBlobMetadata(blobId, null), this.blobsService.getBlobFullUrl(blobId)]);
    return {
      type: 'collection-file',
      kind: 'static-collection-item',
      id,
      fileId: blobId,
      collectionId,
      view: {
        title: {
          text: meta.name,
          onClick: {
            type: 'open-file',
            value: location.origin + '/' + generateTitleUrl('b', meta.name, id),
          } as Commands.DynamicCommand,
          onDrag: { type: 'download-url', url: this.blobsService.getDownloadUrl(blobId) } as Commands.DownloadUrl,
        },
        icon: { lightUrl: iconUrl },
        thumbnail: { url: this.blobsService.getThumbnailUrl(blobId) },
      },
      action: {
        type: 'collection-file',
        click: { primary: { type: 'preview' } },
      },
      blobUrl,
      meta,
    };
  }
}
