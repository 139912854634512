<div class="filter-container" [ngStyle]="{ marginRight: separateLayout ? '32px' : '0px' }">
  <ng-container *ngFor="let filter of mainFilters">
    <ng-container *ngTemplateOutlet="filterItem; context: { filter: filter, index: filter?.displayIndex }"></ng-container>
  </ng-container>
  <ng-container *ngIf="separateLayout && showClearAll">
    <div class="separate-line"></div>
    <ng-container *ngTemplateOutlet="clearAll"></ng-container
  ></ng-container>
  <ng-container *ngTemplateOutlet="isCollectionsView ? sideFiltersRef : null"></ng-container>
</div>
<ng-container *ngTemplateOutlet="!isCollectionsView ? sideFiltersRef : null"></ng-container>

<div *ngIf="!separateLayout && showClearAll && showClearIcon" style="margin-left: 16px">
  <ng-container *ngTemplateOutlet="clearAll"></ng-container>
</div>
<ng-template #filterItem let-filter="filter" let-index="index">
  <ng-container *ngIf="filter.valuesFetcher || filter.values?.length || filter.viewDetails?.enableEmptyFilter">
    <div *ngIf="filter?.viewDetails?.showSeparate" class="separate-line"></div>
    <ng-container [ngSwitch]="filter?.picker">
      <ng-container *ngSwitchCase="'multi-select'">
        <multi-select-wrapper
          [attr.data-cy]="'filter-menu-item-' + filter.title"
          #filterElement
          class="filter dropdown-filter"
          [ngClass]="{ 'min-width-auto': filter.viewDetails?.hideTitle }"
          [model]="filter"
          [hideTitle]="filter.viewDetails?.hideTitle"
          [marked]="
            list?.markedIndex === index ? { marked: true, keys: lastKeys, event: lastEvent } : { marked: false, keys: null, event: null }
          "
          (onOpened)="multiSelectOpenChanged($event, filter.name)"
          (onValuesUpdated)="valuesUpdated($event)"
          [keepPlaceholder]="true"
          [showItemIcon]="filter.viewDetails?.showItemIcon"
          [showSelectedItemIcons]="filter.viewDetails?.showSelectedItemIcons"
          [isFullDetailLine]="filter.viewDetails?.isFullDetailLine"
          [showItemLabel]="filter.viewDetails?.showItemLabel"
          [tooltipLabel]="filter.viewDetails?.tooltipLabel"
          [showItemIconLabel]="filter.viewDetails?.showItemIconLabel"
          [isTwoLine]="filter.viewDetails?.isTwoLine"
          [sortBy]="filter.viewDetails?.sortBy"
          [filterPlaceholder]="filter.viewDetails?.placeholder"
          (onFilterChange)="onFilterChange($event)"
          [allowMultiSelectIcon]="false"
          [oneValue]="filter.viewDetails?.oneValue"
          [open]="shouldOpen(index)"
          [version]="filterVersions[index]"
          [updateOnRemove]="filter.viewDetails?.updateOnRemove"
          [showTooltipIcons]="filter.name == 'filter:app'"
          [noCheckbox]="filter.viewDetails?.noCheckbox"
          [excludeSelectedFromHeader]="filter.viewDetails?.excludeSelectedFromHeader"
          [showGroupOptions]="'FilterOnly'"
          [showClearAll]="filter.viewDetails?.showClearAll"
          [hideOnSelect]="filter.viewDetails?.hideOnSelect"
          [showHasValues]="filter.viewDetails?.showHasValues"
          [limitShowSelectedLabels]="filter.viewDetails?.limitShowSelectedLabels"
          [showRemoveIcon]="filter.viewDetails?.showRemoveIcon"
          (onBack)="back()"
          [displayBackIcon]="filter.viewDetails?.showBackIcon"
          [clearAllMinSelected]="filter.viewDetails?.clearAllMinSelected"
          [shortSelected]="filter.viewDetails?.shortSelected"
          [onlyIcon]="filter.viewDetails?.onlyIcon"
          [tooltipSelected]="filter.viewDetails?.tooltipSelected"
          [customSelectedCount]="filter.viewDetails?.customSelectedCount"
          [optionsFetcher]="filter.valuesFetcher"
          [filterDisabled]="'FilterOnly'"
          [filterBy]="filter.valuesFetcher ? ',' : null"
          [filterMatchMode]="filter.valuesFetcher ? 'notEquals' : 'contains'"
          [wideWidth]="filter.viewDetails?.wideWidth"
          [enableBack]="filter.viewDetails?.enableBack"
          [appendTo]="filter.viewDetails?.appendTo || null"
          [separateSelected]="filter.viewDetails?.separateSelected"
          [fullDetailIds]="fullDetailIds"
          (onClickEvent)="onClick.emit(index)"
          [clearAllTooltipContent]="tooltipClearFilters"
          [timeoutDelayChangeItem]="timeoutDelayChangeItem"
        ></multi-select-wrapper>
      </ng-container>
      <ng-container *ngSwitchCase="'time-dropdown'">
        <time-multi-select
          [attr.data-cy]="'filter-menu-item-' + filter.title"
          #filterElement
          class="filter dropdown-filter"
          [model]="filter"
          [keepPlaceholder]="keepPlaceholder"
          [marked]="
            list?.markedIndex === index ? { marked: true, keys: lastKeys, event: lastEvent } : { marked: false, keys: null, event: null }
          "
          [filterPlaceholder]="filter.viewDetails?.placeholder"
          [isFullDetailLine]="filter.viewDetails?.isFullDetailLine"
          [filterTitle]="filter.viewDetails?.filterTitle"
          (onFilterChange)="onFilterChange($event)"
          (onOpened)="multiSelectOpenChanged($event, filter.name)"
          (closeFilter)="datePickerClosed(filter.name)"
          (onValuesUpdated)="valuesUpdated($event)"
          [oneValue]="filter.viewDetails?.oneValue"
          [open]="shouldOpen(index)"
          [version]="filterVersions[index]"
          [updateOnRemove]="filter.viewDetails?.updateOnRemove"
          [noCheckbox]="filter.viewDetails?.noCheckbox"
          [showHasValues]="filter.viewDetails?.showHasValues"
          (onBack)="back()"
          [optionsFetcher]="filter.valuesFetcher"
          [displayBackIcon]="filter.viewDetails?.showBackIcon"
          [shortSelected]="filter.viewDetails?.shortSelected"
          [customSelectedCount]="filter.viewDetails?.customSelectedCount"
          [showClearAll]="filter.viewDetails?.showClearAll"
          [wideWidth]="filter.viewDetails?.wideWidth"
          [enableBack]="filter.viewDetails?.enableBack"
          [hideOnSelect]="filter.viewDetails?.hideOnSelect"
          [filterMatchMode]="filter.valuesFetcher ? 'notEquals' : 'contains'"
          [filterBy]="filter.valuesFetcher ? ',' : null"
          [separateSelected]="filter.viewDetails?.separateSelected"
          [appendTo]="filter.viewDetails?.appendTo || null"
          [hideSearchFilter]="filter.viewDetails?.hideSearchFilter"
          [showFilterTooltip]="showFilterTooltip"
        ></time-multi-select>
      </ng-container>
      <ng-container *ngSwitchCase="'free-text'">
        <dropdown-wrapper
          [attr.data-cy]="'filter-menu-item-' + filter.title"
          #filterElement
          class="filter dropdown-filter free-text-dropdown"
          [model]="filter"
          [filterInputValue]="filter.values?.[0]?.value"
          [filterPlaceholder]="filter.viewDetails?.placeholder"
          (onFilterChange)="onFilterChange($event, filter.name)"
          (onOpened)="multiSelectOpenChanged($event, filter.name, index)"
          [marked]="shouldOpen(index) ? { marked: true, keys: lastKeys, event: lastEvent } : { marked: false, keys: null, event: null }"
          [open]="shouldOpen(index)"
          [selectByEnter]="true"
          [selectedFilterValue]="filter.values?.[0]?.value"
        >
        </dropdown-wrapper>
      </ng-container>
      <ng-container *ngSwitchCase="'toggle'">
        <toggle-filter
          [attr.data-cy]="'filter-menu-item-' + filter.title"
          #filterElement
          class="filter"
          [class.hide-split]="!filter.viewDetails?.showSplitLine"
          [model]="filter"
          (change)="onFilterChange($event)"
          [marked]="
            list?.markedIndex === index ? { marked: true, keys: lastKeys, event: lastEvent } : { marked: false, keys: null, event: null }
          "
          [isSelected]="filter?.values[0]?.selected"
        ></toggle-filter>
      </ng-container>
      <ng-container *ngSwitchCase="'spread'">
        <spread-filter
          [attr.data-cy]="'filter-menu-item-' + filter.title"
          #filterElement
          class="filter"
          [model]="filter"
          (change)="onFilterChange($event, filter.name)"
          [resultFocused]="resultFocused"
          [marked]="
            list?.markedIndex === index ? { marked: true, keys: lastKeys, event: lastEvent } : { marked: false, keys: null, event: null }
          "
          [enabledOnly]="filter.viewDetails?.enabledOnly"
          [limitItemsCount]="filter.viewDetails?.limitItemsCount"
        ></spread-filter>
      </ng-container>
      <ng-container *ngSwitchCase="'nested'">
        <nested-filter
          class="filter"
          #filterElement
          [model]="filter"
          [filterMatchMode]="'contains'"
          [version]="filterVersions[index]"
          [appendTo]="filter.viewDetails?.appendTo || null"
          (onFilterChange)="onFilterChange($event)"
          (onOpened)="multiSelectOpenChanged($event, filter.name)"
        ></nested-filter>
      </ng-container>
      <ng-container *ngSwitchCase="'constant'">
        <constant-filter class="filter" #filterElement [model]="filter" [keepPlaceholder]="keepPlaceholder"></constant-filter>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-template>
<ng-template #clearAll>
  <ng-container *ngIf="showClearAll">
    <span
      [pTooltip]="tooltipClearFilters"
      tooltipPosition="top"
      tooltipStyleClass="u-tooltip"
      [escape]="false"
      class="clear-filters"
      (click)="onClearAllFilters()"
      >Clear Filters</span
    ></ng-container
  >
</ng-template>
<ng-template #sideFiltersRef>
  <div *ngIf="sideFilters">
    <ng-container *ngFor="let filter of sideFilters">
      <ng-container *ngTemplateOutlet="filterItem; context: { filter: filter, index: filter?.displayIndex }"></ng-container>
    </ng-container>
  </div>
</ng-template>
