import { Injectable } from '@angular/core';
import { Permissions, Questionnaire, Window } from '@local/client-contracts';
import { TypePlatforms, ValueStorage } from '@local/common';
import { isEmbed, isNativeWindow } from '@local/common-web';
import { PopupRef, PopupService } from '@local/ui-infra';
import { EmbedService } from '@shared/embed.service';
import { EventsService, LogService, ServicesRpcService, WindowService } from '@shared/services';
import { QuestionnaireRpcInvoker } from '@shared/services/invokers/questionnaire-rpc-invoker';
import { SessionStorageService } from '@shared/services/session-storage.service';
import { Logger } from '@unleash-tech/js-logger';
import { firstValueFrom } from 'rxjs';
import { AppPopupComponent, AppPopupData } from '../components/app-popup/app-popup.component';
import { ErrorQuestionnaireConstants } from '../views/results-views/questionnaire/error.const';
import {
  QuestionnaireComponent,
  QuestionnaireData,
  QuestionnaireEnum,
  QuestionnairePopupData,
} from '../views/results-views/questionnaire/questionnaire.component';
import { questionnaireContent } from '../views/results-views/questionnaire/questionnaire.content';
import { BlobsService } from './blobs/blob.service';
import { HubService } from './hub.service';

export type Platforms = {
  [key in TypePlatforms]?: string;
};

@Injectable()
export class QuestionnaireService implements Questionnaire.Service {
  private readonly ACTIVE_PROCESS_ID: string = 'activeRfpIds';

  private readonly AUX_WINDOW_SIZE = {
    height: '600px',
    width: '800px',
  };

  private readonly POPUP_DATA: AppPopupData = {
    message: 'Closing the window while processing <br> will result in lost data.',
    showButtons: true,
    leftButtonStyle: { size: 100 },
    rightButtonStyle: { size: 100 },
    content: {
      title: 'Are you sure you want to close?',
      secondaryButton: 'Cancel',
      primaryButton: 'Yes, leave',
    },
    messageStyle: { fontSize: '12px' },
    titleStyle: { 'font-weight': '700' },
  };

  private service: Questionnaire.Service;
  private logger: Logger;
  private isEmbed: boolean = isEmbed();
  questionnairePopupRef: PopupRef<QuestionnaireComponent, any>;
  private warningPopupRef: PopupRef<AppPopupComponent, AppPopupData>;
  private errorPopupRef: PopupRef<AppPopupComponent, AppPopupData>;
  private processIdStorage: ValueStorage<Platforms>;
  private platform: TypePlatforms;

  constructor(
    services: ServicesRpcService,
    logger: LogService,
    private popupService: PopupService,
    private eventsService: EventsService,
    private blobsService: BlobsService,
    private sessionStorageService: SessionStorageService,
    private windowService: WindowService,
    private embedService: EmbedService,
    private hubService: HubService
  ) {
    this.logger = logger.scope('QuestionnaireService');
    this.service = services.invokeWith(QuestionnaireRpcInvoker, 'questionnaire');
    this.initProcessIdStorage();
  }

  async initProcessIdStorage() {
    this.platform = 'web';
    if (isEmbed()) {
      this.platform = 'embed';
      if (await this.embedService?.isExternalWebSite()) {
        this.platform = 'extension';
      }
    } else if (isNativeWindow()) {
      this.platform = 'native';
    }
    this.processIdStorage = this.sessionStorageService.getStore('roaming', 'account').entry<Platforms>(this.ACTIVE_PROCESS_ID);
  }

  async getCurrentProcessId(): Promise<string> {
    const processing = (await this.processIdStorage.get()) || {};
    return processing[this.platform];
  }

  async setCurrentProcessId(id?: string) {
    const processing = (await this.processIdStorage.get()) || {};
    processing[this.platform] = id || '';
    return this.processIdStorage.set(processing as Platforms);
  }

  async loadFile(file: File) {
    try {
      const { name, type } = file;
      const shareOptions: Permissions.ShareOptions = {
        level: 'Private',
      };
      const blobId = (await this.blobsService.create(name, type, shareOptions))?.id;
      const uploadResponse = await this.blobsService.upload(file, blobId);

      if (uploadResponse.status >= 400) {
        throw new Error(uploadResponse.statusText);
      }

      return this.load({ type: 'sheet', blobId, data: new Uint8Array(await file.arrayBuffer()) });
    } catch (error) {
      this.logger.error('error to upload rfp file', error);
    }
  }

  create(req: Questionnaire.CreateQuestionnaireRequest): Promise<Questionnaire.CreateQuestionnaireResponse> {
    return this.service.create(req);
  }

  load(req: Questionnaire.LoadRequest): Promise<Questionnaire.LoadResponse> {
    return this.service.load(req);
  }

  status(id: string): Promise<Questionnaire.QuestionnaireStatusResponse> {
    return this.service.status(id);
  }

  delete(id: string): Promise<void> {
    return this.service.delete(id);
  }

  async deleteProgress(processId: string, blobId: string) {
    await Promise.all([this.setCurrentProcessId(), this.delete(processId), this.blobsService.delete({ ids: [blobId] })]);
  }

  async open(data?: QuestionnairePopupData) {
    if (this.isEmbed) {
      const embedOptions = await firstValueFrom(this.embedService?.options$);
      const isStandardEmbed = (await this.embedService?.isFullApp()) || embedOptions?.windowStyle === 'standard';
      if (!isStandardEmbed) {
        this.openAuxWindow(data);
        return;
      }
    }

    if (isNativeWindow()) {
      this.openAuxWindow(data);
    } else {
      this.openPopup(data);
    }
  }

  private openAuxWindow(data?: QuestionnairePopupData) {
    const settings: Window.OpenAuxWindowOptions = {
      url: `/window/questionnaire${data.processId ? '/' + data.processId : ''}${
        data.assistantId ? '?assistantId=' + data.assistantId : ''
      }`,
      width: this.AUX_WINDOW_SIZE.width,
      height: this.AUX_WINDOW_SIZE.height,
      hideOnClickOut: true,
    };
    if (isNativeWindow()) {
      this.windowService.openAuxWindow(settings);
    }
    if (isEmbed()) {
      this.embedService.openAuxWindow(settings);
    }
  }

  private openPopup(data?: QuestionnairePopupData): PopupRef<QuestionnaireComponent, QuestionnairePopupData> {
    if (this.questionnairePopupRef) {
      return;
    }
    this.questionnairePopupRef = this.popupService.open<QuestionnaireComponent, QuestionnairePopupData>(
      'center',
      QuestionnaireComponent,
      data,
      {
        hasBackdrop: false,
        closeOnClickOut: true,
        backdropStyle: 'blur-2',
        panelClass: this.isEmbed ? 'questionnaire-popup-embed' : 'questionnaire-popup',
      }
    );

    this.questionnairePopupRef.destroy$.subscribe(() => {
      this.questionnairePopupRef = null;
    });

    this.questionnairePopupRef.outsidePointerEvents$.subscribe(() => {
      const data: QuestionnaireData = this.questionnairePopupRef.compInstance.data;
      const currentStep: QuestionnaireEnum = this.questionnairePopupRef.compInstance.currentStep;
      if (!data?.fileName || [QuestionnaireEnum.Processing, QuestionnaireEnum.Summary].includes(currentStep)) {
        this.closePopup();
      }
    });

    return this.questionnairePopupRef;
  }

  closePopup(windowMode?: boolean) {
    if (windowMode) {
      if (isNativeWindow()) {
        this.windowService.close();
      }
      if (isEmbed()) {
        this.embedService.close();
      }
    } else {
      this.questionnairePopupRef.destroy();
      this.questionnairePopupRef = null;
    }
  }

  closeWarningPopup() {
    this.warningPopupRef?.destroy();
    this.warningPopupRef = null;
  }

  closeErrorPopup() {
    this.errorPopupRef?.destroy();
    this.errorPopupRef = null;
  }

  openWarningPopup(step: QuestionnaireEnum, processId: string, blobId: string, windowMode?: boolean) {
    if (this.warningPopupRef) {
      this.closeWarningPopup();
    }
    const popupData: AppPopupData = this.POPUP_DATA;
    if (step === QuestionnaireEnum.Summary) {
      popupData.message = ErrorQuestionnaireConstants.CLOSE_SUMMARY_MESSAGE;
      popupData.content.title = ErrorQuestionnaireConstants.CLOSE_SUMMARY_QUESTION;
    }
    this.warningPopupRef = this.popupService.open<AppPopupComponent, AppPopupData>('center', AppPopupComponent, popupData, {
      position: 'center',
    });
    this.warningPopupRef.compInstance.primaryButton.subscribe(async () => {
      this.closeWarningPopup();
      this.closePopup(windowMode);
      if (processId) {
        await this.deleteProgress(processId, blobId);
      } else if (blobId) {
        this.blobsService.delete({ ids: [blobId] });
      }
    });
    this.warningPopupRef.compInstance.secondaryButton.subscribe(() => {
      this.closeWarningPopup();
    });
  }

  openErrorPopup(type: 'failed' | 'duplicateMapping' | 'alreadyProcess' | 'emptyColumn'): PopupRef<AppPopupComponent, AppPopupData> {
    if (this.errorPopupRef) {
      this.closeErrorPopup();
    }

    let popupData: AppPopupData = ErrorQuestionnaireConstants.ERROR_MAPPING_DATA;
    switch (type) {
      case 'duplicateMapping':
        popupData.message = ErrorQuestionnaireConstants.ERROR_FAILED_MESSAGE;
        popupData.content.title = ErrorQuestionnaireConstants.ERROR_FAILED_TITLE;
        break;
      case 'failed':
        popupData = ErrorQuestionnaireConstants.ERROR_FAILED_PROCESS_DATA;
        break;
      case 'emptyColumn':
        popupData = ErrorQuestionnaireConstants.ERROR_EMPTY_COLUMN_DATA;
        break;
    }

    this.errorPopupRef = this.popupService.open<AppPopupComponent, AppPopupData>('center', AppPopupComponent, popupData, {
      position: 'center',
    });
    this.errorPopupRef.compInstance.primaryButton.subscribe(() => {
      this.closeWarningPopup();
    });
    this.errorPopupRef.compInstance.secondaryButton.subscribe(() => {
      this.closeWarningPopup();
    });
    return this.errorPopupRef;
  }

  createWorksheetsRequest(worksheets: Questionnaire.Worksheet[]): Questionnaire.WorksheetRequest[] {
    const request = worksheets.flatMap((ws) =>
      ws.questionnaire.map((q) => {
        const question = q.questionColumn;
        const response = q.responseColumn;

        return {
          name: ws.name,
          questionColumn: {
            header: this.isUndefinedHeader(question?.header) ? null : question.header,
            address: question?.address?.toUpperCase(),
          },
          responseColumn: {
            header: this.isUndefinedHeader(response?.header) ? null : response.header,
            address: response?.address?.toUpperCase(),
          },
        };
      })
    );

    return request.filter((ws) => ws.questionColumn?.address);
  }

  private isUndefinedHeader(header: string) {
    return !header || [questionnaireContent.undefinedColumn, questionnaireContent.emptyColumn].includes(header);
  }
}
