import { Assistants, Chats } from '@local/client-contracts';
import { SourceSettings } from '../source-settings';

export interface AnswersSourceSettings extends SourceSettings {
  type: 'answers';
  maxCount: number;
  minWords: number;
  onlyClassifyAi?: boolean;
  collectionId?: string;
  displayOpenChatResult?: boolean;
  allowAllQuestionQueries?: boolean;
  preventFormattedAnswer?: boolean;
  chat?: Assistants.QuestionChatSettings;
  redirectToChat?: boolean;
  source?: Assistants.AnswerLocationType;
  blobIds?: string[];
  answerCacheTime?: number;
  action?: Chats.UserMessageAction;
  ignoreFilters?: boolean;
  shouldClassifyAi?: boolean;
}
