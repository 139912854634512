<div class="custom-interval-wrapper" (click)="closePopup()">
  <div class="custom-interval-container" (click)="$event.stopPropagation()">
    <div class="custom-interval-header">
      <span>Custom recurrence</span>
    </div>
    <div class="custom-interval-content">
      <div class="interval-select-kind">
        <span>Repeat every</span>
        <u-input-number
          [minValue]="1"
          [maxValue]="99"
          (onChange)="onChangeInterval($event)"
          [styles]="{ width: '36px', height: '36px' }"
        ></u-input-number>
        <u-dropdown
          [options]="REPEAT_OPTIONS"
          optionLabel="label"
          [selectedValue]="selectedRepeatOption"
          (onChange)="onRepeatOptionSelect($event)"
          [styles]="{ width: '120px', height: '36px' }"
        ></u-dropdown>
      </div>
      <div class="interval-select-content">
        <ng-container [ngSwitch]="selectedRepeatOption?.value">
          <ng-container *ngSwitchCase="'week'">
            <div class="week-select-container">
              <span>Repeat on</span>
              <div class="week-buttons">
                <u-button
                  *ngFor="let day of weekdays; let i = index"
                  [label]="day?.value"
                  [ngClass]="{
                    'no-border-button': !day.selected
                  }"
                  [type]="day.selected ? 'primary' : 'secondary'"
                  (onClick)="onSelectWeekday(i)"
                  [styles]="{ width: '34px', height: '34px' }"
                ></u-button>
              </div>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="'month'">
            <u-dropdown
              [options]="monthlyOptions"
              optionLabel="label"
              [selectedValue]="selectedMonthlyOption"
              (onChange)="onMonthlyOptionSelect($event)"
              [styles]="{ width: '160px', height: '36px' }"
            ></u-dropdown>
          </ng-container>
          <ng-container *ngSwitchCase="'year'">
            <span class="year-interval-text">On {{ inputDateFormatDate }}</span>
          </ng-container>
        </ng-container>
      </div>
    </div>
    <div class="custom-interval-footer">
      <u-button label="Cancel" type="secondary" (onClick)="closePopup()"></u-button>
      <u-button label="Done" type="primary" (onClick)="done()" [disabled]="disableSaveButton"></u-button>
    </div>
  </div>
</div>
