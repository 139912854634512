import { Injectable } from '@angular/core';
import { Constants } from '@local/common';
import { CanActivateViewGuard } from './can-activate-view.guard';
import { getNodeIdFromRoute } from '../nav-tree-node-id.resolver';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';

@Injectable()
export class CanActivateGoLinksGuard extends CanActivateViewGuard {
  protected flag;
  protected flagMap = {
    golinks: Constants.DISABLED_GO_LINKS_WORKSPACE_FEATURE_FLAG,
    favorites: Constants.DISABLED_FAVORITES_WORKSPACE_FEATURE_FLAG,
  };

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const nodeId: string = getNodeIdFromRoute(route);
    if (!this.flagMap[nodeId]) {
      return of(true);
    }
    this.flag = this.flagMap[nodeId];
    return super.canActivate(route, state);
  }
}
