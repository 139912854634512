import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, input, Input, model, output, ViewChild } from '@angular/core';
import { ElementSize } from '../../types/element-size';
import { UBaseInfraComponent } from '../u-base-infra.component';
import { Button } from 'primeng/button';

//Version 1
export type UButtonType = 'primary' | 'primary-danger' | 'secondary' | 'secondary-danger' | 'secondary-light' | 'tertiary' | 'link';

//Version 2
export type StyleType = 'fill' | 'outlined' | 'text' | 'link';

@Component({
  selector: 'u-button',
  templateUrl: './u-button.component.html',
  styleUrls: ['./u-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UButtonComponent extends UBaseInfraComponent {
  //Version 2
  label = model<string>(null);
  styleType = input<StyleType>('fill');
  fontIcon = input<string>(null);
  iconPos = input<'left' | 'right'>('left');
  forceSecondaryDarkMode = input<boolean>(false);
  versionNumber = input<number>(1);
  styles = input<any>({});

  onClick = output<any>();

  @ViewChild('pButton') pButtonRef: Button;

  @HostBinding('class.u-host-button-v2')
  get isVersionTwo(): boolean {
    return this.versionNumber() === 2;
  }

  getBaseClasses() {
    return {
      ...super.getBaseClasses(),
      'p-button-readonly': this.stateElement() === 'readonly',
    };
  }

  getIconRef() {
    if (this.versionNumber() > 1) {
      return this.pButtonRef?.el?.nativeElement?.querySelector('.p-button-icon');
    }
    return this.button?.nativeElement?.querySelector('.p-button-icon');
  }

  getButtonRef(): ElementRef {
    if (this.versionNumber() > 1) {
      return this.pButtonRef?.el;
    }
    return this.button;
  }

  click(event: any) {
    if (['readonly', 'disabled', 'loading'].includes(this.stateElement())) {
      return;
    }
    this.onClick.emit(event);
  }

  //Version 1
  private _active = false;

  @Input() disabled = false;
  @Input() type: UButtonType = 'primary';
  @Input() withIcon = false;
  @Input() onlyIcon = false;
  @Input() elementSize: ElementSize;
  @Input() loading = false;

  @Input() set active(v: boolean) {
    if (this._active && !v) {
      this.button.nativeElement.blur();
    }
    this._active = v;
  }
  get active() {
    return this._active;
  }

  @ViewChild('button') button: ElementRef;

  @HostBinding('class.u-host-button-v1')
  get isVersionOne(): boolean {
    return this.versionNumber() === 1;
  }
}
