import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ClientStorageService } from '@shared/services/client-storage.service';

@Injectable({
  providedIn: 'root',
})
export class SidebarPinResolver implements Resolve<boolean> {
  constructor(private clientStorage: ClientStorageService) {}

  async resolve(): Promise<boolean> {
    const state = await this.clientStorage.get('chatSidebarState');
    return !(state && state === 'unpinned');
  }
}
