<a class="dismiss-button" (click)="onCancelClick('close')">
  <u-icon class="close-button" [model]="{ type: 'font', value: 'icon-times-large' }"></u-icon>
</a>
<header>
  <h3>{{ data?.title }}</h3>
</header>
<div *ngIf="data?.warningMessage" class="warning">
  <div class="warning-icon"><div class="icon">!</div></div>
  <div class="warning-message">{{ data?.warningMessage }}</div>
</div>
<section [class.show-border-line]="!data?.styleOptions?.hideSelectionBorder">
  <div class="auto-complete-container" [class.no-warning]="!data?.warningMessage">
    <p class="description">{{ data?.description }}</p>
  </div>
  <div class="auto-complete-container" [class.multiple]="data?.styleOptions?.showAddButton">
    <u-auto-complete
      [class]="'select-item-auto-complete ' + (data?.styleOptions?.overrideAutoCompleteClass ?? '')"
      field="name"
      [placeholder]="data?.placeholder"
      [suggestionItems]="data?.itemsList"
      (onChange)="onSelectItem($event)"
      (onHide)="onHideAutoComplete()"
      (inputQueryChange)="inputChanged($event)"
      [autoFocus]="true"
      [expandOnInputClick]="true"
      [filterInvoker]="data?.filterInvoker"
      [showArrow]="data?.autoCompleteSettings?.showArrow"
      [removeSelected]="data?.autoCompleteSettings?.removeSelected"
      [selectedItems]="selectedItem && !multiple ? [selectedItem] : selectedItem"
      [multiple]="multiple"
      [width]="data?.styleOptions?.showAddButton ? '324px' : '432px'"
      [viewMode]="multiple ? undefined : 'tag'"
      [displayDismissButton]="data?.autoCompleteSettings?.displayDismissButton"
      [displayCreateNew]="data?.autoCompleteSettings?.displayCreateNew"
      [createNewOption]="data?.autoCompleteSettings?.createNewOption"
    >
      <ng-template let-item pTemplate="item">
        <div
          *ngIf="!item.isNew"
          class="auto-complete-select-item"
          [ngClass]="{ 'item-disable': item?.disable }"
          (click)="item.name === 'No results' ? $event.stopPropagation() : null"
        >
          <u-emoji *ngIf="!!item.collection?.emoji" [unicode]="item.collection?.emoji"></u-emoji>
          <u-icon
            class="auto-complete-item-icon"
            *ngIf="!item.collection?.emoji && data?.autoCompleteSettings?.allowIconInViewList && item.icon"
            [model]="{ type: 'font', value: item.icon }"
            [style]="{ fontSize: '12px', marginRight: '4px', color: item.iconColor }"
          ></u-icon>
          <span class="title">{{ item.name }}</span>
          <span *ngIf="item.collection && !item.isNew" class="resources-count"
            >{{ item.itemsNumber === 10000 ? '10,000+' : item.itemsNumber ?? 0 }} {{ wikiId ? 'cards' : 'resources' }}</span
          >
          <avatar-list class="avatar-list" [model]="item.avatarList" [spreadAvatarsCount]="3" [tooltipLimit]="10"></avatar-list>
        </div>
      </ng-template>
    </u-auto-complete>
    <ng-container *ngIf="data?.styleOptions?.showAddButton">
      <ng-container *ngTemplateOutlet="addButton; context: { disabled: !selectedItem?.length }"></ng-container>
    </ng-container>
  </div>

  <div *ngIf="data?.displaySelectedItems" class="display-selected-items">
    <div class="scrolling-area" [style.height]="scrollHeight">
      <ng-scrollbar visibility="hover">
        <cdk-virtual-scroll-viewport itemSize="40" ngScrollbarView smoothScroll scrollViewport [class.virtual-scroll]="true">
          <div class="selected-item" *ngFor="let selected of chosenItems; let index = index">
            <div class="selected-main-details">
              <div class="selected-icon" *ngIf="data.renderSelectedOptions.icon" [style.background]="selected.iconColor">
                <ng-container *ngIf="selected.icon; else showLetter">
                  <u-icon [model]="{ type: 'font', value: selected.icon }" [styles]="{ fontSize: '14px', color: 'var(--white)' }"></u-icon>
                </ng-container>
                <ng-template #showLetter>
                  {{ selected.name.charAt(0) }}
                </ng-template>
              </div>
              <div class="title-description-wrapper">
                <div class="title" *ngIf="data.renderSelectedOptions.title">{{ selected.name }}</div>
                <div class="description" *ngIf="data.renderSelectedOptions.title">
                  {{ selected.id === 'home' ? 'Shared with Workspace' : 'Tab shared with ' }}
                  <avatar-list
                    *ngIf="selected.id !== 'home' && selected.avatarList"
                    class="avatar-list"
                    [model]="selected.avatarList"
                    [tooltipLimit]="10"
                    tooltipHeader="Shared With"
                    [spreadAvatarsCount]="0"
                    [labelModel]="selected.avatarList.length + (selected.avatarList.length > 1 ? ' members' : ' member')"
                  ></avatar-list>
                  <div *ngIf="!selected.avatarList" class="description">You</div>
                </div>
              </div>
            </div>
            <div class="selected-main-buttons" *ngIf="data.renderSelectedOptions.rightButton">
              <u-button class="right-button" type="secondary" elementSize="small28" (onClick)="rightButtonClick(selected, index)">
                <u-icon
                  class="right-button-icon"
                  *ngIf="data.renderSelectedOptions.rightButton.icon"
                  [model]="{ type: 'font', value: data.renderSelectedOptions.rightButton.icon }"
                  [styles]="{ fontSize: '14px' }"
                ></u-icon>
                <div class="right-button-label" *ngIf="data.renderSelectedOptions.rightButton.label">
                  {{ data.renderSelectedOptions.rightButton.label }}
                </div>
              </u-button>
            </div>
          </div>
        </cdk-virtual-scroll-viewport>
      </ng-scrollbar>
    </div>
  </div>
</section>
<div class="buttons-container" *ngIf="!data?.styleOptions?.hidesButtonsContainer">
  <u-button
    id="cancel"
    label="Cancel"
    class="cancel"
    type="secondary"
    [styles]="{ width: '79px', height: '32px' }"
    (onClick)="onCancelClick('cancel')"
  >
  </u-button>
  <ng-container *ngTemplateOutlet="addButton; context: { disabled: !selectedItem || selectedItem?.isInvalid }"></ng-container>
</div>

<ng-template #addButton let-disabled="disabled">
  <u-button
    class="add-button"
    id="add"
    [label]="data?.buttonText || 'Add'"
    type="primary"
    [disabled]="disabled"
    [styles]="{ width: '79px', height: '32px' }"
    (onClick)="onPrimaryClick('add')"
  >
  </u-button>
</ng-template>
