import { Assistants, Filters, Search } from '@local/client-contracts';
import { EventInfo, LogService } from '@shared/services';
import { AssistantsService } from '@shared/services/assistants.service';
import { Logger } from '@unleash-tech/js-logger';
import { Subscription } from 'rxjs';
import {
  AssistantTicketResultType,
  getAssistantTicketResultTitle,
} from 'src/app/bar/views/assistant-incontext/models/assistant-ticket-result-type.enum';
import { AnswerSearchItem, TelemetryTrigger, TimeActionItem } from 'src/app/bar/views/results/models/results-types';
import { ResultMarkdownService } from '../../../result-markdown.service';
import { ResultsService } from '../../../results.service';
import { SearchClient } from '../search-client';
import { SearchRequest } from '../search-request';
import { SearchResponse } from '../search-response';
import { SearchResponseType } from '../search-response-type';
import { AssistantIncontextExtraData } from './assistant-incontext-extra-data';
import { AssistantIncontextSourceSettings } from './assistant-incontext-source-settings';
import { AnswerResourcesService } from '../../../answer-resources.service';
import { AnswerSearchService } from '../../../answers-search.service';

export class AssistantIncontextSearchClient implements SearchClient<AssistantIncontextSourceSettings> {
  private logger: Logger;
  private instances: { [sessionName: string]: Subscription } = {};

  constructor(
    logService: LogService,
    private assistantService: AssistantsService,
    private resultMarkdownService: ResultMarkdownService,
    private resultsService: ResultsService,
    private answerResourcesService: AnswerResourcesService,
    private answerSearchService: AnswerSearchService
  ) {
    this.logger = logService.scope('answers');
  }

  supportsSort(_sort: Search.Sort): boolean {
    return true;
  }

  supportsFilters(_filters: Filters.Values): boolean {
    return true;
  }

  search(request: SearchRequest<AssistantIncontextSourceSettings>, response: SearchResponse): SearchResponseType {
    const subscription = this.instances[request.sessionName];
    if (subscription) {
      subscription.unsubscribe();
      this.instances[request.sessionName] = null;
    }
    return this.innerSearch(request, response);
  }

  nextPage(
    _request: SearchRequest<AssistantIncontextSourceSettings>,
    _response: SearchResponse,
    _trigger: TelemetryTrigger
  ): Promise<void> {
    return;
  }

  destroy(_id: number, sessionName: string): void {
    const subscription = this.instances[sessionName];
    if (subscription) {
      subscription?.unsubscribe();
      delete this.instances[sessionName];
    }
    this.answerResourcesService.clearResources();
  }

  private async innerSearch(request: SearchRequest<AssistantIncontextSourceSettings>, response: SearchResponse) {
    if (!request.sourceSettings.data.ticket?.description && !request.sourceSettings.data.ticket?.messages?.length) {
      response.items = [];
      response.complete(true);
      return;
    }

    const extraData: AssistantIncontextExtraData[] = Object.entries(AssistantTicketResultType).map((e) => ({
      name: e[0],
      title: getAssistantTicketResultTitle(request.sourceSettings.data.type, e[0], e[1]),
      totalResults: 0,
    }));

    extraData.push({ name: 'Answer', title: 'Resolution', results: [] });

    try {
      this.instances[request.sessionName] = this.assistantService.ticket$(request.sourceSettings.data).subscribe({
        next: async (context: Assistants.AssistantTicketContext) => {
          if (response.cancelled) {
            return;
          }

          if (context.performance) {
            response.extra = {
              performance: context.performance,
              searchId: context.id,
            };
          }

          this.handleResults(context.response, request, response, extraData);
        },
        error: (ex) => {
          this.logger.error('got error in assistant incontext search client', ex);
          response.error = ex;
          response.complete(true);
        },
      });
    } catch (ex) {
      this.logger.error('got error in assistant incontext search client', ex);
      response.error = ex;
      response.complete(true);
    }
  }

  private async handleResults(
    res: Assistants.AssistantTicketResponse,
    request: SearchRequest<AssistantIncontextSourceSettings>,
    response: SearchResponse,
    extraData: AssistantIncontextExtraData[]
  ) {
    if (!res) {
      return;
    }
    const query = request.sourceSettings.data?.ticket?.title;
    const answerExtraData = extraData.find((e) => e.name === 'Answer');
    switch (res.status) {
      case 'Generating':
      case 'GeneratingDone':
      case 'Full': {
        const answer = await this.onAnswerReady(response, res, query);
        if (response.cancelled) {
          return;
        }
        if (answer) {
          const resolutionTimeItem = this.addTimeResolutionResult(res.resolutionTime);
          answerExtraData.results = [answer, resolutionTimeItem];
        }
        this.updateGroups(res, extraData);
        break;
      }
    }
    response.extra = {
      ...(response.extra || {}),
      extraData,
    };
    if (res.searchId) {
      response.extra.searchId = res.searchId;
    }
    if (res.status) {
      response.extra.responseStatus = res.status;
    }
    response.complete(true);
  }

  private updateGroups(res: Assistants.AssistantTicketResponse, extraData: AssistantIncontextExtraData[]) {
    if (!res.resultsGroup?.length) {
      return;
    }
    res.resultsGroup.forEach((result) => {
      const typeExtraData = extraData.find((e) => e.name === result.name);
      if (typeExtraData) {
        typeExtraData.results = result.results;
        typeExtraData.totalResults = result.results?.length || 0;
      }
    });
  }

  private async onAnswerReady(response: SearchResponse, res: Assistants.AssistantTicketResponse, query: string): Promise<AnswerSearchItem> {
    if (!res.federatedAnswer?.answer) {
      return;
    }
    const items: any[] = res.results || [];
    let formattedAnswer: string = res.federatedAnswer.answer;

    for (const item of items) {
      item.action = await this.resultsService.getResultAction(item);
      if (response.cancelled) {
        return;
      }
    }

    this.answerResourcesService.resources = items as Search.ResultResourceItem[];

    if (res.federatedAnswer?.references) {
      formattedAnswer = this.answerSearchService.insertReferencesIntoText(res.federatedAnswer, items as Search.ResultResourceItem[]);
    }

    formattedAnswer = this.resultMarkdownService.render(
      formattedAnswer || '',
      res.federatedAnswer?.references?.length ? { items, sourceType: 'result-answer', searchId: res.searchId } : null
    );

    return {
      type: 'answer',
      resources: items,
      text: res.federatedAnswer.answer,
      state: 'Cache',
      query,
      formattedAnswer,
      debugInfo: res.debugInfo,
      searchId: res.searchId,
      references: res.federatedAnswer.references,
      resourceIds: res.federatedAnswer.resourceIds,
    };
  }

  private addTimeResolutionResult(resolutionTime: number) {
    const time: TimeActionItem = {
      type: 'time-action',
      actionButton: { buttonIcon: 'sync', buttonTooltip: 'Refresh', buttonAction: 'refresh' },
      time: resolutionTime,
      timeText: 'Last updated',
      selectable: false,
    };
    return time;
  }

  getTelemetryEndEvent(_response: SearchResponse): Partial<EventInfo>[] {
    return;
  }
}
