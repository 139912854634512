import { Injectable } from '@angular/core';
import { Collections, Wiki } from '@local/client-contracts';
import { LogService, ServicesRpcService } from '@shared/services';
import { Logger } from '@unleash-tech/js-logger';
import { BehaviorSubject, Observable } from 'rxjs';
import { observable } from '@local/common';
import { WikiFoldersRpcInvoker } from '../invokers/wiki-folders-rpc-invoker';
import { WikisService } from './wikis.service';

@Injectable()
export class WikiFoldersService {
  private service: Wiki.FoldersService;
  private logger: Logger;
  private _all$ = new BehaviorSubject<Wiki.Folder[]>(null);

  @observable
  get all$(): Observable<Wiki.Folder[]> {
    return this._all$;
  }

  constructor(
    logger: LogService,
    services: ServicesRpcService,
    private wikisService: WikisService
  ) {
    this.logger = logger.scope('WikiFoldersService');
    this.service = services.invokeWith(WikiFoldersRpcInvoker, 'wikifolders');
    this.service.all$.subscribe((all) => {
      this._all$.next(all);
    });
  }

  update(id: string, actions: Collections.UpdateAction[]): Promise<void> {
    return this.service.update(id, actions);
  }

  async refresh(): Promise<string> {
    return this.service.refresh();
  }
}
