import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MultiSelectModule } from 'primeng/multiselect';
import { FormsModule } from '@angular/forms';
import { UMultiSelectComponent } from './u-multi-select.component';
import { UIconModule } from '../u-icon/u-icon.module';
import { UButtonModule } from '../u-button/u-button.module';
import { UiInfraModule } from '../../ui-infra.module';
import { ChipModule } from 'primeng/chip';


@NgModule({
  declarations: [UMultiSelectComponent],
  imports: [CommonModule, MultiSelectModule, FormsModule, UIconModule, UButtonModule, UiInfraModule, ChipModule],
  exports: [UMultiSelectComponent],
})
export class UMultiSelectModule {}
