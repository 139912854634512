import { Injectable } from '@angular/core';
import { SearchResultContext } from 'src/app/bar/services/search';
import { listNameByType, QueryItem, SearchResults } from '../../results';
import { SourceResultItems } from 'src/app/bar/services/search/models/source-results-items.model';
import { Constants, SimpleTextRanker } from '@local/common';
import { MemorySearchService } from '@shared/services/memory-search.service';
import { ResultCommandService } from 'src/app/bar/services/commands/result-command.service';
import { WorkspacesService } from 'src/app/bar/services/workspaces.service';
import { SourceResult } from 'src/app/bar/services/search/source-result';

@Injectable({
  providedIn: 'root',
})
export class HomeSearchPopupService {
  constructor(
    private memorySearch: MemorySearchService,
    private resultCommandService: ResultCommandService,
    private workspacesService: WorkspacesService
  ) {}

  getSearchResults(query: string, ctx: SearchResultContext, sliceGroupsSize: boolean) {
    if (sliceGroupsSize) {
      ctx = this.sliceGroupsSizeIfNeeded(ctx, query);
    }
    ctx.items = this.filterUniqueItems(ctx.items);
    const displayedContext = { ...ctx, items: this.rankResultItems(query, ctx) };

    displayedContext.items.forEach((item, i) => {
      item.resourceList = listNameByType(item);
      item.resultIndex = this.getRealPosition(displayedContext, i);
    });
    return displayedContext;
  }

  private removeHeader(source: SourceResult) {
    const items = source.items as SearchResults[];
    if (items[0].type === 'header') {
      items.shift();
    }
    return items;
  }

  private sliceGroupsSizeIfNeeded(ctx: SearchResultContext, query: string) {
    if (query.startsWith('go/') && !this.workspacesService.isFeatureDisabled(Constants.DISABLED_GO_LINKS_WORKSPACE_FEATURE_FLAG)) {
      ctx.sources[0].items = this.removeFooter(ctx.sources[0].items as SearchResults[]);
    }

    const resultsOnly = ctx.items.filter((item) => item.type !== 'header');
    if (resultsOnly?.length === 1 && resultsOnly[0].type === 'web-search') {
      const webSourceIndex = ctx.sources.findIndex((source) => source.source.type === 'web-search');
      ctx.sources[webSourceIndex].items = this.removeHeader(ctx.sources[webSourceIndex]);
    }
    return ctx;
  }

  private getRealPosition(displayedContext, index: number) {
    return this.resultCommandService.getRealPosition(displayedContext.items, index, displayedContext.lastHeaderIndex);
  }

  private removeFooter(items: SearchResults[]) {
    if (items[items.length - 1]?.type === 'header') {
      items.pop();
    }
    return items;
  }

  private filterUniqueItems(results: SearchResults[]) {
    const dedup = {};
    return results.filter((i) => {
      const r = !(<any>i).resource?.id || !dedup[(<any>i).resource?.id];
      dedup[(<any>i).resource?.id || ''] = true;
      return r;
    });
  }

  private rankResultItems(query: string, ctx: SearchResultContext): SearchResults[] {
    const sourceItems: (SearchResults & { sourceIndex: number })[] = [];
    for (let i = 0; i < ctx.sources?.length; i++) {
      this.flatItems(ctx.sources[i].items, sourceItems, i);
    }

    const items = this.rankRelevantItems(
      query,
      sourceItems.filter((a) => a.type !== 'header')
    );
    const results: SearchResults[] = [];
    const sourcesIndices = new Set<number>();
    for (const item of items) {
      const sourceIndex: number = (<any>item).sourceIndex;
      if (sourcesIndices.has(sourceIndex)) {
        continue;
      }
      results.push(
        ...(Array.isArray(ctx.sources[sourceIndex].items)
          ? ctx.sources[sourceIndex].items.flat()
          : (ctx.sources[sourceIndex].items as SearchResults[]))
      );
      sourcesIndices.add(sourceIndex);
    }

    return results;
  }

  private flatItems(items: SourceResultItems, sourceItems: (SearchResults & { sourceIndex: number })[], i: number) {
    for (const item of items || []) {
      if (Array.isArray(item)) {
        this.flatItems(item, sourceItems, i);
        continue;
      }
      sourceItems.push({ ...(item as SearchResults), sourceIndex: i });
    }
  }

  private rankRelevantItems(query: string, items: SearchResults[]): SearchResults[] {
    return items.sort((a: SearchResults, b: SearchResults) => {
      const queryTokens = query ? this.memorySearch.tokenize(query) : [];

      if (a.source === 'fill-questionnaire') return -1;
      if (b.source === 'fill-questionnaire') return 1;

      if (a.source === 'answers') return -1;
      if (b.source === 'answers') return 1;

      if (a.source === 'static-items') return -1;
      if (b.source === 'static-items') return 1;

      if (a.source === b.source && a.type === 'header') return -1;
      if (a.source === b.source && b.type === 'header') return 1;

      if (a.source === 'calculator') return -1;

      if (b.source === 'calculator') return 1;

      if (a.source === 'web-search' && b.source !== 'web-search') return 1;

      if (b.source === 'web-search' && a.source !== 'web-search') return -1;

      if (a.source === 'link-resources' && b.source !== 'link-resources') return 1;

      if (b.source === 'link-resources' && a.source !== 'link-resources') return -1;

      if (a.type == 'query' && b.type == 'query') {
        const qa = <QueryItem>a;
        const qb = <QueryItem>b;

        if (qa.class == 'term-in' && qb.class != 'term-in') return 1;

        if (qb.class == 'term-in' && qa.class != 'term-in') return -1;
      }
      const textRanker = new SimpleTextRanker();
      const textRank = textRanker.rank(
        queryTokens,
        a.searchTokens || this.memorySearch.tokenize(this.getItemText(a)),
        b.searchTokens || this.memorySearch.tokenize(this.getItemText(b))
      );

      if (textRank != 0) return textRank;

      if (a.source == 'favorites' && b.source != 'favorites') return -1;

      if (b.source == 'favorites' && a.source != 'favorites') return 1;

      return a.displayIndex - b.displayIndex;
    });
  }

  private getItemText(item: any) {
    if (item.displayName) return item.displayName;

    if (item.view) return item.view.title?.text + ' ' + item.view.subtitle?.text;

    return (item.title || '') + ' ' + (item.subtitle || '');
  }
}
