import { NgZone, Provider } from '@angular/core';
import { WebSocketRpcChannel, observable, performanceCheckpoint } from '@local/common';
import { isEmbed, isNativeWindow } from '@local/common-web';
import { LogWriter } from '@unleash-tech/js-logger';
import { Rpc, RpcChannel, RpcLazyChannel, RpcObservableExtension, RpcOptions, RpcWebPortChannel } from '@unleash-tech/js-rpc';
import { Observable, Observer, Subscription, firstValueFrom } from 'rxjs';
import { servicesWorker } from 'src/services';
import * as uuid from 'uuid';

class MedObserver<T> implements Observer<T> {
  constructor(
    private isAng: boolean,
    private ngzone: NgZone,
    private obs: Observer<T>
  ) {}
  private run(x: () => void) {
    if (this.isAng && !NgZone.isInAngularZone()) this.ngzone.run(x);
    else if (!this.isAng && NgZone.isInAngularZone()) this.ngzone.runOutsideAngular(x);
    else x();
  }
  next(value: T) {
    this.run(() => this.obs.next(value));
  }
  error(err: any) {
    this.run(() => this.obs.error(err));
  }
  complete() {
    this.run(() => this.obs.complete());
  }
}

export class RpcService {
  private subscriptions: Subscription[] = [];
  private handlers: { name: string; cb: any }[] = [];
  private destroyed = false;

  constructor(
    public rpc: Rpc,
    private ngzone: NgZone,
    private destroyable: boolean = false
  ) {}

  destroy() {
    if (!this.destroyable) throw new Error('destroying this host is not allowed!!');
    if (this.destroyed) throw new Error('accessing a destroyed host');
    this.destroyed = true;
    for (const s of this.subscriptions) {
      s.unsubscribe();
    }
    for (const c of this.handlers) {
      this.rpc.unhandle(c.name, c.cb);
    }
  }

  handle(channel: string, callback): ServicesRpcService {
    if (this.destroyed) throw new Error('accessing a destroyed host');
    const ncallback = (...args) => this.ngzone.run(() => callback(...args));
    this.rpc.handle(channel, ncallback);
    this.handlers.push({ name: channel, cb: ncallback });
    return this;
  }

  handleWith<T>(handler: T | (new (...args: any[]) => T), interfaceName?: string): void {
    if (this.destroyed) throw new Error('accessing a destroyed host');
    return this.rpc.handleWith(handler, interfaceName);
  }

  invoke<T = any>(channel: string, ...args: any[]): Promise<T> {
    if (this.destroyed) throw new Error('accessing a destroyed host');
    return this.rpc.invoke(channel, false, ...args);
  }

  invokeWith<T>(ClassType: new (...args: any[]) => T, interfaceName?: string) {
    if (this.destroyed) throw new Error('accessing a destroyed host');
    return this.rpc.invokeWith<T>(ClassType, interfaceName);
  }

  @observable
  observable<T = any>(channel: string, ...args: any[]): Observable<T> {
    if (this.destroyed) throw new Error('accessing a destroyed host');
    let innerObservableTask: Promise<Observable<T>> = null;

    return new Observable<T>((subscriber) => {
      let subscription: Subscription = null;

      if (!innerObservableTask) {
        innerObservableTask = this.invoke(channel, ...args);
      }

      innerObservableTask.catch((e) => {
        throw new Error('failed to register on observable: ' + e);
      });

      innerObservableTask.then((observable) => {
        subscription = observable.subscribe(
          (x) => subscriber.next(<any>x),
          (e) => subscriber.error(e),
          () => subscriber.complete()
        );
        this.subscriptions.push(subscription);
      });

      return () => {
        if (subscription) {
          this.subscriptions = this.subscriptions.filter((s) => s != subscription);
          subscription.unsubscribe();
        }
      };
    });
  }
}

export class NativeMainRpcService extends RpcService {}
export class NativeServicesRpcService extends RpcService {}
export class ServicesRpcService extends RpcService {}

export class NgZoneRpc extends Rpc {
  ngzone: NgZone;
  constructor(channel: RpcChannel, logWriter: LogWriter, options?: RpcOptions) {
    super(channel, logWriter, options);
  }

  async invoke(method: string, async: boolean, ...args: any[]): Promise<any> {
    const inAngular = NgZone.isInAngularZone();
    const r = await super.invoke(method, async, ...args);
    if (r?.subscribe) {
      const org = (<Observable<any>>r).subscribe.bind(r);
      (<Observable<any>>r).subscribe = <any>((a: any, b, c) => {
        let x = a;
        if (typeof a == 'function') {
          x = { next: a, error: b, complete: c };
        }
        return org(new MedObserver(inAngular, this.ngzone, x));
      });
    }
    return r;
  }
}

export let nativeMainRpc: NgZoneRpc = null;
let nativeServicesRpc = null;
const servicesRpcCh = new RpcLazyChannel();
export const servicesRpc = new NgZoneRpc(servicesRpcCh, null);

export function initRpcServices() {
  if (!(<any>self).initTenant) {
    (<any>self).webLoad = (<any>self).webLoad || { stage: { isEmbed: isEmbed() } };
    (<any>self).webLoad.stage['initRpcServices-start'] = true;
    servicesRpc.addExtension(new RpcObservableExtension());

    const channelToServicesWorker = new MessageChannel();

    const rpcChannelToServicesWorker = new RpcWebPortChannel(channelToServicesWorker.port2);
    servicesRpcCh.bind(rpcChannelToServicesWorker);

    (<any>self).webLoad = (<any>self).webLoad || { stage: { isEmbed: isEmbed() } };
    (<any>self).webLoad.stage['initRpcServices-mid'] = true;
    servicesWorker.then(async (worker) => {
      (<any>self).webLoad = (<any>self).webLoad || { stage: { isEmbed: isEmbed() } };
      (<any>self).webLoad.stage['initRpcServices-pre-options'] = true;
      const embedOpts: any = isEmbed() ? await firstValueFrom((<any>window).__embedService.options$) : null;
      (<any>self).webLoad = (<any>self).webLoad || { stage: { isEmbed: isEmbed() } };
      (<any>self).webLoad.stage['initRpcServices-link'] = true;

      performanceCheckpoint('link-services-worker');
      const linkId = uuid.v4();

      channelToServicesWorker.port2.addEventListener('message', (e) => {
        if (e.data.type == 'live:ack') (<any>self).webLoad.portTest = Date.now();
      });
      worker.postMessage(
        {
          type: 'link',
          id: linkId,
          port: channelToServicesWorker.port1,
          peer: (<any>window).__isOffscreen ? 'offscreen-host' : location.pathname.startsWith('/developer') ? 'developer' : 'app',
          peerName: location.pathname.startsWith('/developer') ? 'web:developer:main' : 'web:app:main',
          authentication: isEmbed()
            ? { origin: (<any>window).__isExtensionPage ? location.origin : embedOpts.origin, id: embedOpts.id }
            : { origin: location.origin },
        },
        [channelToServicesWorker.port1]
      );
      window.addEventListener('beforeunload', () => {
        servicesRpc.invoke('detachClient', true);
      });

      if (worker instanceof MessagePort) {
        worker.addEventListener('message', (e) => {
          if (e.data?.type == 'link:ack' && e.data.id == linkId) {
            (<any>self).webLoad.portLinked = { time: Date.now(), version: e.data.version, threadStartTime: e.data.startTime };
          }
          if (e.data?.type == 'link:ack:finish' && e.data.id == linkId) {
            (<any>self).webLoad.portLinked = (<any>self).webLoad.portLinked || {};
            (<any>self).webLoad.portLinked.succeeded = e.data.success;
          }
        });
      }
      (<any>self).webLoad = (<any>self).webLoad || { stage: { isEmbed: isEmbed() } };
      (<any>self).webLoad.stage['initRpcServices-post-link'] = true;
      (<any>self).webLoad.linkPostMessage = true;
      channelToServicesWorker.port1.start();
      channelToServicesWorker.port2.start();
    });
  }
  if (isNativeWindow()) {
    const nativeMainRpcCh = new RpcLazyChannel();
    nativeMainRpc = new NgZoneRpc(nativeMainRpcCh, null);
    nativeMainRpc.addExtension(new RpcObservableExtension());
    const nativeServicesRpcCh = new RpcLazyChannel();
    nativeServicesRpc = new NgZoneRpc(nativeServicesRpcCh, null);
    nativeServicesRpc.addExtension(new RpcObservableExtension());

    window.addEventListener('message', async (e) => {
      if (e.data?.type != 'unleash:native:ports') {
        return;
      }

      const channel = new RpcWebPortChannel(e.ports[0]);
      nativeMainRpcCh.bind(channel);
      (await servicesWorker).postMessage({ type: 'link', port: e.ports[1], peer: 'native:app', optional: true }, [e.ports[1]]);
      const { port, token } = e.data;
      const path = 'rpc/internal-services';
      const wsChannel = new WebSocketRpcChannel([port], path, token, 'web:app:main');
      nativeServicesRpcCh.bind(wsChannel);
      servicesRpc.invoke('init:native:services', false, { port, token });
    });

    (<any>window).initPorts();
  }
}
// provides a private host for a component.. so once the component itself is destroyed it can call host.destroy
// and remove all rpcs that were registered
export const componentServicesRpcProvider: Provider = {
  provide: ServicesRpcService,
  useFactory: function (ngzone: NgZone) {
    servicesRpc.ngzone = ngzone;
    return new RpcService(servicesRpc, ngzone, true);
  },
  deps: [NgZone],
};

export const globalServicesRpcProvider: Provider = {
  provide: ServicesRpcService,
  useFactory: function (ngzone: NgZone) {
    servicesRpc.ngzone = ngzone;
    return new RpcService(servicesRpc, ngzone, false);
  },
  deps: [NgZone],
};

export const globalNativeMainRpcProvider: Provider = {
  provide: NativeMainRpcService,
  useFactory: function (ngzone: NgZone) {
    if (nativeMainRpc) nativeMainRpc.ngzone = ngzone;
    return nativeMainRpc ? new RpcService(nativeMainRpc, ngzone, false) : null;
  },
  deps: [NgZone],
};

export const componentNativeMainRpcProvider: Provider = {
  provide: NativeMainRpcService,
  useFactory: function (ngzone: NgZone) {
    if (nativeMainRpc) nativeMainRpc.ngzone = ngzone;
    return nativeMainRpc ? new RpcService(nativeMainRpc, ngzone, true) : null;
  },
  deps: [NgZone],
};

export const globalNativeServiceRpcProvider: Provider = {
  provide: NativeServicesRpcService,
  useFactory: function (ngzone: NgZone) {
    if (nativeServicesRpc) nativeServicesRpc.ngzone = ngzone;
    return nativeServicesRpc ? new RpcService(nativeServicesRpc, ngzone, false) : null;
  },
  deps: [NgZone],
};

export const componentNativeServiceRpcProvider: Provider = {
  provide: NativeServicesRpcService,
  useFactory: function (ngzone: NgZone) {
    if (nativeServicesRpc) nativeServicesRpc.ngzone = ngzone;
    return nativeServicesRpc ? new RpcService(nativeServicesRpc, ngzone, true) : null;
  },
  deps: [NgZone],
};
