<ng-container *ngIf="!loadingError; else error">
  <div
    data-cy="file-preview-container"
    (mouseleave)="calcMetadataVisibility()"
    (mousemove)="calcMetadataVisibility()"
    class="preview-container"
    [class.preview-container-side]="!popup"
    [class.preview-container-full]="popup"
  >
    <header
      id="header"
      class="header-container"
      [class.header-popup]="popup"
      [ngClass]="[
        (metadataVisible && !!model) || fileType === 'code' ? 'show-metadata' : 'hide-metadata',
        !model?.view?.subtitle?.text ? 'header-baseline' : '',
      ]"
    >
      <result-ghost *ngIf="!model"></result-ghost>
      <div
        data-cy="file-preview-container-info"
        *ngIf="!!model"
        class="header-info"
        [ngStyle]="isLargeBreakpoint ? { 'max-width': headerWidth - 16 + 'px' } : { 'max-width': headerWidth + 'px ' }"
      >
        <span
          data-cy="file-preview-container-icon"
          class="header-icon"
          [ngClass]="{
            'header-icon-baseline': !model?.view?.subtitle?.text,
            'header-icon-baseline-popup': !model?.view?.subtitle?.text && popup,
          }"
        >
          <u-icon [model]="icon" [styles]="{ width: '25px', height: '25px', marginRight: '12px' }"> </u-icon>
        </span>
        <div class="header-content" [ngClass]="{ 'header-content-code': fileType === 'code' }">
          <div
            class="file-title-container"
            [ngClass]="{ 'popup-header-text': popup }"
            [pTooltip]="model.view.title?.text"
            tooltipPosition="bottom"
            tooltipStyleClass="u-tooltip"
          >
            <p #title [ngClass]="{ 'side-truncate': !popup, 'popup-truncate': popup }" class="title">
              {{ fileItemTitle.start?.text }}
            </p>
            <span class="file-title-end" [ngStyle]="headerWidth <= XS_HEADER_WIDTH ? { overflow: 'hidden' } : { overflow: 'visible' }">{{
              fileItemTitle.end
            }}</span>
          </div>
          <p
            #subtitle
            *ngIf="!popup"
            [ngClass]="{ 'popup-header-text': popup, 'side-truncate': !popup, 'popup-truncate': popup }"
            class="subtitle"
          >
            {{ model?.view?.subtitle?.text }}
          </p>
        </div>
      </div>
      <div class="header-buttons" *ngIf="!!model">
        <div class="popup-buttons-container">
          <ng-container *ngIf="model.type !== 'assets-file'">
            <u-icon
              *ngIf="!popup && fileType === 'code'"
              (click)="$event.stopPropagation(); onPreviewClick()"
              class="action font-icon"
              [model]="INDICATE_PREVIEW_ICON"
              pTooltip="Preview"
              tooltipPosition="top"
              tooltipStyleClass="u-tooltip"
            >
            </u-icon>
            <u-icon
              *ngIf="isAvailableSummary(model?.action)"
              class="action font-icon"
              [class.action-popup]="popup"
              (click)="onSummary($event)"
              [model]="INDICATE_SUMMARY_ICON"
              pTooltip="Show summary"
              tooltipPosition="top"
              tooltipStyleClass="u-tooltip"
            ></u-icon>
            <i
              *ngIf="allowOpenUrl"
              data-cy="file-preview-container-action-open"
              (click)="$event.stopPropagation(); openUrl(model?.view?.title?.onClick, null, 'mouse_click')"
              class="action font-icon icon-launch"
              [class.action-popup]="popup"
              [pTooltip]="'Open'"
              tooltipPosition="top"
              tooltipStyleClass="u-tooltip"
            >
            </i>
            <i
              data-cy="file-preview-container-action-download"
              *ngIf="!isFolder && isDownloadEnabled && model?.link?.appId !== 'pc'"
              (click)="download()"
              class="action font-icon icon-download2"
              [class.action-popup]="popup"
              [pTooltip]="'Download'"
              tooltipPosition="top"
              tooltipStyleClass="u-tooltip"
            >
            </i>

            <ng-container *featureFlagEnabled="DISABLED_FAVORITES_WORKSPACE_FEATURE_FLAG">
              <i
                data-cy="file-preview-container-action-favorite"
                *ngIf="model?.isFavorite"
                class="action action-no-border font-icon favorite"
                [class.action-popup]="popup"
              >
                <u-icon class="icon" [model]="{ type: 'font', value: 'icon-star-solid' }"></u-icon> </i
            ></ng-container>
            <square-button
              data-cy="file-preview-container-action-kebab"
              [active]="contextMenuHelper?.source === 'actions'"
              (click)="openContextMenu($event, 'actions')"
              [ngClass]="{ 'action-popup': popup }"
              >•••</square-button
            >
          </ng-container>
          <i
            data-cy="file-preview-container-action-close"
            *ngIf="popup && !hideCloseButtonInEmbed"
            (click)="onSizeChanged('previous', $event)"
            [class.action-popup]="popup"
            class="action action-no-border font-icon icon-times-large"
          >
          </i>
        </div>
      </div>
    </header>
    <section [class.side-preview-content]="size === 'side'" [class.full-preview-content]="popup">
      <file-preview
        data-cy="file-preview-content"
        class="file-preview"
        [model]="model"
        [showBar]="metadataVisible"
        [token]="sessionInfo?.token"
        [smallWidthPreview]="smallWidthPreview"
        [previewMode]="size"
        [icon]="icon"
        [isPopup]="isPopup"
        (loaded)="loaded.emit($event)"
        (size)="size$.next($event); onSizeChanged($event)"
        (download)="download($event)"
        (open)="openUrl(model?.view?.title?.onClick, null, 'mouse_click')"
      >
      </file-preview>
    </section>

    <footer id="footer" *ngIf="!popup && !!traits" [ngClass]="metadataVisible && !!model ? 'show-metadata' : 'hide-metadata'">
      <div *ngIf="createdBy || createAt" class="footer-content">
        <span>{{ !slackApp ? 'Created' : 'Sent' }}</span> {{ createdBy }} {{ createAt }}
      </div>
      <div *ngIf="(modifiedBy || modifiedAt) && !slackApp" class="footer-content">Last edited {{ modifiedBy }} {{ modifiedAt }}</div>
    </footer>
  </div>
</ng-container>

<ng-template #error>
  <preview-error [text]="'your file'" (again)="reload$.next(null)"></preview-error>
</ng-template>

<ng-template #restricted>
  <div class="restricted-container">
    <u-icon [model]="restrictedIcon"></u-icon>
    <div class="restricted-message">
      <span>Content related features will not be available for restricted links.</span>
      <a href="https://help.unleash.so/docs/restricted-links" target="_blank" class="link-button">{{ ' Learn more' }}</a>
    </div>
  </div>
</ng-template>
