import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SessionInfo } from '@local/client-contracts';
import { INIT_POSITION_POPUP, PopupRef, PopupService, UiIconModel, UInputComponent } from '@local/ui-infra';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SessionService } from '@shared/services/session.service';
import { filter, firstValueFrom, map, startWith } from 'rxjs';
import { SearchOptions, SearchResultContext, SearchService, SearchSession } from 'src/app/bar/services/search';
import { cloneDeep } from 'lodash';
import { Constants, performanceCheckpoint } from '@local/common';
import { ChatsService } from '../../../chat-page/services/chats.service';
import { RouterService } from '@shared/services/router.service';
import { LoaderService } from '@shared/loader.service';
import {
  HomeSearchPopupComponent,
  HomeSearchPopupData,
} from '../../../home-search-popup/components/home-search-popup/home-search-popup.component';
import { isEnterKey, KeyName } from '@local/ts-infra';
import { CustomKeyboardEvent, KeyboardService } from '@shared/services/keyboard.service';
import { HomeSearchPopupService } from '../../../home-search-popup/services/home-search-popup.service';
import { HomeSearchSettings } from '../../../home-search-popup/home-search-settings';
import { LinkResourcesSourceSettings } from 'src/app/bar/services/search/client';
import { LinksService } from '@shared/services/links.service';
import { RetrySourceModel } from 'src/app/bar/services/search/models/retry-source-modal';
import { SIDE_PANEL_SEARCH_PAGE_PATH } from 'src/app/bar/utils/constants';
import { RETRY_COUNT_INCREMENT, SIDE_PANEL_FOOTER_TITLE } from '../../helpers/side-panel-constants';
import { SearchResults } from '../../../results';
import { WorkspacesService } from 'src/app/bar/services';

@UntilDestroy()
@Component({
  selector: 'home-side-panel',
  templateUrl: './home-side-panel.component.html',
  styleUrls: ['./home-side-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeSidePanelComponent implements OnInit, OnDestroy {
  private readonly HOME_SIDE_PANEL_TAB_ID = 'home-side-panel';
  private readonly PADDING_OPEN_POPUP = 48;
  private popupRef: PopupRef<HomeSearchPopupComponent, HomeSearchPopupData>;
  private searchSession: SearchSession;
  private query: string;
  readonly subTitleMessage = 'How can I help you today?';
  readonly ENTER_INDICATION_INPUT_PADDING: number = 134;
  readonly INPUT_PADDING: number = 30;
  userFirstName: string;
  search: string;
  searchIcon: UiIconModel = { type: 'font', value: 'icon-search-icon-fixed' };
  placeHolder = 'Search or ask anything';
  displayedContext: SearchResultContext;
  isSearchLoading: boolean;
  isSearchPopupOpen = false;
  selectedItem: boolean;
  emptySearchItems = false;
  @ViewChild('searchElement') searchElement: UInputComponent;

  constructor(
    private sessionService: SessionService,
    private searchHomeSidePanelService: HomeSearchPopupService,
    private searchService: SearchService,
    private cdr: ChangeDetectorRef,
    private popupService: PopupService,
    private chatsService: ChatsService,
    private routerService: RouterService,
    private loaderService: LoaderService,
    private keyboardService: KeyboardService,
    private linksService: LinksService,
    private workspacesService: WorkspacesService
  ) {
    this.linksService.all$.pipe(untilDestroyed(this), startWith(null));
    this.searchSession = this.searchService.getOrCreateSearchSession(this.HOME_SIDE_PANEL_TAB_ID);
  }

  ngOnDestroy() {
    if (this.popupRef) {
      this.popupRef.destroy();
    }
  }

  ngOnInit(): void {
    this.loaderService.ready$.next(false);
    this.keyboardService.registerKeyHandler((keys, event) => this.handleKeys(keys, event), 0);
    this.loadUserInfo();
    this.checkViewReady();
    this.cdr.markForCheck();
  }

  private checkViewReady(): void {
    this.loaderService.ready$.next(true);
    setTimeout(() => {
      this.focusSearchInput();
    }, 0);
  }

  private loadUserInfo(): void {
    this.sessionService.current$
      .pipe(
        untilDestroyed(this),
        filter((session) => !!session),
        map((session: SessionInfo) => session.user)
      )
      .subscribe((u) => {
        performanceCheckpoint('user_fname_ready');
        this.userFirstName = u.firstName || '';
      });
  }

  private async getSearhOptions(query: string): Promise<SearchOptions> {
    let view = !query?.length ? 'default' : 'search';
    if (
      query?.toLowerCase()?.trim()?.startsWith('go/') &&
      !this.workspacesService.isFeatureDisabled(Constants.DISABLED_GO_LINKS_WORKSPACE_FEATURE_FLAG)
    ) {
      view = 'goLinks';
    }
    const sources = cloneDeep(HomeSearchSettings.sidePanelSettings[view]);
    for (const source of sources) {
      if (source.header?.clickable) {
        source.header.clickable = false;
      }
      source.footer = { title: SIDE_PANEL_FOOTER_TITLE };
      if (!source?.filters?.excludeFilters?.includes('Person')) {
        continue;
      }
      if (source.type === 'link-resources') {
        (source as LinkResourcesSourceSettings).tag = 'user_query-home-page';
        const peopleLinkId = await firstValueFrom(this.linksService.peopleLinkId$);
        if (peopleLinkId) {
          source.filters.excludeFilters.push('PeopleLink');
        }
      }
    }
    const options: SearchOptions = {
      resetSession: !query?.length,
      query: query,
      sources,
      trigger: 'user_query',
    };
    return options;
  }

  private searchFocus() {
    this.searchElement.focusInput();
  }

  private setSearchPopupData(): HomeSearchPopupData {
    const data: HomeSearchPopupData = {
      popupWidth: this.searchElement['inputElement'].el.nativeElement.offsetWidth,
      search: this.search,
      redirectPath: SIDE_PANEL_SEARCH_PAGE_PATH,
      searchData: {
        items: this.displayedContext.items,
        sessionId: this.displayedContext.sessionId,
        clientSearchId: this.displayedContext.clientSearchId,
        lastHeaderIndex: this.displayedContext.lastHeaderIndex,
        searchCompleted: this.displayedContext.searchCompleted,
        sources: this.displayedContext.sources,
      },
      isExtension: true,
    };
    return data;
  }

  private openSearchPopup() {
    const data: HomeSearchPopupData = this.setSearchPopupData();
    if (this.popupRef) {
      this.popupRef.update(data);
      return;
    }

    const { x, y } = this.searchElement['inputElement'].el.nativeElement.getBoundingClientRect();
    const top: number = y + this.PADDING_OPEN_POPUP;
    this.popupRef = this.popupService.open<HomeSearchPopupComponent, HomeSearchPopupData>(
      { left: x, top },
      HomeSearchPopupComponent,
      data,
      {
        position: INIT_POSITION_POPUP,
      }
    );

    this.isSearchPopupOpen = true;
    this.cdr.markForCheck();
    this.focusSearchInput();

    this.popupRef.compInstance.onClearSearch.pipe(untilDestroyed(this)).subscribe(async () => {
      await this.onSearch('');
      this.cdr.markForCheck();
    });

    this.popupRef.compInstance.onOpenChat.pipe(untilDestroyed(this)).subscribe(async (assistantId) => {
      this.chatsService.openChat(assistantId, this.search);
    });

    this.popupRef.compInstance.onReOpen.pipe(untilDestroyed(this)).subscribe(() => {
      this.openSearchPopup();
    });

    this.popupRef.compInstance.removeFocus.pipe(untilDestroyed(this)).subscribe(() => {
      this.removeFocusSearchInput();
    });

    this.popupRef.compInstance.onOpenPopup.pipe(untilDestroyed(this)).subscribe(() => {
      this.popupRef.destroy();
      this.popupRef = null;
      this.isSearchPopupOpen = false;
      this.cdr.markForCheck();
    });

    this.popupRef.compInstance.onClosePopup.pipe(untilDestroyed(this)).subscribe(() => {
      this.isSearchPopupOpen = false;
      this.search = '';
      this.cdr.markForCheck();
    });

    this.popupRef.compInstance.onSelectedIndex.pipe(untilDestroyed(this)).subscribe((value) => {
      this.selectedItem = !!value || value === 0;
      this.cdr.markForCheck();
    });

    this.popupRef.compInstance.onSelectGroup.pipe(untilDestroyed(this)).subscribe((headerItem) => {
      this.expandSearchGroup(headerItem);
    });

    this.popupRef.close$.pipe(untilDestroyed(this)).subscribe(() => {
      this.popupRef = null;
      this.isSearchPopupOpen = false;
      this.cdr.markForCheck();
    });
  }

  private expandSearchGroup(headerItem: SearchResults) {
    const group = headerItem?.clientId || headerItem?.source;
    const retrySources: RetrySourceModel[] = [];
    const groupSourceIndex = this.displayedContext.sources.findIndex((item) => (item.source.id || item.source.type) === group);
    if (groupSourceIndex === -1) return;
    const retrySource = this.displayedContext.sources[groupSourceIndex];
    const retrySourceSettings = retrySource.source;
    if ('maxCount' in retrySourceSettings) {
      const countCurrentItems = retrySource.items.length;
      retrySourceSettings.maxCount = countCurrentItems + RETRY_COUNT_INCREMENT;
    }
    retrySources.push({ sourceIndex: groupSourceIndex, sourceSettings: retrySourceSettings });
    this.searchSession.retrySources(retrySources);
  }

  private removeFocusSearchInput() {
    if (!this.isInputFocused()) {
      return;
    }
    this.searchElement?.inputElement.el.nativeElement.blur();
  }

  private isInputFocused() {
    const input = this.searchElement?.inputElement?.el?.nativeElement;
    return document.activeElement === input;
  }

  private focusSearchInput() {
    if (this.isInputFocused() || this.popupService.hasDialog) {
      return;
    }
    this.searchFocus();
  }

  private closeSearchPopup() {
    this.isSearchPopupOpen = false;
    if (this.popupRef) {
      this.popupRef.destroy();
      this.popupRef = null;
    }
  }

  async onSearch($event: string) {
    this.query = $event;
    this.search = this.query;
    if (!this.query || this.query.length <= 0 || this.query === '') {
      this.search = '';
      this.closeSearchPopup();
      return;
    }
    const options = await this.getSearhOptions(this.query);
    this.searchSession
      .search$(options)
      .pipe(untilDestroyed(this))
      .subscribe((res: SearchResultContext) => {
        const ctx = cloneDeep(res);
        if (!ctx.sources || ctx.sources.some((s) => !s.done && !['link-resources', 'web-search'].includes(s.source.type))) {
          return;
        }
        const linkSearch = ctx.sources?.find((x) => x.source.type == 'link-resources');
        if (linkSearch && !linkSearch.done) ctx.sources = ctx.sources.filter((x) => x.source.type != 'web-search');

        const groupSourcesDone = ctx.sources
          .filter((s) => s.source.type != 'web-search')
          .every((s) => s.done || (s.source.type === 'link-resources' && s.extra?.localDone));

        this.displayedContext = this.searchHomeSidePanelService.getSearchResults(this.query, ctx, groupSourcesDone);
        const hasNoSearchItems = ctx.items.length === 0;
        if (this.search) {
          this.isSearchLoading = false;
          if (ctx.searchCompleted || groupSourcesDone) {
            if (hasNoSearchItems) {
              this.emptySearchItems = true;
              this.closeSearchPopup();
              this.cdr.markForCheck();
              return;
            }

            const shouldOpenPopup =
              !this.popupService.hasDialog || (Object.keys(this.popupService.refs).length === 1 && this.isSearchPopupOpen);
            if (shouldOpenPopup) {
              this.emptySearchItems = false;
              this.openSearchPopup();
            }
          }
          this.cdr.markForCheck();
        }
      });
  }

  onEnterPressed() {
    const url = `${SIDE_PANEL_SEARCH_PAGE_PATH}?q=${this.search}`;
    this.search = '';
    this.routerService.navigateByUrl(url);
    this.closeSearchPopup();
  }

  handleKeys(keys: Array<KeyName>, event: CustomKeyboardEvent): void {
    const key = keys[0];

    if (isEnterKey(key) && this.searchKeyIsNotEmpty()) {
      this.onEnterPressed();
      event.stopPropagation();
      return;
    }

    if (isEnterKey(key) && this.popupRef) {
      event.stopPropagation();
      return;
    }
  }

  searchKeyIsNotEmpty() {
    return this.search && this.search.length > 0 && this.search !== '';
  }

  onInputClick() {
    if (!this.isSearchPopupOpen) {
      this.onSearch(this.search);
    }
  }
}
