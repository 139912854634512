import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TelemetryService } from '@shared/services';
import { KeyboardService } from '@shared/services/keyboard.service';
import { HubService } from 'src/app/bar/services/hub.service';
import { Filter, isGroupFilterValue } from '../models';
import { MultiSelectFilterBaseComponent } from '../multi-select-filter-base.component';

@UntilDestroy()
@Component({
  selector: 'multi-select-wrapper',
  templateUrl: './multi-select-wrapper.component.html',
  styleUrls: ['./multi-select-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiSelectWrapperComponent extends MultiSelectFilterBaseComponent<Filter> implements OnDestroy {
  @Input() showItemIcon: boolean;
  @Input() onlyIcon = false;
  @Input() showItemLabel: boolean;
  @Input() showItemIconLabel: boolean;
  @Input() isFullDetailLine: boolean;
  @Input() isTwoLine: boolean;
  @Input() fullDetailIds: string[] = [];
  @Input() hideTitle;
  @Input() clearAllTooltipContent: string;
  @Input() set limitShowSelectedLabels(value) {
    if (value >= 0) {
      this._limitShowSelectedLabels = value;
    }
  }
  readonly MAX_CHARACTERS: number = 25;
  private readonly SMALL_ITEM_HEIGHT: number = 37;
  private readonly LARGE_ITEM_HEIGHT: number = 46;
  private _limitShowSelectedLabels = 2;

  get titlesToDisplay() {
    if (this.selectedItems.length) {
      return this.selectedItems.slice(0, this.limitShowSelectedLabels).map((i) => i.value);
    }
    return [this.model?.title];
  }

  get multiSelectIcon() {
    if (this.allowMultiSelectIcon) {
      return this.allowMultiSelectIcon;
    }
    return this.model.icon;
  }

  set model(val: Filter) {
    super.model = val;
    val.values.forEach((v) => {
      v.height = v.subtitle ? this.LARGE_ITEM_HEIGHT : this.SMALL_ITEM_HEIGHT;
      if (isGroupFilterValue(v)) {
        v.childFilter?.values?.forEach((a) => {
          a.height = a.subtitle ? this.LARGE_ITEM_HEIGHT : this.SMALL_ITEM_HEIGHT;
        });
      }
    });
  }

  get model() {
    return super.model;
  }

  get limitShowSelectedLabels() {
    return this._limitShowSelectedLabels;
  }

  constructor(
    public cdr: ChangeDetectorRef,
    protected telemetryService: TelemetryService,
    public ref: ElementRef,
    protected keyboardService: KeyboardService,
    protected hubService: HubService
  ) {
    super(cdr, telemetryService, ref, keyboardService, hubService);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  getMoreLabelsTooltip(): string {
    const items = this.selectedItems.slice(this.limitShowSelectedLabels).map((elm) => elm.value);
    return items.join(', ');
  }
}
