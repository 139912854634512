import { Directive, TemplateRef, ViewContainerRef, effect, inject, input } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';
import { WorkspacesService } from 'src/app/bar/services';

@UntilDestroy()
@Directive({
  selector: '[featureFlagEnabled]',
})
export class FeatureFlagEnabledDirective {
  private workspacesService = inject(WorkspacesService);
  private subscription: Subscription;

  featureFlagEnabled = input<string>(null);
  featureFlagEnabledElse = input<TemplateRef<any>>(null);

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {
    effect(() => {
      if (this.featureFlagEnabled()) {
        if (this.subscription) {
          this.subscription.unsubscribe();
        }
        this.subscription = this.workspacesService
          .isFeatureFlagDisabled$(this.featureFlagEnabled())
          .pipe(untilDestroyed(this))
          .subscribe((res) => {
            this.viewContainer.clear();
            if (!res) {
              if (this.templateRef) {
                this.viewContainer.createEmbeddedView(this.templateRef);
              }
            } else if (this.featureFlagEnabledElse()) {
              this.viewContainer.createEmbeddedView(this.featureFlagEnabledElse());
            }
          });
      } else {
        this.viewContainer.clear();
        this.viewContainer.createEmbeddedView(this.templateRef);
      }
    });
  }
}
