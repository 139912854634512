import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Config } from '@environments/config';
import { Module, performanceCheckpoint } from '@local/common';
import { WorkerEntry } from '@local/common-web';
import { pushTag } from '@shared/analytics';
import { EmbedService } from '@shared/embed.service';
import { initRpcServices } from '@shared/services';
import { isProdEnv } from '@shared/utils';
import { AppModule } from './app/app.module';
import { initServicesWorker } from './services';

const MODULES = {
  main: () => MainModule,
};
pushTag({ event: 'ng-page-load-start', track_time: performance.now() - (<any>self).__START_TIME });

let isEmbed = window.self != window.top;
const isProd = isProdEnv();
let testPlatform;
if (!isProd) {
  testPlatform = window.sessionStorage.getItem('test-platform');
  if (testPlatform) {
    (<any>self).testEmbed = isEmbed = testPlatform === 'embed';
  }
}

performanceCheckpoint('before-embed');

const embedService = isEmbed ? new EmbedService() : null;
(<any>window).__embedService = embedService;

class MainModule extends Module {
  async load() {
    (<any>window).__module = this;

    if (!(<any>self).initTenant) {
      setTimeout(() => {
        if ((<any>self).webLoad?.linkPostMessage) {
          return;
        }
        const msg = 'Client initialize timeout ' + JSON.stringify((<any>self).webLoad);
        this.logger.error(msg, { extra: (<any>self).webLoad });
      }, 10000);
    }
    initRpcServices();
    initServicesWorker(embedService);
    if (Config.environment != 'development') {
      enableProdMode();
    }
    if (!(<any>window).__isOffscreen) platformBrowserDynamic().bootstrapModule(AppModule);
  }

  link(): Promise<void> {
    return;
  }
}

performanceCheckpoint('main_module');
new WorkerEntry(Config, MODULES).run();
