import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { isUndefined } from 'lodash';
import { Table } from 'primeng/table';

export interface Column {
  field?: string;
  header: string;
  templateType?: string;
  class?: string;
  maxWidth?: string;
  minWidth?: string;
  displayCondition?: () => boolean;
}
@Component({
  selector: 'u-table',
  templateUrl: './u-table.component.html',
  styleUrls: ['./u-table.component.scss'],
})
export class UTableComponent implements AfterViewInit {
  @Input() data: any;
  @Input() cols: Column[];
  @Input() nodeTemplate: any;
  @Input() headerTemplate: any;
  @Input() styles: any = {};
  @Input() emptyMessage = 'No results';
  @Input() loading: boolean;
  @Input() scrollHeight = '400px';
  @Input() virtualScroll = false;
  @Input() virtualScrollItemSize: number;
  @Input() set searchKeyword(val: string) {
    if (isUndefined(val)) {
      return;
    }
    this.table.filterGlobal(val, 'contains');
  }

  @ViewChild('ptable') table: Table;

  ngAfterViewInit(): void {
    if (this.table.scroller) {
      this.table.scroller.scrollHeight = this.scrollHeight;
    }
  }
}
