<u-icon class="trial-expire-icon" [model]="{ type: 'img' }" [inSvg]="'/assets/signin/kyc/work.svg'"></u-icon>
<h1 class="trial-expire-title">Your trial period has ended</h1>

<ng-container *ngTemplateOutlet="isOwner ? ownerUpgradeMessage : upgradeMessage"></ng-container>

<a class="log-out" (click)="logOut()">Log out</a>

<!-- Templates -->
<ng-template #ownerUpgradeMessage>
  <div class="select-plan-text">
    Select a plan now to keep your information <br />
    at your fingertips
  </div>
  <u-button
    class="select-plan-button"
    [label]="'Select a Plan'"
    [type]="'primary'"
    (onClick)="selectPlanClicked.emit()"
    [styles]="{ fontSize: '14px', width: '188px' }"
  ></u-button>
  <div class="owner-admin-message"><span>Have a question?</span> <a class="contact-us" [href]="contactUsURL">Contact us</a></div>
</ng-template>

<ng-template #upgradeMessage>
  <div class="member-message">Please contact your administrator - {{ ownerEmail }},<br />to upgrade your account</div>
</ng-template>
