<div class="home-page">
  <div *ngIf="stickyHeader">
    <ng-container *ngTemplateOutlet="sticky"></ng-container>
  </div>
  <ng-scrollbar visibility="hover">
    <div *ngIf="!stickyHeader">
      <ng-container *ngTemplateOutlet="sticky"></ng-container>
    </div>
    <ng-container *ngIf="!loadingTabs">
      <home-tab
        [tab]="selectedTab"
        [isPopupOpen]="isSearchPopupOpen"
        [homePins]="homePins"
        [componentFocused]="componentFocused"
        [ownerOrAdmin]="ownerOrAdmin"
        (scrollToWidget)="scrollToWidget($event)"
        (removeFocusSearchInput)="removeFocusSearchInput()"
      ></home-tab> </ng-container
  ></ng-scrollbar>
</div>

<ng-template #sticky>
  <div
    class="home-page-header"
    [ngClass]="{ 'min-height-header': stickyHeader, banner: stickyHeader && isHubBannerActive }"
    [ngStyle]="stickyHeader ? { 'margin-right': widthHeader } : {}"
  >
    <div class="home-page-content">
      <div class="home-page-title" data-cy="home-page-title" *ngIf="displayTitle || !stickyHeader">
        <u-icon class="main-icon" *ngIf="workspaceBanner; else text" [model]="workspaceBanner"></u-icon>
        <ng-template class="text-title" #text>
          <ng-container *ngIf="workspaceBannerInit">
            <div *ngIf="!stickyHeader" class="date-headline">{{ dateHeadLine }}</div>
            <div class="headline" data-cy="home-headline">
              {{ subTitleMessage }}, <strong>{{ userFirstName }}</strong>
            </div></ng-container
          >
        </ng-template>
      </div>
      <div class="search-container" data-cy="home-search-container" (outsideClick)="outsideClick($event)">
        <u-input
          id="inputHome"
          #searchElement
          (onChange)="onSearch($event)"
          [model]="search"
          [inputSize]="stickyHeader ? 'medium' : 'large'"
          [placeholder]="placeHolder"
          [isSearchInput]="true"
          [isLoading]="false"
          [extraIcon]="inputIcon"
          [inlineExtraIcon]="searchIcon"
          [inlineExtraIconPos]="'left'"
          (click)="onSearch(search)"
          data-cy="home-search-input"
          [ngClass]="{ 'popup-open': isSearchPopupOpen, 'default-popup-open': isSearchPopupOpen && !search }"
          [class.second-popup]="isSecondPopup"
          [styles]="{ paddingRight: (!selectedItem ? ENTER_INDICATION_INPUT_PADDING : INPUT_PADDING) + 'px' }"
          [ignoreEnter]="true"
        >
          <div
            (click)="onEnterPressed()"
            class="enter-indication"
            [class.enter-indication-second-popup]="isSecondPopup"
            *ngIf="search && !selectedItem && !inputIcon"
          >
            <u-icon [model]="{ type: 'font', value: 'icon-key-enter' }"></u-icon>
            <span>to search</span>
          </div>
        </u-input>
      </div>
      <div *ngIf="stickyHeader" class="empty"></div>
    </div>
  </div>
  <ng-container *ngIf="!loadingTabs">
    <div
      *ngIf="displayTabs"
      class="home-page-tabs"
      [ngClass]="{ 'min-height-header': stickyHeader, banner: stickyHeader && isHubBannerActive }"
    >
      <tabs-view-wrapper
        [tabs]="tabs"
        [isOwnerOrAdmin]="ownerOrAdmin"
        [hasDelayTab]="hasDelayTab"
        (activeTabId)="onSelectedTab($event)"
        (editMode)="setTabsEditMode($event)"
        (tabCreated)="onTabCreated($event)"
      ></tabs-view-wrapper></div
  ></ng-container>
</ng-template>
