import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { HomePins, HomeTabs, SessionInfo } from '@local/client-contracts';
import { Constants, performanceCheckpoint } from '@local/common';
import { isEmbed, isMac, isNativeWindow } from '@local/common-web';
import { KeyName, getModifiers, isEnterKey, isPrintableKey, isSemicolonKey } from '@local/ts-infra';
import { PopupRef, PopupService, STYLE_SERVICE, Scheme, UInputComponent, UiIconModel, INIT_POSITION_POPUP } from '@local/ui-infra';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { HEADER_BANNER_MESSAGES } from '@shared/consts/banner-messages';
import { LoaderService } from '@shared/loader.service';
import { EventsService, ServicesRpcService } from '@shared/services';
import { BannerMessage, BannerService } from '@shared/services/banner.service';
import { FlagsService } from '@shared/services/flags.service';
import { CustomKeyboardEvent, KeyboardService } from '@shared/services/keyboard.service';
import { LinksService } from '@shared/services/links.service';
import { MemorySearchService } from '@shared/services/memory-search.service';
import { RouterService } from '@shared/services/router.service';
import { SessionService } from '@shared/services/session.service';
import { StyleService } from '@shared/services/style.service';
import { TimerService } from '@shared/services/timer.service';
import {
  BreakpointsWidth,
  getWidthBreakpointScreen,
  isDevEnv,
  isLocalEnv,
  isPreviewEnv,
  isReleaseEnv,
  windowSizeObserver,
} from '@shared/utils';
import { stopEvent } from '@shared/utils/elements-util';
import { cloneDeep, isEmpty, isEqual } from 'lodash';
import * as moment from 'moment/moment';
import { NgScrollbar } from 'ngx-scrollbar';
import { BehaviorSubject, ReplaySubject, Subscription, combineLatest, firstValueFrom } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith, take } from 'rxjs/operators';
import Semaphore from 'semaphore-async-await';
import { HubService } from 'src/app/bar/services/hub.service';
import { ResultsService } from 'src/app/bar/services/results.service';
import { BrowseBarModel } from '../../components/browse-bar/browse-bar.component';
import { WorkspacesService } from '../../services';
import { ResultCommandService } from '../../services/commands/result-command.service';
import { HomeComponentFocused, HomePageService } from '../../services/home-page.service';
import { HomePinsService } from '../../services/home-pins.service';
import { HomeSettingsService } from '../../services/home-settings.service';
import { HomeTabsService } from '../../services/home-tabs.service';
import { NavTreeService } from '../../services/nav-tree.service';
import { PcPreferencesService } from '../../services/pc-preferences.service';
import { PricingService } from '../../services/pricing.service';
import { QuickLinksService } from '../../services/quick-links.service';
import { SearchOptions, SearchResultContext, SearchService, SearchSession } from '../../services/search';
import { LinkResourcesSourceSettings } from '../../services/search/client';
import { ShareOptionsService } from '../../services/share-options.service';
import { ShowToasterService } from '../../services/show-toaster.service';
import { SidebarService } from '../../services/sidebar.service';
import { SEARCH_PLACEHOLDER } from '../../utils/constants';
import { WikiCardPreviewService } from '../collections-page/services/wiki-card-preview.service';
import { PeopleService } from '../preview/people-preview/services/people.service';
import { HeaderItem } from '../results';
import { PostWidgetService } from './services/post-widget.service';
import { WidgetsService } from './services/widgets.service';
import { ChatsService } from '../chat-page/services/chats.service';
import {
  HomeSearchPopupComponent,
  HomeSearchPopupData,
} from '../home-search-popup/components/home-search-popup/home-search-popup.component';
import { HomeSearchSettings } from '../home-search-popup/home-search-settings';
import { HomeSearchPopupService } from '../home-search-popup/services/home-search-popup.service';
import { FilePreviewService } from '../preview/file-preview/services/file-preview.service';
import { WEB_SEARCH_ITEMS } from '../hub/shared/sidebar/menu-items';
import { TagsService } from '../../services/tags.service';
import { SearchParamsService } from '../../services/search-params.service';

@UntilDestroy()
@Component({
  selector: 'home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomePageComponent implements OnInit, OnDestroy {
  static readonly ID = 'HomePageComponent';
  private readonly BROWSE_BAR_TELEMETRY = 'home_filter_suggestion';
  private readonly HEIGHT_HEADER: number = 290;
  private readonly HEIGHT_STICKY_HEADER: number = 100;
  private readonly WIDTH_HEADER_NATIVE_BUTTON: number = 144;
  private readonly WIDTH_HEADER_BUTTON: number = 45;
  private readonly MARGIN_HEADER_BUTTON: number = 48;
  private readonly isNative = isNativeWindow();
  private readonly IGNORE_LIMIT_ITEMS_SOURCES = ['answers'];
  readonly isEmbed: boolean = isEmbed();
  private readonly HOME_TAB_ID = 'home';
  readonly INPUT_ICON: UiIconModel = { type: 'font', value: 'icon-slash' };
  readonly INPUT_PADDING: number = 70;
  readonly ENTER_INDICATION_INPUT_PADDING: number = 170;
  private widthHeaderButton: number;
  private workspaceBannerDark: UiIconModel;
  private workspaceBannerLight: UiIconModel;
  private theme: Scheme;
  private onTrialPlan: boolean;
  private planDaysLeft: number;
  breakPoint: BreakpointsWidth;
  sideBarReady: boolean;
  displayTitle = true;
  inputIcon: UiIconModel = this.INPUT_ICON;
  searchIcon: UiIconModel;
  isHubBannerActive: boolean;
  workspaceBanner: UiIconModel;
  workspaceBannerInit: boolean;
  ownerOrAdmin: boolean;
  placeHolder = SEARCH_PLACEHOLDER;

  //page values
  dateHeadLine: string;
  subTitleMessage: string;
  userFirstName: string;
  search: string;
  private refreshInterval: number;

  //search fields
  private searchSession: SearchSession;
  private query: string;
  displayedContext: SearchResultContext;
  isSearchLoading: boolean;
  selectedItem: boolean;

  //browse bar fields
  browseBarModel: BrowseBarModel = { items: [], isAppsItemsModel: true, redirectPath: '/search', telemetryName: this.BROWSE_BAR_TELEMETRY };

  //popup fields
  @ViewChild('searchElement') searchElement: UInputComponent;
  private popupRef: PopupRef<HomeSearchPopupComponent, HomeSearchPopupData>;
  isSearchPopupOpen = false;
  private lock: Semaphore = new Semaphore(1);

  //tabs
  private lastTabFetch = 0;
  private tabsEditMode: boolean;
  private innerTabs = new ReplaySubject<HomeTabs.HomeTab[]>(1);
  private tabs$ = new BehaviorSubject<HomeTabs.HomeTab[]>(null);
  homePins: HomePins.HomePin[];
  selectedTab: HomeTabs.HomeTab;
  loadingTabs = true;
  displayTabs = false;
  hasDelayTab = true;
  isFirst: boolean = true;

  //scroll
  private scroll$: Subscription;
  private scrollTop: number;
  private wheelDirection: 'down' | 'up';
  _stickyHeader = false;
  @ViewChild(NgScrollbar) scrollbarRef: NgScrollbar;

  //keyboard
  private readonly COMPONENT_FOCUSED_ID = HomeComponentFocused.SEARCH_INPUT;
  private componentFocused: boolean;
  private keyHandlerId: string;
  private userNameFetched: boolean;
  private browseBarFetched: boolean;

  get tabs(): HomeTabs.HomeTab[] {
    return this.tabs$.value;
  }

  set tabs(tabs: HomeTabs.HomeTab[]) {
    this.tabs$.next(tabs);
  }

  get stickyHeader(): boolean {
    return this._stickyHeader;
  }

  set stickyHeader(value: boolean) {
    this._stickyHeader = value;
    this.hasDelayTab = false;
    this.homePageService.stickyHeader = value;
  }

  get isSecondPopup() {
    return this.wikiCardPreviewService.popupRef || this.isAnyPopupOpen;
  }

  get widthHeader(): string {
    return `${this.widthHeaderButton}px`;
  }

  get isAnyPopupOpen(): boolean {
    return this.popupService.hasDialog;
  }

  constructor(
    private services: ServicesRpcService,
    private hubService: HubService,
    private sessionService: SessionService,
    private cdr: ChangeDetectorRef,
    private loaderService: LoaderService,
    private resultsService: ResultsService,
    private popupService: PopupService,
    public widgetsService: WidgetsService,
    private keyboardService: KeyboardService,
    protected searchService: SearchService,
    private navTreeService: NavTreeService,
    private timer: TimerService,
    private routerService: RouterService,
    private sidebarService: SidebarService,
    private eventsService: EventsService,
    private pcPreferencesService: PcPreferencesService,
    private bannerService: BannerService,
    private linksService: LinksService,
    private resultCommandService: ResultCommandService,
    private peopleService: PeopleService,
    private filePreviewService: FilePreviewService,
    private workspaceService: WorkspacesService,
    private memorySearch: MemorySearchService,
    private pricingService: PricingService,
    private quickLinksService: QuickLinksService,
    private homePinService: HomePinsService,
    @Inject(STYLE_SERVICE) private styleService: StyleService,
    private wikiCardPreviewService: WikiCardPreviewService,
    private homeTabService: HomeTabsService,
    private homeSettingService: HomeSettingsService,
    private postWidgetService: PostWidgetService,
    private homePageService: HomePageService,
    private showToasterService: ShowToasterService,
    private widgetService: WidgetsService,
    private shareOptionsService: ShareOptionsService,
    private flagsService: FlagsService,
    private chatsService: ChatsService,
    private homeSearchPopupService: HomeSearchPopupService,
    private tagsService: TagsService,
    private searchParamsService: SearchParamsService
  ) {
    this.homeTabService.all$.pipe(untilDestroyed(this), distinctUntilChanged()).subscribe((t) => this.innerTabs.next(t));
    combineLatest([
      this.innerTabs,
      this.homeSettingService.orderTabs$,
      this.workspaceService.ownerOrAdmin$,
      this.hubService.state$.pipe(
        map((s) => s.t),
        distinctUntilChanged()
      ),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([all, tabsOrder, ownerOrAdmin]) => {
        this.ownerOrAdmin = ownerOrAdmin;
        this.cdr.markForCheck();
        this.updateTabs(all, tabsOrder);
        this.initWidthHeaderButton();
      });
    this.widgetService.published$.pipe(untilDestroyed(this)).subscribe((widget) => this.onSelectedTab(widget.tabId));
    this.homeTabService.moveToTab$.pipe(untilDestroyed(this)).subscribe((tabId) => this.onSelectedTab(tabId));
    this.homePinService.all$.pipe(untilDestroyed(this), distinctUntilChanged()).subscribe((all) => {
      this.homePins = all;
      this.cdr.markForCheck();
    });
    this.searchSession = this.searchService.getOrCreateSearchSession(this.HOME_TAB_ID);
    performanceCheckpoint('time-to-homepage-component');
    this.searchIcon = { type: 'font', value: 'icon-search-icon-fixed' };
  }

  private async updateTabs(all: HomeTabs.HomeTab[], tabsOrder: string[]) {
    await this.lock.acquire();
    try {
      if (!tabsOrder) {
        return;
      }
      all = all.slice().sort((a, b) => {
        const aIndex = tabsOrder.findIndex((t) => t === a.id);
        const bIndex = tabsOrder.findIndex((t) => t === b.id);
        if (aIndex === -1 && bIndex > 0) return 1;
        return aIndex - bIndex;
      });
      const currentEmpty = isEmpty(this.tabs);
      const tabsNotChanged = !isEmpty(all) && !currentEmpty && isEqual(all, this.tabs);
      this.displayTabs = all?.length !== 1 || this.ownerOrAdmin;
      if (!tabsNotChanged) {
        this.tabs = cloneDeep(all);
      }
      if (this.tabs?.length) {
        const currentId = (await this.homePageService.getCurrentTab())?.id || this.HOME_TAB_ID;
        await this.onSelectedTab(currentId, true);
      }
      if (this.tabs?.length || tabsNotChanged) {
        this.loadingTabs = false;
        if (tabsNotChanged) {
          return;
        }
      }
      this.checkViewReady();
      this.cdr.markForCheck();
    } finally {
      this.lock.release();
    }
  }

  ngOnInit(): void {
    this.homeTabService.refresh();
    this.setAppConfig();
    this.setObservers();
    this.getHeaderData();
    this.initUserInfo();
    this.initBarItems();
    this.checkSideBarReady();
    this.registerKeyboardHandler();
    this.setRefreshInterval();
    this.manageConnectPcMessage();
    this.initTheme();
    combineLatest([
      this.linksService.all$.pipe(untilDestroyed(this), startWith(null)),
      this.workspaceService.current$.pipe(untilDestroyed(this), startWith(null)),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([_, workspace]) => {
        if (workspace) {
          const { light, dark } = this.workspaceService.getBanner(true);
          if (light) {
            this.workspaceBannerLight = { type: 'img', value: { lightUrl: light } };
          }
          if (dark) {
            this.workspaceBannerDark = { type: 'img', value: { darkUrl: dark } };
          }
          this.workspaceBannerInit = true;
          this.handleBannerThemeChange();
          this.manageTrialPaymentBanner(this.onTrialPlan);
        }
        this.cdr.markForCheck();
      });

    combineLatest([this.workspaceService.onTrial$, this.workspaceService.trialDaysLeft$])
      .pipe(untilDestroyed(this))
      .subscribe(([onTrial, daysLeft]) => {
        this.onTrialPlan = onTrial;
        this.planDaysLeft = daysLeft;
        this.manageTrialPaymentBanner(this.onTrialPlan);
        this.cdr.markForCheck();
      });
    this.bannerService.bannerActive$.pipe(untilDestroyed(this), startWith(false)).subscribe((isBannerActive) => {
      this.isHubBannerActive = isBannerActive;
    });
    this.quickLinksService.refresh();
    this.homePinService.refresh();
    this.homeTabService.refresh();
    this.homeSettingService.refresh();
  }

  ngOnDestroy(): void {
    if (this.keyHandlerId) {
      this.keyboardService.unregisterKeyHandler(this.keyHandlerId);
      this.keyHandlerId = null;
    }
    this.timer.unregister(this.refreshInterval);
    this.closeRef();
    this.homePageService.stickyHeader = false;
    this.searchSession.destroy();
  }

  private registerKeyboardHandler(topPriority = false) {
    if (this.keyHandlerId) {
      this.keyboardService.unregisterKeyHandler(this.keyHandlerId);
    }
    const priority = topPriority ? 7 : 0;
    this.keyHandlerId = this.keyboardService.registerKeyHandler((keys, event) => this.handleKeys(keys, event), priority);
  }

  @HostListener('wheel', ['$event'])
  onWheel(event: WheelEvent) {
    if (event.deltaY > 0) {
      this.wheelDirection = 'down';
    } else if (event.deltaY < 0) {
      this.wheelDirection = 'up';
    }
    this.onScroll();
  }

  onScroll() {
    if (this.stickyHeader && this.wheelDirection === 'up') {
      if (this.scrollTop + this.HEIGHT_STICKY_HEADER < this.HEIGHT_HEADER) {
        this.stickyHeader = false;
      }
    }
    if (!this.stickyHeader && this.wheelDirection === 'down') {
      if (this.scrollTop >= this.HEIGHT_HEADER) {
        this.stickyHeader = true;
      }
    }
  }

  scrollToWidget(widget: HomeTabs.Widget) {
    let scrollTop = (widget?.viewSetting.y || 0) * 100;
    scrollTop += scrollTop === 0 ? 0 : this.HEIGHT_HEADER;
    this.scrollbarRef?.scrollTo({ top: scrollTop });
  }

  manageConnectPcMessage() {
    this.pcPreferencesService.openPcBanner$.pipe(untilDestroyed(this)).subscribe((show) => {
      if (!show) {
        return this.bannerService.removeMessage(HEADER_BANNER_MESSAGES.connectPc);
      }
      const msg: BannerMessage = cloneDeep(HEADER_BANNER_MESSAGES.connectPc);
      msg.onClick = () => this.pcPreferencesService.createPcLink();
      this.bannerService.addMessage(msg);
    });

    this.bannerService.buttonClicked$
      .pipe(filter((msg) => msg?.id === 'connect_pc'))
      .pipe(untilDestroyed(this))
      .subscribe(async () => {
        this.bannerService.addMessage(HEADER_BANNER_MESSAGES.pcAvailable);
      });

    this.bannerService.close$
      .pipe(filter((msg) => msg?.id === 'connect_pc'))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.pcPreferencesService.isPcBannerClosed = true;
      });
  }

  manageTrialPaymentBanner(onTrialPlan: boolean) {
    const trialBanner = { ...HEADER_BANNER_MESSAGES.trialPayment };
    if (onTrialPlan) {
      trialBanner.onClick = () => {
        this.eventsService.event('upgrade_plan', {
          location: {
            title: this.hubService.currentLocation,
          },
          target: 'banner',
          label: this.planDaysLeft + '',
        });
        this.onUpgradePlanClicked();
      };
      trialBanner.content = `${this.planDaysLeft} DAYS LEFT`;
      this.bannerService.addMessage(trialBanner);
    } else {
      this.bannerService.removeMessage(trialBanner);
    }
  }

  setRefreshInterval() {
    this.refreshInterval = this.timer.register(
      () => {
        this.getHeaderData();
        this.cdr.markForCheck();
      },
      {
        visibleInterval: 1000 * 60 * 5,
        focusInterval: 1000 * 60 * 5,
      }
    );
  }

  checkSideBarReady() {
    this.navTreeService.roots$.pipe(untilDestroyed(this), take(1)).subscribe(() => {
      this.sideBarReady = true;
      this.checkViewReady();
    });
  }

  async setAppConfig() {
    //enable full loader only if this is the first time entering the page
    if (!this.widgetsService.firstLoad && !this.routerService.canGoBack) {
      this.loaderService.ready$.next(false);
    }
    this.hubService.readOnly = true;
    this.cdr.markForCheck();
  }

  getHeaderData() {
    this.getDateHeadline();
    this.getSubTitle();
  }

  getDateHeadline() {
    this.dateHeadLine = moment().format('dddd, D MMMM, YYYY');
  }

  getSubTitle() {
    const date = new Date();
    const hour = date.getHours();
    if (hour < 12) {
      this.subTitleMessage = 'Good morning';
    } else if (hour <= 18) {
      this.subTitleMessage = 'Good afternoon';
    } else {
      this.subTitleMessage = 'Good evening';
    }
  }

  initUserInfo() {
    this.sessionService.current$
      .pipe(
        untilDestroyed(this),
        filter((session) => !!session),
        map((session: SessionInfo) => session.user)
      )
      .subscribe((u) => {
        performanceCheckpoint('user_fname_ready');
        this.userFirstName = u.firstName || '';
        if (isDevEnv() || isPreviewEnv() || isLocalEnv()) {
          this.userFirstName += ' (London)';
        } else if (isReleaseEnv()) {
          this.userFirstName += ' (Paris)';
        }
        this.userNameFetched = true;
        this.checkViewReady();
        this.cdr.markForCheck();
      });
  }

  initBarItems() {
    this.services
      .observable('results.appbaritems$')
      .pipe(
        untilDestroyed(this),
        filter((res) => res)
      )
      .subscribe((items) => {
        performanceCheckpoint('bar_items_ready');
        this.browseBarModel.items = this.resultsService.getFilteredBarApps(items);
        this.browseBarFetched = true;
        this.checkViewReady();
        this.cdr.markForCheck();
      });
  }

  checkViewReady() {
    if (this.browseBarFetched && this.userNameFetched && this.tabs && this.isFirst) {
      this.loaderService.ready$.next(true);
      this.widgetsService.firstLoad = true;
      this.hubService.setViewReady(HomePageComponent.ID);
      this.focusSearchInput();
      this.isFirst = false;
    }
    if (!this.scroll$ && this.scrollbarRef) {
      this.scroll$ = this.scrollbarRef.scrolled.pipe(filter((ev) => !!ev)).subscribe((ev: UIEvent) => {
        this.scrollTop = (ev.target as HTMLElement).scrollTop;
        this.onScroll();
      });
    }
  }

  async onSearch($event: string) {
    this.focusSearchInput();
    this.checkForClickX($event);
    this.query = $event;
    this.search = this.query;
    let isDefaultSearch = false;
    if (!$event || $event.length <= 0 || $event === '') {
      this.search = '';
      setTimeout(() => {
        this.searchFocus();
      }, 0);
      isDefaultSearch = true;
    }
    const options = await this.getSearchOptions(this.query);
    this.isSearchLoading = true;
    this.searchSession
      .search$(options)
      .pipe(untilDestroyed(this))
      .subscribe((res: SearchResultContext) => {
        const ctx = cloneDeep(res);
        if (!ctx.sources || ctx.sources.some((s) => !s.done && !['link-resources', 'web-search'].includes(s.source.type))) {
          return;
        }
        const linkSearch = ctx.sources?.find((x) => x.source.type == 'link-resources');
        if (linkSearch && !linkSearch.done) ctx.sources = ctx.sources.filter((x) => x.source.type != 'web-search');

        const groupSourcesDone = ctx.sources
          .filter((s) => !['web-search'].includes(s.source.type))
          .every((s) => s.done || (s.source.type === 'link-resources' && s.extra?.localDone));

        const sliceGroupsSize = !isDefaultSearch && groupSourcesDone;
        this.displayedContext = this.homeSearchPopupService.getSearchResults(this.query, ctx, sliceGroupsSize);

        if (this.search || isDefaultSearch) {
          this.isSearchLoading = false;
          if ((ctx.searchCompleted && isDefaultSearch) || groupSourcesDone) {
            if (!this.isAnyPopupOpen || (Object.keys(this.popupService.refs).length === 1 && this.isSearchPopupOpen)) {
              this.openSearchPopup();
            }
          }
          this.cdr.markForCheck();
        }
      });
  }

  private async getSearchOptions(query: string): Promise<SearchOptions> {
    let view = !query?.length ? 'default' : 'search';
    if (
      query?.toLowerCase()?.trim()?.startsWith('go/') &&
      !this.workspaceService.isFeatureDisabled(Constants.DISABLED_GO_LINKS_WORKSPACE_FEATURE_FLAG)
    ) {
      view = 'goLinks';
    }
    const sources = cloneDeep(HomeSearchSettings.settings[view]);
    for (const source of sources) {
      if (!source?.filters?.excludeFilters?.includes('Person')) {
        continue;
      }
      if (source.type === 'link-resources') {
        (source as LinkResourcesSourceSettings).tag = 'user_query-home-page';
        const peopleLinkId = await firstValueFrom(this.linksService.peopleLinkId$);
        if (peopleLinkId) {
          source.filters.excludeFilters.push('PeopleLink');
        }
      }
    }
    const options: SearchOptions = {
      resetSession: !this.search?.length,
      query: this.query,
      sources,
      trigger: 'user_query',
      telemetrySearchMethod: 'Quick-Search',
    };
    return options;
  }

  checkForClickX(value: string) {
    if (this.search && this.search.length > 0 && value === '') {
      this.eventsService.event('home_page.search_bar', {
        search: {
          query: this.search,
        },
      });
    }
  }

  searchKeyIsNotEmpty() {
    return this.search && this.search.length > 0 && this.search !== '';
  }

  openSearchPopup() {
    this.inputIcon = null;
    this.widgetsService.triggerWidgetFocus$.next(null);
    const data: HomeSearchPopupData = {
      popupWidth: this.searchElement['inputElement'].el.nativeElement.offsetWidth,
      search: this.search,
      redirectPath: 'search',
      browseBarModel: this.browseBarModel,
      searchData: {
        items: this.displayedContext.items,
        sessionId: this.displayedContext.sessionId,
        clientSearchId: this.displayedContext.clientSearchId,
        lastHeaderIndex: this.displayedContext.lastHeaderIndex,
        searchCompleted: this.displayedContext.searchCompleted,
        sources: this.displayedContext.sources,
      },
    };

    if (!this.popupRef) {
      const { x, y } = this.searchElement['inputElement'].el.nativeElement.getBoundingClientRect();
      const top: number = y + (this.stickyHeader ? 32 : 40);
      this.popupRef = this.popupService.open<HomeSearchPopupComponent, HomeSearchPopupData>(
        { left: x, top },
        HomeSearchPopupComponent,
        data,
        {
          position: INIT_POSITION_POPUP,
        }
      );

      this.isSearchPopupOpen = true;
      this.cdr.markForCheck();

      this.focusSearchInput();

      this.popupRef.compInstance.onClearSearch.pipe(untilDestroyed(this)).subscribe(async () => {
        await this.onSearch('');
        this.cdr.markForCheck();
      });

      this.popupRef.compInstance.onOpenChat.pipe(untilDestroyed(this)).subscribe(async (assistantId) => {
        this.chatsService.openChat(assistantId, this.search);
      });

      this.popupRef.compInstance.onReOpen.pipe(untilDestroyed(this)).subscribe(() => {
        this.openSearchPopup();
      });

      this.popupRef.compInstance.removeFocus.pipe(untilDestroyed(this)).subscribe(() => {
        this.removeFocusSearchInput();
      });

      this.popupRef.compInstance.onOpenPopup.pipe(untilDestroyed(this)).subscribe(() => {
        this.closeRef();
        this.isSearchPopupOpen = false;
        this.cdr.markForCheck();
      });

      this.popupRef.compInstance.onSelectedIndex.pipe(untilDestroyed(this)).subscribe((value) => {
        this.selectedItem = !!value || value === 0;
        this.cdr.markForCheck();
      });

      this.popupRef.compInstance.onSelectGroup.pipe(untilDestroyed(this)).subscribe((headerItem) => {
        this.onSelectGroup(headerItem);
      });

      this.popupRef.close$.pipe(untilDestroyed(this)).subscribe(() => {
        this.popupRef = null;
        this.isSearchPopupOpen = false;
        this.inputIcon = this.INPUT_ICON;
        this.cdr.markForCheck();
      });
    } else this.popupRef.update(data);
  }

  setObservers() {
    this.sidebarService.sidebarStateChange$.pipe(untilDestroyed(this)).subscribe(() => {
      this.initWidthHeaderButton();
    });

    this.sidebarService.onNodeClick$.pipe(untilDestroyed(this)).subscribe((node) => {
      if (node === this.HOME_TAB_ID && this.search) {
        this.onSearch('');
      }
    });

    this.homePageService.triggerComponentFocus$.pipe(untilDestroyed(this)).subscribe((value) => {
      if (value.live === this.COMPONENT_FOCUSED_ID) {
        this.componentFocused = true;
        this.focusSearchInput();
      } else {
        this.componentFocused = false;
        this.removeFocusSearchInput();
      }
    });

    this.widgetsService.scrollToWidget$.pipe(untilDestroyed(this)).subscribe((widget) => {
      this.scrollToWidget(widget);
    });
  }

  handleKeys(keys: Array<KeyName>, event: CustomKeyboardEvent): void {
    const key = keys[0];
    const modifiers = getModifiers(keys);

    if (isEnterKey(key) && this.searchKeyIsNotEmpty() && !this.inputIcon) {
      this.onEnterPressed();
      event.stopPropagation();
      return;
    }

    if (isEnterKey(key) && this.popupRef) {
      event.stopPropagation();
      return;
    }

    if (key === 'tab') {
      if (!this.componentFocused && this.homePageService.componentFocus !== this.COMPONENT_FOCUSED_ID) return;
      stopEvent(event);
      if (!this.isInputFocused()) {
        this.focusSearchInput();
        return;
      }
      this.homePageService.keyboardComponentFocus$.next({
        step: modifiers.length === 1 && modifiers[0] === 'shift' ? 'prev' : 'next',
        live: this.COMPONENT_FOCUSED_ID,
      });
      return;
    }

    if (
      !this.isSearchPopupOpen &&
      !this.isAnyPopupOpen &&
      !this.shareOptionsService.shareOptionsPopupRef &&
      !this.postWidgetService.popupsOpen
    ) {
      if (key === 'slash') {
        this.focusSearchInput();
        this.homePageService.keyboardComponentFocus$.next({
          step: 'current',
          live: this.COMPONENT_FOCUSED_ID,
        });
        event.preventDefault();
        return;
      }
      const editMode = this.tabsEditMode || this.widgetsService.editMode;
      const printableKey = isPrintableKey(event) && !isEnterKey(key) && !isSemicolonKey(event);
      if (printableKey && !this.isInputFocused() && !editMode) {
        this.showToasterService.showToaster({
          id: 'hit-slash-for-search',
          title: 'Hit / to jump back to the search box',
          irremovable: true,
        });
        event.stopPropagation();
      }
    }
  }

  setTabsEditMode(editMode: boolean) {
    this.tabsEditMode = editMode;
  }

  onEnterPressed() {
    this.routerService.navigateByUrl(`search?q=${encodeURIComponent(this.search)}`);
  }

  private isInputFocused() {
    const input = this.searchElement?.inputElement?.el?.nativeElement;
    return document.activeElement === input;
  }

  private focusSearchInput() {
    if (this.isInputFocused() || this.isAnyPopupOpen || this.widgetsService.editMode || this.tabsEditMode) {
      return;
    }
    this.searchFocus();
  }

  private searchFocus() {
    this.inputIcon = null;
    this.searchElement?.inputElement?.el?.nativeElement?.focus();
  }

  outsideClick($event) {
    this.inputIcon = this.INPUT_ICON;
    this.cdr.markForCheck();
  }

  removeFocusSearchInput() {
    if (!this.isInputFocused()) {
      return;
    }
    this.inputIcon = this.INPUT_ICON;
    this.searchElement?.inputElement.el.nativeElement.blur();
  }

  getRealPosition(index: number) {
    return this.resultCommandService.getRealPosition(this.displayedContext.items, index, this.displayedContext.lastHeaderIndex);
  }

  private initTheme() {
    this.styleService.theme$.pipe(untilDestroyed(this)).subscribe((theme) => {
      this.theme = theme;
      this.handleBannerThemeChange();
      this.cdr.markForCheck();
    });
    windowSizeObserver()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.breakPoint = getWidthBreakpointScreen();
        this.initWidthHeaderButton();
        this.cdr.markForCheck();
      });
  }

  private initWidthHeaderButton() {
    this.widthHeaderButton = this.WIDTH_HEADER_BUTTON + this.MARGIN_HEADER_BUTTON;

    if (this.ownerOrAdmin) {
      this.widthHeaderButton += this.WIDTH_HEADER_BUTTON * 2;
    }
    if (this.isNative && !isMac()) {
      this.widthHeaderButton += this.WIDTH_HEADER_NATIVE_BUTTON;
    }
    this.displayTitle = !(
      ['small', 'extra-small'].includes(this.breakPoint) ||
      (['medium'].includes(this.breakPoint) && this.sidebarService.sidebarState === 'open')
    );
    this.cdr.markForCheck();
  }

  private handleBannerThemeChange() {
    this.workspaceBanner = this.theme === 'dark' ? this.workspaceBannerDark : this.workspaceBannerLight;
    this.cdr.markForCheck();
  }

  onUpgradePlanClicked() {
    this.pricingService.navigateToPricingPage();
  }

  async onNewActionTab(tabId: string) {
    await this.lock.acquire();
    try {
      await this.onSelectedTab(tabId);
      const tab = this.selectedTab;
      if (!tab) {
        return;
      }
      await this.homePageService.waitTilTabExists(tabId);
    } finally {
      this.lock.release();
    }
  }

  async onSelectedTab(tabId: string, force?: boolean) {
    if (tabId === this.selectedTab?.id && !force) {
      return;
    }
    this.homePageService.stickyHeader = false;
    let selectedTab = this.tabs.find((t) => t.id === tabId);
    if (!selectedTab && tabId != this.HOME_TAB_ID) {
      selectedTab = this.tabs.find((t) => t.id === this.HOME_TAB_ID);
    }
    this.selectedTab = cloneDeep(selectedTab);
    if (!this.selectedTab) {
      return;
    }
    this.homePageService.setCurrentTab(this.selectedTab);
    const now = Date.now();
    if (this.lastTabFetch < now - 10 * 1000) {
      this.lastTabFetch = now;
      this.homeTabService.refresh();
    }
    this.cdr.markForCheck();
  }

  async onTabCreated(tab: HomeTabs.HomeTab) {
    if (!this.tabs.find((t) => t.id === tab.id)) {
      this.innerTabs.next([...(this.tabs || []), tab]);
      await firstValueFrom(this.tabs$.pipe(filter((t) => t?.map((e) => e.id)?.includes(tab.id))));
      await this.lock.acquire();
      try {
        await this.onSelectedTab(tab.id);
        await firstValueFrom(this.homeTabService.all$.pipe(filter((t) => t?.map((e) => e.id)?.includes(tab.id))));
      } finally {
        this.lock.release();
      }
    }
  }

  private closeRef() {
    if (this.popupRef) {
      this.popupRef.destroy();
      this.popupRef = null;
    }
  }

  private async onSelectGroup(headerItem: HeaderItem) {
    const group = headerItem.group;

    if (group.name === 'web-search') {
      const url = `/web-search/${group.value}?q=${this.search}`;
      this.routerService.navigateByUrl(url, {}, true);
      const i = WEB_SEARCH_ITEMS.find((p) => p.id.split('web-search_')[1] === group.value);
      if (i) {
        this.tagsService.all = [this.hubService.suggestionsToTag(i)];
      }
      return;
    }

    await this.routerService.navigate(['search'], { queryParams: { q: this.search } });
    this.searchParamsService.addGroup(group);
    this.closeRef();
  }
}
